import styled from '@emotion/styled';
import GridColumn from '../grid/GridColumn';
import Spans from '../../../tokens/Spans';
import Grid from '../grid/Grid';
import Section from '../section/Section';
import React, { FC } from 'react';

import { listArray } from '../../../helpers/array';
import { isDebug, isStorybook } from '../../../helpers/dom';

const list = listArray(24);

/**
 * A component to render grid in background
 */
const GridDebugger: FC = () => {
  /** No render in development */
  if (!isDebug && !isStorybook) return null;
  return (
    <GridDebuggerRoot>
      <StyledSection>
        <SectionInner>
          <StyledGrid>
            {list.map((num) => (
              <StyledGridColumn key={num} span={Spans.one} />
            ))}
          </StyledGrid>
        </SectionInner>
      </StyledSection>
    </GridDebuggerRoot>
  );
};

export default GridDebugger;

const GridDebuggerRoot = styled.div`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const StyledSection = styled(Section)`
  height: 100%;
  > div {
    height: 100%;
  }
`;

const SectionInner = styled.div`
  height: 100%;
  position: relative;
`;

const StyledGrid = styled(Grid)`
  align-items: stretch;
  grid-row-gap: 0 !important;
  height: 100%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
`;

const StyledGridColumn = styled(GridColumn)`
  background-color: rgba(0, 255, 255, 0.1);
`;
