import React, { FC } from 'react';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import styled from '@emotion/styled';
import useDictionary from '../../../hooks/useDictionary';
import Colors from '../../../tokens/Colors';
import Spacings from '../../../tokens/Spacings';
import gieplLogo from '../../../assets/images/giepl.png';
import Button from '../../atoms/button/Button';
import { useHistory } from 'react-router';
import Icons from '../../../tokens/icons/Icons';
import Markdown from '../../atoms/markdown/Markdown';
import FontSizes from '../../../tokens/FontSizes';
import LineHeights from '../../../tokens/LineHeights';
import Title from '../../atoms/title/Title';
import Spacer from '../../atoms/spacer/Spacer';
import Icon from '../../atoms/icon/Icon';
import Sizes from '../../../tokens/Sizes';

const PageNotFound: FC = () => {
  const dictionary = useDictionary();
  const history = useHistory();
  return (
    <AnimatePresence>
      <MaintenanceViewContainer>
        <StyledSection {...maintenanceMotionProps}>
          <img src={gieplLogo} alt="logo" />
          <StyledTitle as="h1">{dictionary.get('404')}</StyledTitle>
          <StyledTitle as="h2">{dictionary.get('Page does not exist.')}</StyledTitle>
          <Spacer spacing={Spacings.large} />
          <StyledButton
            onClick={() => history.goBack()}
            trailing={<Icon icon={Icons.arrowRight} color={Colors.white} size={Sizes.small} />}
            fontSize={{ mobile: FontSizes.body }}
            lineHeight={{ mobile: LineHeights.small }}
            padding={{ mobile: `0px` }}
            spacing={Spacings.xSmall}
            stateStyles={{
              enabled: {
                color: Colors.graphiteBlack
              },
              disabled: {
                color: Colors.graphiteBlack
              },
              hover: {
                color: Colors.white
              },
              active: {
                color: Colors.safetyOrange
              }
            }}
          >
            <Markdown text={dictionary.get('Go Back')} />
          </StyledButton>
        </StyledSection>
      </MaintenanceViewContainer>
    </AnimatePresence>
  );
};

export default PageNotFound;

const MaintenanceViewContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 40px);
  padding-top: 60px;
  box-sizing: border-box;
`;

const StyledTitle = styled(Title)`
  color: ${Colors.graphiteBlack};
  margin: ${Spacings.small} 0 0;
`;

const StyledSection = styled(motion.div)`
  text-align: center;
  position: relative;
`;

const StyledButton = styled(Button)`
  background: ${Colors.gieplGreen};
  padding: ${Spacings.medium};
  width: 150px;
  color: ${Colors.white};
`;

const maintenanceMotionProps: MotionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } }
};
