import { TextSizeThemeData } from '../text/Text.theme';
import Colors, { Color } from '../../../tokens/Colors';
import FontSizes from '../../../tokens/FontSizes';
import LetterSpacings from '../../../tokens/LetterSpacings';
import LineHeights, { createCustomLineHeight } from '../../../tokens/LineHeights';
import { ComponentThemeData } from '../../../types';

export interface MarkingSystemThemeData {
  color: Color;
  title: TextSizeThemeData;
  emDash: TextSizeThemeData;
}

export interface MarkingSystemThemesData {
  markingSystem: ComponentThemeData<MarkingSystemThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    markingSystem: MarkingSystemThemeData;
  }
}

const MarkingSystemTheme: MarkingSystemThemesData = {
  markingSystem: {
    light: {
      color: Colors.graphiteBlack,
      title: {
        fontSize: {
          mobile: FontSizes.large
        },
        letterSpacing: {
          mobile: LetterSpacings.large
        },
        lineHeight: {
          mobile: LineHeights.large
        }
      },
      emDash: {
        fontSize: {
          mobile: FontSizes.large
        },
        letterSpacing: {
          mobile: LetterSpacings.large
        },
        lineHeight: {
          mobile: createCustomLineHeight(26 / 16)
        }
      }
    },
    dark: {
      color: Colors.white
    }
  }
};

export default MarkingSystemTheme;
