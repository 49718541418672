import React, { FC } from 'react';
import { useQuery } from 'graphql-hooks';
import loadable from '@loadable/component';
import GET_ALL_PAGE_CONTENT from '../../../graphQL/queries/getPageContent';
import { getHomePageContent } from '../../../helpers/cms/homeView';
const SectionHero = loadable(() => import('../../organisms/section-hero/SectionHero'));
const SectionProducts = loadable(() => import('../../organisms/section-products/SectionProducts'));
import PageLoadingSpinner from '../../atoms/page-loading-spinner/PageLoadingSpinner';
import Spacer from '../../atoms/spacer/Spacer';
import Spacings from '../../../tokens/Spacings';

const HomeView: FC = () => {
  const { data, loading } = useQuery(GET_ALL_PAGE_CONTENT, {
    variables: {
      pathname: '/'
    }
  });
  if (loading) {
    return <PageLoadingSpinner />;
  }
  if (!loading && !!data) {
    const content = getHomePageContent(data);
    return (
      <main className="container">
        <SectionHero {...content.sections.hero} showMissionStatement={false} />
        <Spacer spacing={Spacings.xxxLarge} />
        <SectionProducts {...content.sections.products} />
      </main>
    );
  }
  return <></>;
};

export default HomeView;
