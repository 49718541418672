import { Property } from 'csstype';

export type LetterSpacing = Property.LetterSpacing<string | 0> | 0;

enum LetterSpacings {
  large = '-0.075rem', // -1.2px
  small = '-0.01875rem' // -0.3px
}

export default LetterSpacings;

export const createCustomLetterSpacing = (multiplier: number): string => `${multiplier}rem`;
