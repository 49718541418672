export interface Span {
  mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
  tablet?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
  desktop?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  desktopWide?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
}

const Spans: {
  full: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  twelve: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  eleven: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  ten: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  nine: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  eight: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  seven: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  six: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  half: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  five: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  four: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
  };
  three: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
    desktop: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';
  };
  two: {
    mobile: 0 | 1 | 2 | 3 | 4 | 'hidden';
    tablet: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 'hidden';
  };
  one: { mobile: 0 | 1 | 2 | 3 | 4 | 'hidden' };
} = Object.freeze({
  full: { mobile: 4, tablet: 8, desktop: 12 },
  twelve: { mobile: 4, tablet: 8, desktop: 12 },
  eleven: { mobile: 3, tablet: 7, desktop: 11 },
  ten: { mobile: 3, tablet: 6, desktop: 10 },
  nine: { mobile: 2, tablet: 6, desktop: 9 },
  eight: { mobile: 2, tablet: 4, desktop: 8 },
  seven: { mobile: 2, tablet: 4, desktop: 7 },
  six: { mobile: 2, tablet: 4, desktop: 6 },
  half: { mobile: 2, tablet: 4, desktop: 6 },
  five: { mobile: 2, tablet: 4, desktop: 5 },
  four: { mobile: 2, tablet: 4 },
  three: { mobile: 2, tablet: 2, desktop: 3 },
  two: { mobile: 2, tablet: 2 },
  one: { mobile: 1 }
});

export default Spans;
