import React, { forwardRef } from 'react';
import Text, { TextProps } from '../text/Text';
import useTheme from '../../../hooks/useTheme';
import Sizes from '../../../tokens/Sizes';

export interface TitleProps extends TextProps<HTMLHeadingElement> {
  /**
   * The size of the title
   */
  size?: Sizes.medium;
}

const Title = forwardRef<HTMLElement, TitleProps>(
  ({ as = 'h1', size = Sizes.medium, ...props }: TitleProps, ref) => {
    const theme = useTheme();
    const { sizes, ...titleStyles } = theme.title;
    const titleSizeStyles = sizes[size];

    return <Text ref={ref} as={as} {...titleStyles} {...titleSizeStyles} {...props} />;
  }
);

Title.displayName = 'Title';

export default Title;
