import { css } from '@emotion/react';
import { ButtonStateStyles } from './Button.theme';
import Icon from '../icon/Icon';
import Row from '../row/Row';
import Spacer from '../spacer/Spacer';
import { responsiveStyles } from '../../../helpers/responsiveStyle';
import {
  shouldNotForwardProps,
  shouldNotForwardThemeProps
} from '../../../helpers/shouldForwardProps';
import styled from '@emotion/styled';
import { ResponsiveThemeData } from '../../../themes/Theme';
import {
  CrossAxisAlignment,
  CrossAxisAlignments,
  MainAxisAlignment,
  MainAxisAlignments
} from '../../../tokens/Alignments';
import Colors, { Color } from '../../../tokens/Colors';
import { FontStyle } from '../../../tokens/FontStyle';
import { FontWeight } from '../../../tokens/FontWeights';
import { Font } from '../../../tokens/Fonts';
import { IconData } from '../../../tokens/icons/Icons';
import { Opacity } from '../../../tokens/Opacities';
import Orientations from '../../../tokens/Orientations';
import Spacings, { DimensionalSpacing, Spacing } from '../../../tokens/Spacings';
import React, { forwardRef, Fragment } from 'react';
import { LineHeight } from '../../../tokens/LineHeights';
import { createCSSTransition, MotionDurations, MotionEasings } from '../../../tokens/Motion';
import { aria, focusStyle } from '../../../helpers/accessibility';
import { LetterSpacing } from '../../../tokens/LetterSpacings';
import { FontSize } from '../../../tokens/FontSizes';
import { overflowEllipsis } from '../../../helpers/style';

/**
 * @internal
 */
export interface SharedButtonProps {
  // === coming from ButtonSizeThemeData ===
  /**
   * @internal
   */
  fontSize: ResponsiveThemeData<FontSize>;
  /**
   * @internal
   */
  letterSpacing?: ResponsiveThemeData<LetterSpacing>;
  /**
   * @internal
   */
  lineHeight: ResponsiveThemeData<LineHeight>;
  /**
   * Control the padding in the button
   */
  padding: ResponsiveThemeData<DimensionalSpacing>;
  /**
   * Control the spacing inside the button
   */
  spacing: Spacing;
  /**
   * The height of the button
   */
  height?: ResponsiveThemeData<Spacing>;

  // === default props ===
  /**
   * Badge is meant for the `<Badge />` component, this slot will be rendered after the trailing icon.
   */
  badge?: JSX.Element;
  /**
   * Flag to disable the button
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * An object defining the styles of all button availble buttons states like: enabled, disabled, hover and active.
   */
  stateStyles: ButtonStateStyles;
  /**
   * Leading slot that will be rendered before the children
   */
  leading?: JSX.Element | IconData;
  /**
   * Trailing slot that will be rendered after the children
   */
  trailing?: JSX.Element | IconData;
  /**
   * Colorize leading / trailing icon(s) with the provided color
   */
  iconColor?: Color | 'inherit';
  /**
   * Alignment options over the main axis (horizontal)
   */
  mainAxisAlignment?: MainAxisAlignment;
  /**
   * Alignment options over the cross axis (vertical)
   */
  crossAxisAlignment?: CrossAxisAlignment;
  /**
   * Enable this flag to give the button a width of 100%.
   *
   * @default false
   */
  stretch?: boolean;
  /**
   * @internal
   */
  fontFamily?: Font;
  /**
   * @internal
   */
  fontWeight?: FontWeight;
  /**
   * @internal
   */
  fontStyle?: FontStyle;
  /**
   * @internal
   */
  opacity?: Opacity;
  /**
   * Add a spacing according to design
   */
  alignmentSpacing?: Spacing;
  /**
   * Offsets the icon top based on `pairedWithText` prop
   */
  topOffset?: boolean;
}

type ButtonOrAnchorProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * @internal
 */
export interface ExtendedButtonProps
  extends Omit<ButtonOrAnchorProps, 'size' | 'height'>,
    Pick<
      SharedButtonProps,
      // These can be set in the extended buttons, the rest is internal and coming from the theme file
      'iconColor' | 'badge' | 'disabled' | 'leading' | 'trailing' | 'stretch'
    > {
  // Allow non-interactive span/div for when buttons are used in another interactive element like a card
  as?: 'button' | 'a' | 'span' | 'div';
  //
}

export interface ButtonProps
  extends Omit<ButtonOrAnchorProps, 'size' | 'height'>,
    SharedButtonProps {
  //
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      height,
      alignmentSpacing,
      iconColor = 'inherit',
      mainAxisAlignment = MainAxisAlignments.start,
      crossAxisAlignment = CrossAxisAlignments.center,
      spacing = Spacings.medium,
      topOffset = true,
      as = 'button',
      ...props
    }: ButtonProps,
    ref
  ) => (
    <StyledButton
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      {...aria({
        disabled: props.disabled
      })}
      as={as}
      type={as === 'button' ? 'button' : undefined}
      {...props}
    >
      <StyledRow
        crossAxisAlignment={crossAxisAlignment}
        mainAxisAlignment={mainAxisAlignment}
        rowHeight={height}
        topOffset={topOffset}
        stateStyles={props.stateStyles}
        disabled={props.disabled || false}
      >
        {alignmentSpacing && mainAxisAlignment === MainAxisAlignments.end && (
          <Spacer orientation={Orientations.horizontal} spacing={alignmentSpacing} />
        )}
        {props.leading && (
          <IconRow
            crossAxisAlignment={crossAxisAlignment}
            iconColor={'glyph' in props.leading ? iconColor : undefined}
          >
            <Fragment>
              {'glyph' in props.leading ? (
                <Icon icon={props.leading} color={iconColor} />
              ) : (
                props.leading
              )}
              <Spacer orientation={Orientations.horizontal} spacing={spacing} />
            </Fragment>
          </IconRow>
        )}
        <StyledChildren
          stretch={mainAxisAlignment === MainAxisAlignments.stretch}
          lineHeight={props.lineHeight}
        >
          {props.children}
        </StyledChildren>
        {(props.trailing || props.badge) && (
          <IconRow
            crossAxisAlignment={crossAxisAlignment}
            iconColor={
              props.trailing !== undefined && 'glyph' in props.trailing ? iconColor : undefined
            }
          >
            {props.trailing && (
              <Fragment>
                <Spacer orientation={Orientations.horizontal} spacing={spacing} />
                {'glyph' in props.trailing ? (
                  <Icon icon={props.trailing} color={iconColor} />
                ) : (
                  props.trailing
                )}
              </Fragment>
            )}
            {props.badge && (
              <Fragment>
                <Spacer orientation={Orientations.horizontal} spacing={spacing} />
                {props.badge}
              </Fragment>
            )}
          </IconRow>
        )}
        {alignmentSpacing && mainAxisAlignment === MainAxisAlignments.start && (
          <Spacer orientation={Orientations.horizontal} spacing={alignmentSpacing} />
        )}
      </StyledRow>
    </StyledButton>
  )
);

Button.displayName = 'Button';

export default Button;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledButton = styled(
  'button',
  shouldNotForwardThemeProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)<any>((props) => {
  const { enabled, hover, active, disabled } = props.stateStyles;
  return css(
    css`
      margin: 0;
      display: inline-block;
      color: ${enabled.color};
      border-style: solid;
      border-width: 0;
      cursor: pointer;
      width: ${props.stretch ? '100%' : 'auto'};
      max-width: 100%;
      box-shadow: ${enabled.borderColor ? `0 0 0 1px ${enabled.borderColor} inset` : 'none'};
      background-color: ${enabled.backgroundColor || 'transparent'};
      transition: ${createCSSTransition(
          'background-color',
          MotionEasings.default,
          MotionDurations.fast
        )},
        ${createCSSTransition('color', MotionEasings.default, MotionDurations.fast)},
        ${createCSSTransition('border-color', MotionEasings.default, MotionDurations.fast)},
        ${createCSSTransition('box-shadow', MotionEasings.default, MotionDurations.fast)},
        ${createCSSTransition('opacity', MotionEasings.default, MotionDurations.fast)};
      text-decoration: none;
      user-select: none;
      touch-action: manipulation;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        color: ${hover.color};
        ${hover.backgroundColor
          ? css`
              background-color: ${hover.backgroundColor};
            `
          : ''};
        box-shadow: ${hover.borderColor ? `0 0 0 1px ${hover.borderColor} inset` : 'none'};
      }

      &:active {
        color: ${active.color};
        ${active.backgroundColor
          ? css`
              background-color: ${active.backgroundColor};
            `
          : ''};
        box-shadow: ${active.borderColor ? `0 0 0 1px ${active.borderColor} inset` : 'none'};
      }

      ${focusStyle()};
    `,
    props.leading &&
      css`
        vertical-align: middle;
      `,
    !!props.fontFamily &&
      css`
        font-family: ${props.fontFamily};
      `,
    !!props.fontWeight &&
      css`
        font-weight: ${props.fontWeight};
      `,
    !!props.opacity &&
      css`
        opacity: ${props.opacity};
      `,
    !!enabled.borderColor &&
      css`
        box-shadow: 0 0 0 1px ${enabled.borderColor} inset;
      `,
    css`
      ${responsiveStyles({
        fontSize: props.fontSize,
        lineHeight: props.lineHeight,
        padding: props.padding,
        ...(!!props.letterSpacing && {
          letterSpacing: props.letterSpacing
        })
      })}
    `,
    props.disabled &&
      css`
        color: ${disabled.color};
        background-color: ${disabled.backgroundColor || 'transparent'};
        box-shadow: ${disabled.borderColor ? `0 0 0 1px ${disabled.borderColor} inset` : 'none'};
        cursor: default;

        &:hover,
        &:active {
          color: ${disabled.color};
          background-color: ${disabled.backgroundColor || 'transparent'};
          box-shadow: ${disabled.borderColor ? `0 0 0 1px ${disabled.borderColor} inset` : 'none'};
          cursor: default;
        }
      `
  );
});

const StyledRow = styled(
  Row,
  shouldNotForwardProps(false, 'rowHeight', 'stateStyles')
)<{
  rowHeight?: ResponsiveThemeData<Spacing>;
  stateStyles: ButtonStateStyles;
  disabled: boolean;
  topOffset?: boolean;
}>((props) => {
  const { disabled } = props.stateStyles;
  return css(
    css`
      transition: ${createCSSTransition('opacity', MotionEasings.default, MotionDurations.fast)};
      position: relative;
    `,
    props.topOffset &&
      css`
        transform: translateY(1px);
      `,
    props.rowHeight &&
      css`
        ${responsiveStyles({
          height: props.rowHeight,
          lineHeight: props.rowHeight
        })}
      `,
    props.disabled &&
      !!disabled?.contentOpacity &&
      css`
        opacity: ${disabled.contentOpacity};
      `
  );
});

const StyledChildren = styled.div<{
  lineHeight: ResponsiveThemeData<LineHeight>;
  stretch: boolean;
}>(
  ({ lineHeight, stretch }) => css`
    white-space: nowrap;
    max-width: 100%;
    text-align: left;
    flex: ${stretch ? 1 : 'none'};
    ${overflowEllipsis};

    ${responsiveStyles({
      lineHeight
    })}
  `
);

// Unless another iconColor is provided set by default
// the icon colors to Colors.safetyOrange.
const IconRow = styled(Row)<{ iconColor?: string }>(({ iconColor }) =>
  css(
    iconColor === 'inherit' &&
      css`
        color: ${Colors.gieplGreen};
      `,
    iconColor !== 'inherit' &&
      iconColor &&
      css`
        color: ${iconColor};
      `
  )
);
IconRow.displayName = 'IconRow';
