import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import RevealInView from '../../atoms/reveal-in-view/RevealInView';
import Title from '../../atoms/title/Title';
import ResponsiveImageWithRatio from '../../atoms/responsive-image-with-ratio/ResponsiveImageWithRatio';
import { ProductCard } from '../../../types/models/cms';
import Colors, { withOpacity } from '../../../tokens/Colors';
import Spacings from '../../../tokens/Spacings';
import Markdown from '../../atoms/markdown/Markdown';
import Spacer from '../../atoms/spacer/Spacer';
import LineHeights from '../../../tokens/LineHeights';
import TextButton from '../../atoms/text-button/TextButton';
import useDictionary from '../../../hooks/useDictionary';
import Icons from '../../../tokens/icons/Icons';
import Sizes from '../../../tokens/Sizes';
import MediaQueries from '../../../tokens/MediaQueries';
import Icon from '../../atoms/icon/Icon';

export interface ProductCardWithHoverProps extends ProductCard {
  index: number;
  learnMoreClick: (title: string) => void;
}

const ProductCardWithHover: FC<ProductCardWithHoverProps> = ({
  title = '',
  description = '',
  cardImage,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  learnMoreClick = () => {},
  index
}: ProductCardWithHoverProps) => {
  const [isHover, toggleHover] = useState<boolean>(false);
  const dictionary = useDictionary();

  return (
    <>
      <ProductCardContainer delay={0.1 * index}>
        <CardSection
          onMouseEnter={() => toggleHover(true)}
          onMouseOver={() => toggleHover(true)}
          onMouseOut={() => toggleHover(false)}
          onFocus={() => toggleHover(true)}
          onBlur={() => toggleHover(false)}
        >
          {cardImage && <StyledResponsiveImage ratio={0.7} {...cardImage} />}
          <AnimatePresence>
            {isHover && (
              <HoverContainer {...overlayMotionProps}>
                <StyledTitle as="h3">
                  <Markdown text={title} />
                </StyledTitle>
                <Spacer spacing={Spacings.xLarge} />
                <DescriptionSection>
                  <Markdown text={description} />
                </DescriptionSection>
                <Spacer spacing={Spacings.xLarge} />
                <StyledTextButton
                  trailing={<Icon icon={Icons.arrowRight} size={Sizes.small} />}
                  onMouseEnter={() => toggleHover(true)}
                  onMouseOver={() => toggleHover(true)}
                  onMouseOut={() => toggleHover(false)}
                  onClick={() => learnMoreClick(title)}
                >
                  <Markdown text={dictionary.get('Learn More')} />
                </StyledTextButton>
              </HoverContainer>
            )}
          </AnimatePresence>
        </CardSection>
      </ProductCardContainer>
    </>
  );
};

export default ProductCardWithHover;

const ProductCardContainer = styled(RevealInView)``;

const CardSection = styled.div`
  padding: ${Spacings.large} 0;
  box-sizing: border-box;
  box-shadow: 0 0 31px 0 ${Colors.boxShadow};
  position: relative;
  ${MediaQueries.tablet} {
    min-height: 400px;
  }
`;

const HoverContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  background: ${withOpacity(Colors.white, 0.99)};
  z-index: 1;
  height: 100%;
  padding: ${Spacings.medium};
`;

const StyledResponsiveImage = styled(ResponsiveImageWithRatio)`
  object-fit: contain;
  margin: 0 0 ${Spacings.medium};
`;

const StyledTitle = styled(Title)`
  color: ${Colors.gieplGreen};
  font-weight: bold;
`;

const DescriptionSection = styled.div`
  line-height: ${LineHeights.small};
`;

const StyledTextButton = styled(TextButton)`
  color: ${Colors.gieplGreen};
`;

const overlayMotionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};
