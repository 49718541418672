import styled from '@emotion/styled';
import React, { FC, useRef } from 'react';

import useInViewOnce from '../../../hooks/useInviewOnce';

import Reveal, { RevealProps } from '../reveal/Reveal';

export interface RevealInViewProps extends Omit<RevealProps, 'show'> {
  offset?: string;
  onAnimationStart?: () => void;
}

const RevealInView: FC<RevealInViewProps> = ({ offset = '15vh', onAnimationStart, ...props }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const { inView } = useInViewOnce({ targetRef, onShow: onAnimationStart });

  return (
    <div>
      <Target offset={offset} ref={targetRef} />
      <Reveal {...props} show={inView} />
    </div>
  );
};

export default RevealInView;

const Target = styled.div<{ offset: string }>(
  ({ offset }) => `
  margin-top: ${offset};
  position: absolute;
`
);
