import React, { forwardRef } from 'react';

const Plus = forwardRef<SVGSVGElement, React.SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    role="img"
    ref={ref}
    {...props}
  >
    <path d="M24 11.25H12.75V0h-1.5v11.25H0v1.5h11.25V24h1.5V12.75H24v-1.5z" fill="currentColor" />
  </svg>
));
Plus.displayName = 'Plus';
export default Plus;
