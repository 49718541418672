import { css, keyframes } from '@emotion/react';
import { shouldNotForwardProps } from '../../../helpers/shouldForwardProps';
import styled from '@emotion/styled';
import Opacities from '../../../tokens/Opacities';
import React, { forwardRef } from 'react';
import Colors from '../../../tokens/Colors';

export enum SpinnerSizes {
  small = 'small',
  medium = 'medium'
}

export enum SpinnerTypes {
  default = 'default',
  inverted = 'inverted'
}

const ANIMATION_SPEED = `3.4s`;

export interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Whether or not the spinner is disabled
   */
  disabled?: boolean;
  /**
   * Size of the spinner
   */
  size?: SpinnerSizes;
  /**
   * Dark or light version of the theming
   */
  type?: SpinnerTypes;
  /**
   * Support accessibility by providing a name
   */
  // TODO: soon to be required (aria)
  iconLabel?: string;
}

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  (
    {
      size = SpinnerSizes.small,
      disabled = false,
      type = SpinnerTypes.default,
      iconLabel = 'progress',
      ...props
    }: SpinnerProps,
    ref
  ) => {
    const strokeSize: number = size === SpinnerSizes.small ? 16 : 32;
    const strokeWidth: number = size === SpinnerSizes.small ? 2 : 4;

    return (
      <SpinnerContainer {...props} ref={ref}>
        <Svg
          width={`${strokeSize}px`}
          height={`${strokeSize}px`}
          viewBox={`0 0 ${strokeSize * 10} ${strokeSize * 10}`}
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label={iconLabel}
          disabled={disabled}
          size={strokeSize}
        >
          <Circle
            type={type}
            size={strokeSize}
            isSmall={size === SpinnerSizes.small}
            shapeRendering="geometricPrecision"
            fill="none"
            strokeWidth={strokeWidth * 10}
            strokeLinecap="butt"
            cx={Math.floor(strokeSize * 5)}
            cy={Math.floor(strokeSize * 5)}
            r={Math.floor((strokeSize - strokeWidth - 2) * 5)}
          />
        </Svg>
      </SpinnerContainer>
    );
  }
);

Spinner.displayName = 'Spinner';

export default Spinner;

const SpinnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
SpinnerContainer.displayName = 'SpinnerContainer';

const rotator = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const dashConstruct = (isSmall = false) => keyframes`
  0% {
    stroke-dashoffset: 260%;
  }
  50% {
    stroke-dashoffset: ${isSmall ? 25 : 0}%;
    transform: rotate(180deg);
  }
  100% {
    stroke-dashoffset: 260%;
    transform: rotate(725deg);
  }
`;

const dash = dashConstruct();
const dashSmall = dashConstruct(true);

const Svg = styled(
  'svg',
  shouldNotForwardProps(true, 'size', 'disabled')
)<{
  disabled: boolean;
  size: number;
}>(({ size, disabled }) =>
  css(
    css`
      animation: ${rotator} ${ANIMATION_SPEED} linear infinite;
      width: ${size}px;
      height: ${size}px;
    `,
    disabled &&
      css`
        opacity: ${Opacities.low};
      `
  )
);
Svg.displayName = 'Svg';

const Circle = styled(
  'circle',
  shouldNotForwardProps(true, 'type', 'size', 'isSmall')
)<{
  type: SpinnerTypes;
  size: number;
  isSmall: boolean;
}>(
  ({ isSmall, size, type }) => css`
    stroke: ${type === SpinnerTypes.default ? Colors.gieplGreen : Colors.graphiteBlack};
    stroke-dasharray: 260%;
    stroke-dashoffset: 0;
    transform-origin: ${Math.floor(size * 5)}px ${Math.floor(size * 5)}px;
    animation: ${isSmall ? dashSmall : dash} ${ANIMATION_SPEED} ease-in-out infinite;
  `
);
Circle.displayName = 'Circle';
