import React from 'react';
import MediaQueries from '../../../tokens/MediaQueries';

import { isWebPSupport } from '../../../helpers/dom';
import { MediaItem } from '../../../types/models/cms';
import Image, { ImageProps } from '../image/Image';

export const smallMediaQuery = MediaQueries.smallMediaQueryValue;
export const largeMediaQuery = MediaQueries.largeMediaQueryValue;

export interface ResponsiveImageProps
  extends Omit<MediaItem, 'id' | 'video'>,
    Omit<ImageProps, 'alternative' | 'source'> {
  /**
   * Classname
   */
  className?: string;
  /**
   * Condition to be fullbleed
   */
  isFullbleed?: boolean;
  /**
   * Condition to be presentation data so alt is not required
   */
  isPresentation?: boolean;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  alt,
  className,
  imageLarge,
  imageSmall,
  isFullbleed,
  isPresentation,
  role,
  title,
  onLoad,
  onError,
  ...imgProps
}) => {
  const onLoadImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    onLoad && onLoad(event);
  };
  const onErrorImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    onError && onError(event);
  };
  /** Throw error if no alt */
  const imgAlt = alt || title || '';
  return (
    <>
      <picture>
        {isWebPSupport && (
          <source srcSet={imageSmall.responsiveImage.webpSrcSet} media={smallMediaQuery} />
        )}
        <source srcSet={imageSmall.responsiveImage.srcSet} media={smallMediaQuery} />
        {isWebPSupport && (
          <source srcSet={imageLarge.responsiveImage.webpSrcSet} media={largeMediaQuery} />
        )}
        <source srcSet={imageLarge.responsiveImage.srcSet} media={largeMediaQuery} />
        <Image
          alternative={imgAlt}
          className={`${isFullbleed ? 'fullbleed' : ''} ${className}`}
          role={isPresentation || !imgAlt ? 'presentation' : role}
          source={imageLarge.responsiveImage.src}
          onError={onErrorImage}
          onLoad={onLoadImage}
          {...imgProps}
        />
      </picture>
    </>
  );
};

export default ResponsiveImage;
