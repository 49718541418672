import { MutableRefObject, Ref, RefCallback, useCallback } from 'react';

/**
 * Combine Refs to tap into refs without breaking the forwardRef or you can use it as a forwardRef on another component
 *
 * ```js
 * import { useCombinedRef } from '@polestar/component-warehouse-react';
 *
 * const MyComponent = forwardRef<HTMLDivElement, MyComponentProps>((props, ref) => {
 *    const internalRef = useRef<HTMLDivElement>();
 *    const combinedRef = useCombinedRef(ref, internalRef);
 *
 *     const handleChange = useCallback((newValue) => {
 *        // focus an element
 *        internalRef?.current?.focus();
 *
 *        // or use a rendered value for calculation
 *        const value = (internalRef.current?.getBoundingClientRect().width || 0);
 *     }, [onSelect, onChange, value, name]);
 *
 *    return <XXXElement ref={combinedRef} onChange={handleChange}/>
 * }
 * ```
 *
 * @storybook Hooks/useCombinedRef
 *
 * @param refs:Ref<T>[]
 * @returns RefCallback<T>
 */
const useCombinedRef = <T>(...refs: Ref<T>[]): RefCallback<T> => {
  const combinedCallback = useCallback((node: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        (ref as MutableRefObject<T>).current = node;
      }
    });
  }, refs);

  return combinedCallback;
};

export default useCombinedRef;
