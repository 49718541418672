import React, { forwardRef } from 'react';
import { CrossAxisAlignment, MainAxisAlignment } from '../../../tokens/Alignments';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { shouldNotForwardProps } from '../../../helpers/shouldForwardProps';

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Makes the component grow to the available space
   *
   * @default false
   */
  stretch?: boolean;
  /**
   * Reverse the mainAxisAlignment
   *
   * @default false
   */
  reverse?: boolean;
  /**
   * Alignment options over the main axis (horizontal)
   */
  mainAxisAlignment?: MainAxisAlignment;
  /**
   * Alignment options over the cross axis (vertical)
   */
  crossAxisAlignment?: CrossAxisAlignment;
}

const Row = forwardRef<HTMLDivElement, RowProps>((props: RowProps, ref) => {
  return <StyledRow {...props} ref={ref} />;
});

Row.displayName = 'Row';

export default Row;

const StyledRow = styled(
  'div',
  shouldNotForwardProps(true, 'crossAxisAlignment', 'reverse', 'mainAxisAlignment', 'stretch')
)<RowProps>(({ reverse, mainAxisAlignment, crossAxisAlignment, stretch }) =>
  css(
    css`
      display: flex;
      flex-direction: ${reverse ? 'row-reverse' : 'row'};
      justify-content: ${mainAxisAlignment};
      align-items: ${crossAxisAlignment};
      max-height: 100%;
    `,
    stretch &&
      css`
        flex-grow: 1;
      `
  )
);
