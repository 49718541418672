import { useViewportScroll } from 'framer-motion';
import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';

import { hasIntersectionObserver, isClient } from '../helpers/dom';

export type InViewResponse = { inView: boolean };
export const initialInViewResponse: InViewResponse = { inView: false };

/**
 * A hook to check if element is in view
 * @param targetRef RefObject<HTMLElement>
 * @param inViewRef MutableRefObject<InViewResponse>
 * @param callback function to pass response rather than get as state. This helps to avoid render each time
 * @example
 * const targetRef = useRef();
 * const { inView } = useInView({ targetRef });
 * @example
 * useInView({ targetRef, callback: console.log });
 */
export default function useInView({
  targetRef,
  inViewRef = useRef(initialInViewResponse),
  callback
}: {
  targetRef?: RefObject<HTMLElement>;
  inViewRef?: MutableRefObject<InViewResponse>;
  callback?: (response: InViewResponse) => void;
}): InViewResponse {
  /** State for InViewResponse */
  const [inViewResponse, setInViewResponse] = useState(inViewRef.current);

  const update = (inView: boolean) => {
    const prev = inViewRef.current;
    if (prev.inView === inView) return;

    const next = { inView };
    inViewRef.current = next;
    if (callback) {
      callback(next);
    } else {
      setInViewResponse(next);
    }
  };

  /** When mounted */
  useEffect(() => {
    if (!isClient || !targetRef || !targetRef.current || !hasIntersectionObserver) return;
    const io = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        update(entry.isIntersecting && entry.intersectionRatio >= 0);
      }
    });
    io.observe(targetRef.current);
    return () => {
      if (hasIntersectionObserver && io) {
        io.disconnect();
      }
    };
  }, [targetRef]);

  /* Trigger event when scroll is updated */
  const { scrollY } = useViewportScroll();
  useEffect(() => {
    if (!isClient || !targetRef || !targetRef.current || hasIntersectionObserver) return;
    return scrollY.onChange(() => {
      if (!targetRef.current) return;
      const { top, bottom } = targetRef.current.getBoundingClientRect();
      const { innerHeight } = window;
      const isCover = top < 0 && bottom > innerHeight;
      const isTopContain = top >= 0 && top < innerHeight;
      const isBottomContain = bottom > 0 && bottom <= innerHeight;
      update(isCover || isTopContain || isBottomContain);
    });
  }, [scrollY]);

  return inViewResponse;
}
