import { ComponentThemeData } from '../../../types';
import { FontWeight } from '../../../tokens/FontWeights';
import { Font } from '../../../tokens/Fonts';
import Colors, { Color, withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';
import { FontStyle } from '../../../tokens/FontStyle';
import FontSizes, { FontSize } from '../../../tokens/FontSizes';
import LetterSpacings, { LetterSpacing } from '../../../tokens/LetterSpacings';
import LineHeights, { LineHeight } from '../../../tokens/LineHeights';
import { ResponsiveThemeData } from '../../../themes/Theme';

export interface TextSizeThemeData {
  fontSize: ResponsiveThemeData<FontSize>;
  letterSpacing: ResponsiveThemeData<LetterSpacing>;
  lineHeight: ResponsiveThemeData<LineHeight>;
}

export interface TextThemeBaseData {
  fontFamily?: Font;
  fontWeight?: FontWeight;
  fontStyle?: FontStyle;
  color: Color;
}

export interface TextThemeData extends TextThemeBaseData {
  sizes: {
    medium: TextSizeThemeData;
  };
}

export interface TextThemesData {
  text: ComponentThemeData<TextThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    text: TextThemeData;
  }
}

const TextTheme: TextThemesData = {
  text: {
    light: {
      color: withOpacity(Colors.graphiteBlack, Opacities.full),
      sizes: {
        medium: {
          fontSize: {
            mobile: FontSizes.body
          },
          letterSpacing: {
            mobile: LetterSpacings.small
          },
          lineHeight: {
            mobile: LineHeights.small
          }
        }
      }
    },
    dark: {
      color: withOpacity(Colors.white, Opacities.full)
    }
  }
};

export default TextTheme;
