import { ResponsiveThemeData } from '../../../themes/Theme';
import Colors, { blend, Color, withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';
import Spacings, { DimensionalSpacing } from '../../../tokens/Spacings';
import { ComponentThemeData } from '../../../types';

export interface AccordionThemeData {
  states: AccordionStateStyles;
  padding: ResponsiveThemeData<DimensionalSpacing>;
}

export interface AccordionStateStyles {
  enabled: AccordionStyles;
  disabled: AccordionStyles;
  hover: AccordionStyles;
  active: AccordionStyles;
}

export interface AccordionStyles {
  color: Color;
  borderColor: Color;
}

export interface AccordionThemesData {
  accordion: ComponentThemeData<AccordionThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    accordion: AccordionThemeData;
  }
}

const AccordionTheme: AccordionThemesData = {
  accordion: {
    light: {
      padding: {
        mobile: `${Spacings.large} 0`
      },
      states: {
        enabled: {
          color: Colors.graphiteBlack,
          borderColor: Colors.graphiteBlack
        },
        disabled: {
          color: withOpacity(Colors.graphiteBlack, Opacities.low),
          borderColor: blend(Colors.white, Colors.graphiteBlack, Opacities.xLow)
        },
        hover: {
          color: Colors.graphiteBlack,
          borderColor: Colors.graphiteBlack
        },
        active: {
          color: Colors.graphiteBlack,
          borderColor: Colors.graphiteBlack
        }
      }
    },
    dark: {
      states: {
        enabled: {
          color: Colors.white,
          borderColor: Colors.white
        },
        disabled: {
          color: withOpacity(Colors.white, Opacities.low),
          borderColor: withOpacity(Colors.white, Opacities.xLow)
        },
        hover: {
          color: Colors.white,
          borderColor: Colors.white
        },
        active: {
          color: Colors.white,
          borderColor: Colors.white
        }
      }
    }
  }
};

export default AccordionTheme;
