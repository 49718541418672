import React, { forwardRef, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Grids from '../../../tokens/Grids';
import MediaQueries from '../../../tokens/MediaQueries';
import { Span } from '../../../tokens/Spans';
import DeviceTypes from '../../../tokens/DeviceTypes';

type SpanType = keyof typeof DeviceTypes;
const spanTypes = Object.keys(DeviceTypes) as SpanType[];

/**
 * @internal
 */
export const GridContext = React.createContext<{
  span: Span | null;
}>({
  span: null
});

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Whether this grid is inside another Grid element. When true the column widths are calculated correctly.
   */
  nested?: boolean;
}

const Grid = forwardRef<HTMLDivElement, GridProps>(
  ({ nested = false, ...props }: GridProps, ref) => {
    const { span } = useContext(GridContext);

    if (nested && span) {
      const filledSpan: Span = { ...span };
      // eslint-disable-next-line
      let previousValue: any = filledSpan.mobile;
      for (const i in spanTypes) {
        const deviceType = spanTypes[i];

        if (!(deviceType in filledSpan)) {
          filledSpan[deviceType] = previousValue;
        }

        // Hidden inside a nested, so force set to 1 to keep valid css
        if (filledSpan[deviceType] === 'hidden') {
          filledSpan[deviceType] = 1;
        }

        previousValue = filledSpan[deviceType];
      }
      return <NestedGridContainer ref={ref} {...props} span={filledSpan} />;
    }

    return <GridContainer ref={ref} {...props} />;
  }
);
Grid.displayName = 'Grid';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${Grids.mobile.columns}, 1fr);
  grid-gap: ${Grids.mobile.gutter};
  align-items: start;

  ${MediaQueries.tablet} {
    grid-template-columns: repeat(${Grids.tablet.columns}, 1fr);
    grid-gap: ${Grids.tablet.gutter};
  }

  ${MediaQueries.desktop} {
    grid-template-columns: repeat(${Grids.desktop.columns}, 1fr);
    grid-gap: ${Grids.desktop.gutter};
  }
`;

GridContainer.displayName = 'GridContainer';

const NestedGridContainer = styled(GridContainer)<{ span: Span }>(
  ({ span }) => css`
    grid-template-columns: repeat(${span.mobile}, 1fr);

    ${MediaQueries.tablet} {
      grid-template-columns: repeat(${span.tablet}, 1fr);
    }

    ${MediaQueries.desktop} {
      grid-template-columns: repeat(${span.desktop}, 1fr);
    }
  `
);

NestedGridContainer.displayName = 'NestedGridContainer';

export default Grid;
