import Spacings, { Spacing } from './Spacings';

export interface GridsProps {
  columns: number;
  gutter: Spacing;
}

const Grids: { [key: string]: GridsProps } = Object.freeze({
  mobile: {
    columns: 4,
    gutter: '3vw'
  },

  tablet: {
    columns: 8,
    gutter: Spacings.large
  },

  desktop: {
    columns: 12,
    gutter: Spacings.xLarge
  }
});

export default Grids;
