import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, useLocation, Route, Redirect } from 'react-router-dom';
import { useQuery } from 'graphql-hooks';
import MainLayoutRoute from './components/layouts/MainLayoutRoute';
import HomeView from './components/views/home-view/HomeView';
import ProjectContext, { DictionaryEntry } from './contexts/project/ProjectContext';
import GET_PROJECT_CONTENT from './graphQL/queries/getProjectContent';
import GET_PRODUCTS_CONTENT from './graphQL/queries/getPoductContent';
import GET_SEO_CONTENT from './graphQL/queries/getSeoContent';
import ContactUsView from './components/views/contact-us/ContactUsView';
import AboutUsView from './components/views/about-us/AboutUsView';
import GridDebugger from './components/atoms/grid-debugger/GridDebugger';
import ProductContext from './contexts/product/ProductContext';
import ProductsView from './components/views/products-view/ProductsView';
import PageNotFound from './components/views/page-not-found-view/PageNotFoundView';
import { ThemeProvider } from '@emotion/react';
import Themes from './themes/Themes';

const App: FC = () => {
  const { data: projectContent } = useQuery(GET_PROJECT_CONTENT);
  const { data: productsContent } = useQuery(GET_PRODUCTS_CONTENT);
  const productsList = productsContent?.productCardList;
  const { pathname } = useLocation();
  const { data: seoData, loading } = useQuery(GET_SEO_CONTENT, {
    variables: {
      pathname: pathname.includes('home')
        ? '/'
        : pathname.length > 1 && pathname.lastIndexOf('/') !== 0
        ? pathname.substring(0, pathname.lastIndexOf('/'))
        : pathname
    }
  });

  const dictionary: DictionaryEntry[] = projectContent?.allSimpleTexts || [];
  if (!!seoData && !loading) {
    return (
      <>
        {seoData && seoData.page && seoData.page.seoMetaTag && (
          <Helmet>
            <title>{seoData.page.seoMetaTag.title}</title>
            <meta name="description" content={seoData.page.seoMetaTag.description} />
            <meta property="og:image" content={seoData.page.seoMetaTag.image.responsiveImage.src} />
            <meta
              property="twitter:image"
              content={seoData.page.seoMetaTag.image.responsiveImage.src}
            />
          </Helmet>
        )}
        <ThemeProvider theme={Themes.light}>
          <ProductContext.Provider value={{ products: productsList }}>
            <GridDebugger />
            <ProjectContext.Provider value={{ dictionary }}>
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/home" />
                </Route>
                <MainLayoutRoute path="/home" exact component={HomeView} />
                <MainLayoutRoute path="/contact-us" component={ContactUsView} />
                <MainLayoutRoute path="/about-us" component={AboutUsView} />
                <MainLayoutRoute path="/products" component={ProductsView} />
                <MainLayoutRoute path="/page-not-found" component={PageNotFound} noHeaderMenu />
                <Route path="*">
                  <Redirect to="/page-not-found" />
                </Route>
              </Switch>
            </ProjectContext.Provider>
          </ProductContext.Provider>
          <style>{globalStyles}</style>
        </ThemeProvider>
      </>
    );
  }
  return <></>;
};

const globalStyles = `.fullbleed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}`;
export default App;
