import React, { FC } from 'react';

import parseMarkdown from '../../../helpers/parseMarkdown';

export interface MarkdownProps {
  text?: string;
}

const Markdown: FC<MarkdownProps> = ({ text }) => {
  // TODO: add more pattern
  if (!text) return null;
  return (
    <>
      {parseMarkdown(text).map((item, index) => {
        if (!item) return null;
        const key = `${item.style}${item.text}${index}`;
        switch (item.style) {
          /** Use code for superscript */
          case 'code':
            return <sup key={key}>{item.text}</sup>;
          case 'bold':
            return <strong key={key}>{item.text}</strong>;
          case 'link':
            return (
              <a key={key} href={item.href}>
                {item.text}
              </a>
            );
          case 'br':
            return <br key={key} />;
          default:
            return <span key={key}>{item.text}</span>;
        }
      })}
    </>
  );
};

export default Markdown;
