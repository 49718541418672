import { TextSizeThemeData, TextThemeBaseData } from '../text/Text.theme';
import Colors from '../../../tokens/Colors';
import FontSizes from '../../../tokens/FontSizes';
import LetterSpacings from '../../../tokens/LetterSpacings';
import LineHeights from '../../../tokens/LineHeights';
import { ComponentThemeData } from '../../..//types';

export interface TitleThemeData extends TextThemeBaseData {
  sizes: {
    medium: TextSizeThemeData;
  };
}

export interface TitleThemesData {
  title: ComponentThemeData<TitleThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    title: TitleThemeData;
  }
}

const TitleTheme: TitleThemesData = {
  title: {
    light: {
      color: Colors.graphiteBlack,
      sizes: {
        medium: {
          fontSize: {
            mobile: FontSizes.large
          },
          letterSpacing: {
            mobile: LetterSpacings.large
          },
          lineHeight: {
            mobile: LineHeights.large
          }
        }
      }
    },
    dark: {
      color: Colors.white
    }
  }
};

export default TitleTheme;
