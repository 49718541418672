import BezierEasing from 'bezier-easing';

const Easings = {
  EaseOut: BezierEasing(0.15, 1.0, 0.35, 1.0),
  EaseInOutStrong: BezierEasing(0.65, 0.0, 0.0, 1.0),
  EaseInOutSoft: BezierEasing(0.35, 0.0, 0.0, 1.0),
  CssEaseOut: 'cubic-bezier(0.15, 1.0, 0.35, 1.0)',
  CssEaseInOutString: 'cubic-bezier(0.65, 0, 0, 1)',
  CssEaseInOutSoft: 'cubic-bezier(0.35, 0.0, 0.0, 1.0)'
};
export default Easings;
