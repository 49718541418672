import { useViewportScroll } from 'framer-motion';
import { RefObject, useEffect, useState } from 'react';

interface CustomIndicatorVisibilityProps {
  rootRef: RefObject<HTMLElement>;
}

/**
 * A hook to snap lightly by scroll
 * @param rootRef element to check the position
 * @example
 * const rootRef = useRef<HTMLDivElement>();
 * const isCustomIndicatorVisible = useCustomIndicatorVisibility({ rootRef });
 */
export default function useCustomIndicatorVisibility({
  rootRef
}: CustomIndicatorVisibilityProps): boolean {
  const [isVisible, setIsVisible] = useState(true);

  /**
   * Trigger event when scroll is updated
   */
  const { scrollY } = useViewportScroll();
  useEffect(() => {
    return scrollY.onChange(() => {
      const nextIsVisible =
        (rootRef.current?.getBoundingClientRect().bottom || 0) / window.innerHeight > 0.7;
      if (nextIsVisible === isVisible) return;
      setIsVisible(nextIsVisible);
    });
  }, [isVisible, scrollY]);

  return isVisible;
}
