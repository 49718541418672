import { ComponentThemeData } from '../../../types';
import Colors, { blend, Color, withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';

export interface ImageThemeData {
  color: Color;
  backgroundColor: Color;
  backgroundDimColor: Color;
  iconColor: Color;
}

export interface ImageThemesData {
  image: ComponentThemeData<ImageThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    image: ImageThemeData;
  }
}

const ImageTheme: ImageThemesData = {
  image: {
    light: {
      color: withOpacity(Colors.graphiteBlack, Opacities.medium),
      backgroundColor: blend(Colors.white, Colors.graphiteBlack, Opacities.xLow),
      backgroundDimColor: Colors.greyWhite,
      iconColor: withOpacity(Colors.graphiteBlack, Opacities.medium)
    },
    dark: {
      color: withOpacity(Colors.white, Opacities.medium),
      backgroundColor: blend(Colors.graphiteBlack, Colors.white, Opacities.xLow),
      backgroundDimColor: withOpacity(Colors.ironGrey, Opacities.xLow),
      iconColor: withOpacity(Colors.white, Opacities.medium)
    }
  }
};

export default ImageTheme;
