import { ForwardRefExoticComponent, SVGAttributes } from 'react';

import Close from './components/basic/Close';
import Mail from './components/basic/Mail';
import Phone from './components/basic/Phone';
import ClosePairedWithText from './components/text-pairing/Close';
import MapMarker from './components/basic/MapMarker';
import Menu from './components/basic/Menu';
import ArrowRight from './components/navigation/ArrowRight';
import Facebook from './components/social/Facebook';
import Instagram from './components/social/Instagram';
import WhatsApp from './components/social/Whatsapp';
import Plus from './components/basic/Plus';
import Minus from './components/basic/Minus';

// ============================================ //
export type IconData = {
  glyph: ForwardRefExoticComponent<SVGAttributes<SVGSVGElement>>;
  textPairingGlyph?: ForwardRefExoticComponent<SVGAttributes<SVGSVGElement>>;
  deprecated?: string | undefined;
  alias?: boolean;
  group?: 'basic' | 'navigation' | 'car' | 'media' | 'social' | 'text-pairing';
  followDirection?: boolean;
};

const _Icons = {
  close: { glyph: Close, group: 'basic', textPairingGlyph: ClosePairedWithText },
  phone: { glyph: Phone, group: 'basic' },
  mail: { glyph: Mail, group: 'basic' },
  mapMarker: { glyph: MapMarker, group: 'basic' },
  plus: { glyph: Plus, group: 'basic' },
  menu: { glyph: Menu, group: 'basic' },
  minus: { glyph: Minus, group: 'basic' },
  arrowRight: { glyph: ArrowRight, group: 'navigation' },
  facebook: { glyph: Facebook, group: 'social' },
  instagram: { glyph: Instagram, group: 'social' },
  whatsapp: { glyph: WhatsApp, group: 'social' }
};

const Icons = Object.freeze(_Icons) as {
  [key in keyof typeof _Icons]: IconData;
};

export default Icons;
