import React, { forwardRef, ReactChild } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Alignments from '../../../tokens/Alignments';
import { MediaSection, SectionInner } from '../../atoms/section/Section';
import { responsiveStyles } from '../../../helpers/responsiveStyle';
import Grids from '../../../tokens/Grids';
import MediaQueries from '../../../tokens/MediaQueries';
import Spacings from '../../../tokens/Spacings';
import Colors from '../../../tokens/Colors';

export interface HeroSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Move the content outside of the section on mobile. Default it will stay on top.
   */
  mobilePosition?: Alignments.top | Alignments.bottom;
  /**
   * The slot for an image or video as a background
   */
  background: ReactChild;
}

const HeroSection = forwardRef<HTMLDivElement, HeroSectionProps>(
  ({ mobilePosition, children, ...props }: HeroSectionProps, ref) => {
    return (
      <StyledSection {...props} ref={ref}>
        <StyledMedia mobilePosition={mobilePosition}>{props.background}</StyledMedia>
        <ContentWrapper mobilePosition={mobilePosition}>
          <SectionInner>
            <ContentInner>{children}</ContentInner>
          </SectionInner>
        </ContentWrapper>
      </StyledSection>
    );
  }
);
HeroSection.displayName = 'HeroSection';

export default HeroSection;

const StyledSection = styled(MediaSection)`
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
`;

const ContentWrapper = styled.div<{ mobilePosition?: Alignments }>(({ mobilePosition }) =>
  css(
    css`
      ${responsiveStyles({
        left: {
          mobile: Grids.mobile.gutter,
          tablet: Grids.tablet.gutter,
          desktop: Grids.desktop.gutter
        },
        right: {
          mobile: Grids.mobile.gutter,
          tablet: Grids.tablet.gutter,
          desktop: Grids.desktop.gutter
        }
      })}
    `,
    !!mobilePosition &&
      css`
        ${MediaQueries.tablet} {
          position: absolute;
        }
      `,
    !mobilePosition &&
      css`
        position: absolute;
      `
  )
);

ContentWrapper.displayName = 'StyledContentWrapper';

const ContentInner = styled.div(
  () => css`
    ${responsiveStyles({
      margin: {
        mobile: `${Spacings.xxLarge} 0`,
        tablet: `${Spacings.xLarge} 0`,
        desktop: `${Spacings.xxxxLarge} 0`
      }
    })}
  `
);

const StyledMedia = styled.div<{ mobilePosition?: Alignments }>(({ mobilePosition }) =>
  css(
    css`
      background-color: ${Colors.white};
      display: flex;
      width: 100%;
    `,
    responsiveStyles({
      order: {
        mobile: mobilePosition === Alignments.top ? 1 : 0,
        tablet: 0
      }
    })
  )
);
StyledMedia.displayName = 'StyledMedia';
