import { ComponentThemeData } from '../../../types';
import Colors, { Color, withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';

export interface BackdropThemeData {
  backgroundColor: Color;
  backgroundColorDarker: Color;
}

export interface BackdropThemesData {
  backdrop: ComponentThemeData<BackdropThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    backdrop: BackdropThemeData;
  }
}

const BackdropTheme: BackdropThemesData = {
  backdrop: {
    light: {
      backgroundColor: withOpacity(Colors.graphiteBlack, Opacities.xLow),
      backgroundColorDarker: 'rgba(0,0,0,.9)'
    },
    dark: {
      backgroundColor: withOpacity(Colors.white, Opacities.xLow),
      backgroundColorDarker: 'rgba(255,255,255,.9)'
    }
  }
};

export default BackdropTheme;
