/**
 * @internal
 */
import { css } from '@emotion/react';
import MediaQueries from '../tokens/MediaQueries';
import { ResponsiveThemeData } from '../themes/Theme';
import DeviceTypes from '../tokens/DeviceTypes';
import { SerializedStyles } from '@emotion/serialize';

export interface ResponsiveThemeDataObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: ResponsiveThemeData<any>;
}

export interface ResponsiveThemeDataRenderObject {
  [key: string]: (value: string | number, deviceType?: DeviceTypes) => string | SerializedStyles;
}

/**
 * Helper function to generate emotion css with media queries
 *
 * example:
 * responsiveStyle({ mobile: '16px', desktop: '24px'} , (value) => `font-size: ${value};`)
 */
/* eslint-disable */
export const responsiveStyle = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responsiveThemeData: ResponsiveThemeData<any>,
  valueFunction: (arg: string) => string
) => {
  return css(
    css`
      ${valueFunction(responsiveThemeData.mobile)}
    `,
    responsiveThemeData.tablet &&
      css`
        ${MediaQueries.tablet} {
          ${valueFunction(responsiveThemeData.tablet)}
        }
      `,
    responsiveThemeData.desktop &&
      css`
        ${MediaQueries.desktop} {
          ${valueFunction(responsiveThemeData.desktop)}
        }
      `,
    responsiveThemeData.desktopWide &&
      css`
        ${MediaQueries.desktopWide} {
          ${valueFunction(responsiveThemeData.desktopWide)}
        }
      `
  );
};

/**
 * Helper function to generate emotion css with media queries for multiple properties.
 *
 * Be aware that if a breakpoint has already some styling, but is not defined (no `tablet` settings), then you need
 * to add that breakpoint value (even if it is a duplicate of mobile breakpoint settings) to override the other
 * values from an extended component for example.
 *
 * example:
 * responsiveStyles({ fontSize: {mobile: '16px', desktop: '24px'} , lineHeight: {mobile: '16px', desktop: '24px'}})
 */
export const responsiveStyles = (
  responsiveProperties: ResponsiveThemeDataObject,
  renderProperty?: ResponsiveThemeDataRenderObject
) => {
  const styles = {
    mobile: null,
    tablet: null,
    desktop: null,
    desktopWide: null
  };

  for (const property in responsiveProperties) {
    const responsiveValues = responsiveProperties[property];
    if (!responsiveValues) {
      continue;
    }

    for (const device in styles) {
      // @ts-ignore device is always in responsiveValues, if not it's ignored by the if check
      const value = responsiveValues[device];
      if (value === undefined) {
        continue;
      }

      // Initialize as object
      // @ts-ignore device is always in styles
      styles[device] = styles[device] === null ? {} : styles[device];

      // @ts-ignore device is always in styles
      styles[device][property] = renderProperty?.[property]
        ? renderProperty[property](value, DeviceTypes[device as keyof typeof DeviceTypes])
        : value;
    }
  }

  return css(
    css(styles.mobile),
    !!styles.tablet &&
      css`
        ${MediaQueries.tablet} {
          ${css(styles.tablet)}
        }
      `,
    !!styles.desktop &&
      css`
        ${MediaQueries.desktop} {
          ${css(styles.desktop)}
        }
      `,
    !!styles.desktopWide &&
      css`
        ${MediaQueries.desktopWide} {
          ${css(styles.desktopWide)}
        }
      `
  );
};
