import React, { FC, useState, useEffect } from 'react';
import Icon, { IconSizes } from '../../atoms/icon/Icon';
import Icons from '../../../tokens/icons/Icons';
import Colors, { withOpacity } from '../../../tokens/Colors';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import Title from '../../atoms/title/Title';
import Markdown from '../../atoms/markdown/Markdown';
import useDictionary from '../../../hooks/useDictionary';
import Spacings from '../../../tokens/Spacings';
import { navBarEntities } from '../../../mocks/header';
import { NavLink } from 'react-router-dom';

const transitionAnimationVariant = {
  initial: { x: 200 },
  animate: {
    x: 0,
    transition: { duration: 0.6 }
  },
  exit: { x: 200, transition: { duration: 0.3 } }
};

const MobileHamburgerMenu: FC = () => {
  const [showNav, toggleNav] = useState<boolean>(false);
  const dictionary = useDictionary();

  const handleMenuClose = (event: React.KeyboardEvent | KeyboardEvent) => {
    if (event.key === 'Escape') {
      toggleNav(false);
    }
  };

  useEffect(() => {
    if (showNav) {
      window.addEventListener('keydown', handleMenuClose);
    }
    return () => window.removeEventListener('keydown', handleMenuClose);
  }, [showNav]);

  return (
    <MobileHamburgerMenuContainer>
      <Icon icon={Icons.menu} color={Colors.gieplGreen} onClick={() => toggleNav(true)} />
      <AnimatePresence>
        {showNav && (
          <NavMenuSection
            initial="initial"
            animate="animate"
            exit="exit"
            variants={transitionAnimationVariant}
          >
            <TitleSection>
              <StyledTitle as="h1">
                <Markdown text={dictionary.get('GIEPL')} />
              </StyledTitle>
              <Icon
                icon={Icons.close}
                color={Colors.white}
                size={IconSizes.large}
                onClick={() => toggleNav(false)}
              />
            </TitleSection>
            <NavMenu>
              {navBarEntities.map(({ name, route }, index) => (
                <NavLink to={route} key={index} onClick={() => toggleNav(false)}>
                  <Markdown text={name} />
                </NavLink>
              ))}
            </NavMenu>
          </NavMenuSection>
        )}
      </AnimatePresence>
    </MobileHamburgerMenuContainer>
  );
};

export default MobileHamburgerMenu;

const MobileHamburgerMenuContainer = styled.div`
  position: relative;
`;

const NavMenuSection = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: ${withOpacity(Colors.gieplGreen, 0.98)};
  z-index: 2;
  padding: ${Spacings.medium} ${Spacings.small};
  box-sizing: border-box;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  color: ${Colors.white};
  font-size: 24px;
`;

const NavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  a {
    padding: ${Spacings.large} 0;
    color: ${Colors.white};
    &.active {
      font-size: ${Spacings.xxLarge};
    }
  }
`;
