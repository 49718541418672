import DeviceTypes from '../../tokens/DeviceTypes';
import Breakpoints from '../../tokens/BreakPoints';
/**
 * Condition whether server-side or client-side
 */
export const isClient: boolean = typeof window === 'object';

export const isSSR = typeof window === 'undefined';

/* eslint-disable */
let passiveSupported = false;
try {
  if (isSSR) {
    const options = {
      get passive() {
        passiveSupported = true;
        return false;
      }
    };
    // @ts-ignore
    window.addEventListener('test', null, options);
    // @ts-ignore
    window.removeEventListener('test', null, options);
  }
} catch (err) {
  passiveSupported = false;
}

/**
 * Calculate the width the scrollbar takes up
 * If this is false we assume the scrollbar is on top of the page like on most touch devices and default on macOS
 */
export const hasScrollbarWidth = (() => {
  if (!isClient) {
    return true;
  }

  const inner = document.createElement('div');
  inner.style.cssText = `
    width: 100%;
    height: 200px;
  `;

  const outer = document.createElement('div');
  outer.style.cssText = `
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 200px;
    height: 150px;
    overflow: hidden;
  `;
  outer.appendChild(inner);

  document.body.appendChild(outer);

  // Width without scrollbar
  const innerWidth = inner.offsetWidth;

  // Width with scrollbar forced
  outer.style.overflow = 'scroll';
  const outerWidth = outer.clientWidth;

  document.body.removeChild(outer);

  return innerWidth - outerWidth > 0;
})();

export const hasPassiveSupport = passiveSupported;

/**
 * Condition if device has touch event
 */
export const isTouchDevice: boolean = isClient && 'ontouchstart' in window;

/**
 * Condition if browser supports IntersectionObserver
 */
export const hasIntersectionObserver = isClient && 'IntersectionObserver' in window;

/**
 * Condition whether it is under storybook
 */
export const isStorybook =
  isClient && /iframe.html/.test(window.location.pathname) && /id=/.test(window.location.search);

/**
 * Condition in development
 */
export const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * Condition in debug
 */
export const isDebug =
  process.env.DEBUG === 'true' || (isClient && /debug=true/.test(window.location.search));

/**
 * UserAgent
 */
export const userAgent: string = isClient ? navigator.userAgent : '';

/**
 * Condition if device is iPhone
 */
export const isIPhone = /iPhone/.test(userAgent);

/**
 * Condition if device is either iPhone or iPad
 * @see https://stackoverflow.com/a/58017456/1808380
 */
export const isIOS =
  // iPhone
  /iPhone/.test(userAgent) ||
  // iPad
  /iPad/.test(userAgent) ||
  // iPadOS (adding isTouchDevice to helps to avoid detecting Safari browser)
  (isTouchDevice &&
    /Mac/.test(userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2);

/**
 * Condition if device is android
 */
export const isAndroid = /Android/.test(userAgent);

/**
 * Condition no WebP support
 * [BUG] Some minor iPhone / iPad / Mac OS / Safari version does not support WebP even though it should be supported
 * @see https://caniuse.com/webp
 */
export const isWebPSupport =
  !/Mac OS X|iPhone OS 12|iPhone OS 13|iPad; CPU OS 12|iPad; CPU OS 13/.test(userAgent) ||
  /Firefox/.test(userAgent) ||
  /Chrome/.test(userAgent);

export type EaseType =
  | 'easeInQuad'
  | 'easeOutQuad'
  | 'easeInOutQuad'
  | 'easeInCubic'
  | 'easeOutCubic'
  | 'easeInOutCubic'
  | 'easeInQuart'
  | 'easeOutQuart'
  | 'easeInOutQuart'
  | 'easeInQuint'
  | 'easeOutQuint'
  | 'easeInOutQuint'
  | 'linear';

/**
 * Return next number by bezier Curve based easing functions
 * @param t time
 * @return number
 * @see http://greweb.me/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
 */
const getEase = (t: number, ease?: EaseType) => {
  switch (ease) {
    case 'easeInQuad':
      return t * t;
    case 'easeOutQuad':
      return t * (2 - t);
    case 'easeInOutQuad':
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    case 'easeInCubic':
      return t * t * t;
    case 'easeOutCubic':
      return --t * t * t + 1;
    case 'easeInOutCubic':
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    case 'easeInQuart':
      return t * t * t * t;
    case 'easeOutQuart':
      return 1 - --t * t * t * t;
    case 'easeInOutQuart':
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    case 'easeInQuint':
      return t * t * t * t * t;
    case 'easeOutQuint':
      return 1 + --t * t * t * t * t;
    case 'easeInOutQuint':
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    case 'linear':
    default:
      return t;
  }
};

/**
 * Scroll to target with duraiton support
 * @param target target element
 * @param x element position
 * @param y element position
 * @param offsetX offset x position
 * @param offsetY offset y position
 * @param duration time
 * @param onComplete function to call once animation is done
 * @param onUpdate function to call while scrolling
 * @param ease ease type
 */
export const scrollTo = ({
  target,
  x = 0,
  y = 0,
  offsetX = 0,
  offsetY = 0,
  duration = 500,
  onComplete,
  onUpdate,
  ease = 'easeInOutCubic'
}: {
  target?: HTMLElement;
  x?: number;
  y?: number;
  offsetX?: number;
  offsetY?: number;
  duration?: number;
  onComplete?: () => void;
  onUpdate?: () => void;
  ease?: EaseType;
}): void => {
  if (!isClient) return;
  const element = target || window;
  const startX = target ? target.scrollLeft : window.pageXOffset;
  const startY = target ? target.scrollTop : window.pageYOffset;
  const endX = x - offsetX;
  const endY = y - offsetY;
  const clock = Date.now();
  const step = () => {
    const elapsed = Date.now() - clock;
    const isTimeup = elapsed > duration;
    const nextEase = getEase(elapsed / duration, ease);
    const positionX = isTimeup ? endX : startX + (endX - startX) * nextEase;
    const positionY = isTimeup ? endY : startY + (endY - startY) * nextEase;
    element.scrollTo(positionX, positionY);
    if (isTimeup) {
      if (onComplete) onComplete();
    } else {
      window.requestAnimationFrame(step);
      if (onUpdate) onUpdate();
    }
  };
  step();
};

/**
 * Scroll to target with duraiton support
 * @param target target element
 * @param x how far to scroll
 * @param y how far to scroll
 * @param offsetX offset x position
 * @param offsetY offset y position
 * @param duration time
 * @param onComplete function to call once animation is done
 * @param onUpdate function to call while scrolling
 * @param ease ease type
 */
export const scrollBy = ({
  target,
  x = 0,
  y = 0,
  offsetX = 0,
  offsetY = 0,
  duration,
  onComplete,
  onUpdate,
  ease
}: {
  target?: HTMLElement;
  x?: number;
  y?: number;
  offsetX?: number;
  offsetY?: number;
  duration?: number;
  onComplete?: () => void;
  onUpdate?: () => void;
  ease?: EaseType;
}): void => {
  if (!isClient) return;
  const startX = target ? target.scrollLeft : window.pageXOffset;
  const startY = target ? target.scrollTop : window.pageYOffset;
  x = startX - x;
  y = startY - y;
  scrollTo({ target, x, y, offsetX, offsetY, duration, onComplete, onUpdate, ease });
};

/**
 * Scroll to an element with duraiton support
 * @param target target element
 * @param selector selector or element to scroll to
 * @param offsetY offset y position
 * @param duration time
 * @param onComplete function to call once animation is done
 * @param onUpdate function to call while scrolling
 * @param ease ease type
 */
export const scrollToElement = ({
  target,
  selector,
  offsetY = 0,
  duration,
  onComplete,
  onUpdate,
  ease
}: {
  target?: HTMLElement;
  selector: HTMLElement | string;
  offsetY?: number;
  duration?: number;
  onComplete?: () => void;
  onUpdate?: () => void;
  ease?: EaseType;
}): void => {
  if (!isClient) return;
  const element = typeof selector === 'string' ? document.querySelector(selector) : selector;
  if (!element) return;
  const { top } = element.getBoundingClientRect();
  const isMobile = window.innerWidth < 767;
  let y = top * -1;
  if (y > 0) {
    y += isMobile ? 110 : 150;
  } else {
    y += isMobile ? 80 : 100;
  }
  if (typeof duration !== 'number') {
    duration = Math.min(1500, Math.abs(top) / 2);
  }
  scrollBy({ target, y, offsetY, duration, onComplete, onUpdate, ease });
};

/**
 * Set dataset variables to body element
 * @param attr dataset attribute
 * @param value dataset value
 * @example
 * setDatasetVariableToBody('isTop', 'true'); // `[data-is-top="true"]` will be added to body
 */
export const setDatasetVariableToBody = (attr: string, value: string | number): void => {
  if (!isClient) return;
  document.body.dataset[attr] = value.toString();
};

/**
 * Remove dataset variables to body element
 * @param attr dataset attribute
 * @example
 * removeDatasetVariableToBody('isTop');
 */
export const removeDatasetVariableToBody = (attr: string): void => {
  if (!isClient) return;
  delete document.body.dataset[attr];
};

/**
 * Set css variables to body element
 * @param attr style attribute
 * @param value style value
 * @example
 * setCSSVariableToBody('header-height', '60px'); // `--header-height: 60px` will be added to body
 */
export const setCSSVariableToBody = (attr: string, value: string | number): void => {
  if (!isClient) return;
  document.body.style.setProperty(`--${attr}`, value.toString());
};

/**
 * Remove css variables to body element
 * @param attr style attribute
 * @example
 * removeCSSVariableToBody('header-height');
 */
export const removeCSSVariableToBody = (attr: string): void => {
  if (!isClient) return;
  document.body.style.removeProperty(`--${attr}`);
};

/**
 * Set app visibility
 * @param visibility app visibility
 */
export const setAppVisibility = (isVisible?: boolean): void => {
  if (!isVisible) {
    removeCSSVariableToBody('app-visibility');
  } else {
    setCSSVariableToBody('app-visibility', '1');
  }
};

const isBreakpoint = {
  [DeviceTypes.mobile]: (): boolean => isClient && window.innerWidth < Breakpoints.tablet,
  [DeviceTypes.tablet]: (): boolean => isClient && window.innerWidth >= Breakpoints.tablet,
  [DeviceTypes.desktop]: (): boolean => isClient && window.innerWidth >= Breakpoints.desktop,
  [DeviceTypes.desktopWide]: (): boolean => isClient && window.innerWidth >= Breakpoints.desktopWide
};

const isPortrait = (): boolean => isClient && window.innerWidth < window.innerHeight;

/**
 * Return condition per breakpoint / orientation
 * @example
 * isMediaQuery.mobile();
 * isMediaQuery.tablet();
 * isMediaQuery.desktop();
 * isMediaQuery.desktopWide();
 * isMediaQuery.tabletOnly();
 * isMediaQuery.desktopOnly();
 * isMediaQuery.portrait();
 * isMediaQuery.landscape();
 */
export const isMediaQuery = {
  ...isBreakpoint,
  tabletOnly: (): boolean => isBreakpoint.tablet() && !isBreakpoint.desktop(),
  desktopOnly: (): boolean => isBreakpoint.desktop() && !isBreakpoint.desktopWide(),
  portrait: isPortrait,
  landscape: (): boolean => !isPortrait()
};

/**
 * Return condition if mobile layout
 */
export const isMobileLayout = (): boolean => isMediaQuery.mobile();

const focusableElements = [
  'input:not([disabled]):not([type=hidden])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'a[href]',
  'area[href]',
  'summary',
  'iframe',
  'object',
  'embed',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]',
  '[tabindex]:not([disabled])'
];

const TABBABLE_ELEMENT_SELECTOR =
  focusableElements.join(':not([tabindex="-1"]),') + ':not([tabindex="-1"])';

/**
 * All items that we can focus on using keyboard Tab
 */
export const getFocusableElements = (container: HTMLElement): HTMLElement[] => {
  return [...Array.from(container.querySelectorAll(TABBABLE_ELEMENT_SELECTOR))] as HTMLElement[];
};
