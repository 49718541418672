import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import HeroSection from '../../molecules/hero-section/HeroSection';
import ResponsiveImageWithRatio from '../../atoms/responsive-image-with-ratio/ResponsiveImageWithRatio';
import { motion, AnimatePresence, MotionProps } from 'framer-motion';
import Markdown from '../../atoms/markdown/Markdown';
import MarkingSystem from '../../atoms/marking-system/MarkingSystem';
import EnhancedMediaQueries from '../../../tokens/MediaQueries';
import SectionMissionStatement from '../section-mission-statement/SectionMissionStatement';
import { MediaItem, SimpleText, Theme } from '../../../types/models/cms';
import { setAppVisibility } from '../../../helpers/dom';

export type SectionHeroProps = {
  image: MediaItem;
  mission?: SimpleText;
  vision?: SimpleText;
  values?: SimpleText;
  theme: Theme;
  showMissionStatement?: boolean;
};

const SectionHero: FC<SectionHeroProps> = ({
  image,
  theme,
  mission,
  vision,
  values,
  showMissionStatement = true
}: SectionHeroProps) => {
  useEffect(() => {
    setAppVisibility(true);
    return () => {
      setAppVisibility();
    };
  }, []);
  return (
    <>
      <StyledHeroSection
        background={
          <ResponsiveImageWithRatio {...image} ratioSmall={453 / 375} ratioLarge={480 / 1400} />
        }
      >
        <AnimatePresence>
          <motion.div {...heroTextMotionProps}>
            <MarkingSystem
              theme={theme}
              title={image.title}
              subtitle={
                <motion.div {...heroTagLineMotionProps}>
                  <Markdown text={image.body} />
                </motion.div>
              }
            />
          </motion.div>
        </AnimatePresence>
      </StyledHeroSection>
      {showMissionStatement && <SectionMissionStatement {...{ mission, vision, values }} />}
    </>
  );
};

const heroTextMotionProps: MotionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } }
};
const heroTagLineMotionProps: MotionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.5 } }
};

export default SectionHero;

const StyledHeroSection = styled(HeroSection)`
  > div > div {
    width: 100%;
  }
  ${EnhancedMediaQueries.mobileOnly} {
    padding: 0;
  }
`;
