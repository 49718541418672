import { ButtonSizeThemeData, ButtonThemeData } from '../button/Button.theme';
import Colors, { withOpacity } from '../../../tokens/Colors';
import FontSizes from '../../../tokens/FontSizes';
import LetterSpacings from '../../../tokens/LetterSpacings';
import Opacities from '../../../tokens/Opacities';
import Spacings from '../../../tokens/Spacings';
import { ComponentThemeData } from '../../../types';
import LineHeights from '../../../tokens/LineHeights';

export interface TextButtonThemesData {
  textButton: ComponentThemeData<TextButtonThemeData>;
}

export interface TextButtonThemeData extends Omit<ButtonThemeData, 'sizes'> {
  sizes: {
    medium: ButtonSizeThemeData;
    large: ButtonSizeThemeData;
  };
}

declare module '../../../themes/Theme' {
  interface Theme {
    textButton: TextButtonThemeData;
  }
}

const TextButtonTheme: TextButtonThemesData = {
  textButton: {
    light: {
      color: Colors.graphiteBlack,
      states: {
        enabled: {
          color: Colors.graphiteBlack
        },
        disabled: {
          color: Colors.graphiteBlack,
          contentOpacity: Opacities.low
        },
        hover: {
          color: withOpacity(Colors.graphiteBlack, Opacities.medium)
        },
        active: {
          color: Colors.safetyOrange
        }
      },
      sizes: {
        medium: {
          height: {
            // content height needs to be same as font-size even if trailing/leading is higher
            mobile: FontSizes.body
          },
          padding: {
            mobile: `0px`
          },
          spacing: Spacings.xSmall,
          fontSize: {
            mobile: FontSizes.body
          },
          lineHeight: {
            mobile: LineHeights.small
          },
          letterSpacing: {
            mobile: LetterSpacings.small
          }
        },
        large: {
          height: {
            // content height needs to be same as font-size even if trailing/leading is higher
            mobile: FontSizes.large
          },
          padding: {
            mobile: `0px`
          },
          spacing: Spacings.small,
          fontSize: {
            mobile: FontSizes.large
          },
          lineHeight: {
            mobile: LineHeights.large
          },
          letterSpacing: {
            mobile: LetterSpacings.large
          }
        }
      }
    },
    dark: {
      states: {
        enabled: {
          color: Colors.white
        },
        disabled: {
          color: Colors.white,
          contentOpacity: Opacities.low
        },
        hover: {
          color: withOpacity(Colors.white, Opacities.medium)
        },
        active: {
          color: Colors.safetyOrange
        }
      }
    }
  }
};

export default TextButtonTheme;
