import { ButtonSizeThemeData, ButtonStateStyles } from '../button/Button.theme';
import { TextThemeBaseData } from '../text/Text.theme';
import Colors, { Color, withOpacity } from '../../../tokens/Colors';
import FontSizes, { createCustomFontSize } from '../../../tokens/FontSizes';
import FontStyles from '../../../tokens/FontStyle';
import FontWeights from '../../../tokens/FontWeights';
import Fonts from '../../../tokens/Fonts';
import Opacities from '../../../tokens/Opacities';
import Spacings, { createCustomSpacing } from '../../../tokens/Spacings';
import { ComponentThemeData } from '../../../types';
import LineHeights from '../../../tokens/LineHeights';

export interface IconButtonSizeThemeData extends Omit<ButtonSizeThemeData, 'height' | 'width'> {
  width: Spacings | string;
  height: Spacings | string;
}

export interface IconButtonThemeData {
  [key: string]: IconButtonVariantThemeData;

  black: IconButtonVariantThemeData;
  white: IconButtonVariantThemeData;
}

export interface IconButtonVariantThemeData extends TextThemeBaseData {
  sizes: {
    medium: IconButtonSizeThemeData;
    large: IconButtonSizeThemeData;
  };
  backgroundColor: Color;
  states: ButtonStateStyles;
}

export interface IconButtonThemesData {
  iconButton: ComponentThemeData<IconButtonThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    iconButton: IconButtonThemeData;
  }
}

const backwardsCompatibleSize = {
  medium: {
    width: Spacings.large,
    height: Spacings.large,
    padding: {
      mobile: Spacings.xxSmall
    },
    spacing: Spacings.xxSmall,
    fontSize: {
      mobile: FontSizes.body
    },
    lineHeight: {
      mobile: LineHeights.small
    }
  },
  large: {
    width: createCustomSpacing(2.5),
    height: createCustomSpacing(2.5),
    padding: {
      mobile: Spacings.xSmall
    },
    spacing: Spacings.xSmall,
    fontSize: {
      mobile: createCustomFontSize(1.5)
    },
    lineHeight: {
      mobile: LineHeights.small
    }
  }
};

const size = {
  medium: {
    width: Spacings.xLarge,
    height: Spacings.xLarge,
    padding: { mobile: Spacings.none },
    // this is needed when a badge is used with an IconButton
    spacing: Spacings.xxSmall,
    // to ensure nothing in the parent nodes are conflicting the presentation of the icons inside this button
    fontSize: {
      mobile: FontSizes.body
    },
    lineHeight: {
      mobile: LineHeights.small
    }
  },
  large: {
    width: Spacings.xxLarge,
    height: Spacings.xxLarge,
    padding: { mobile: Spacings.none },
    // this is needed when a badge is used with an IconButton
    spacing: Spacings.xSmall,
    // to ensure nothing in the parent nodes are conflicting the presentation of the icons inside this button
    fontSize: {
      mobile: FontSizes.body
    },
    lineHeight: {
      mobile: LineHeights.small
    }
  }
};

const fontProps = {
  fontWeight: FontWeights.regular,
  fontFamily: Fonts.fontStyle,
  fontStyle: FontStyles.normal
};

const IconButtonTheme: IconButtonThemesData = {
  iconButton: {
    light: {
      transparentWithoutPadding: {
        ...fontProps,
        color: Colors.graphiteBlack,
        backgroundColor: Colors.transparent,
        states: {
          enabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.low)
          },
          hover: {
            color: Colors.graphiteBlack
          },
          active: {
            color: Colors.safetyOrange
          }
        },
        sizes: backwardsCompatibleSize
      },
      transparent: {
        ...fontProps,
        color: Colors.graphiteBlack,
        backgroundColor: Colors.transparent,
        states: {
          enabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.low)
          },
          hover: {
            color: Colors.graphiteBlack
          },
          active: {
            color: Colors.safetyOrange
          }
        },
        sizes: size
      },
      white: {
        ...fontProps,
        color: Colors.graphiteBlack,
        backgroundColor: Colors.white,
        states: {
          enabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.low)
          },
          hover: {
            color: Colors.graphiteBlack
          },
          active: {
            color: Colors.safetyOrange
          }
        },
        sizes: size
      },
      black: {
        ...fontProps,
        color: Colors.graphiteBlack,
        backgroundColor: Colors.graphiteBlack,
        states: {
          enabled: {
            color: withOpacity(Colors.white, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.white, Opacities.low)
          },
          hover: {
            color: Colors.white
          },
          active: {
            color: Colors.safetyOrange
          }
        },
        sizes: size
      },
      followTheme: {
        ...fontProps,
        color: Colors.graphiteBlack,
        backgroundColor: Colors.white,
        states: {
          enabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.graphiteBlack, Opacities.low)
          },
          hover: {
            color: Colors.graphiteBlack
          },
          active: {
            color: Colors.safetyOrange
          }
        },
        sizes: size
      }
    },
    dark: {
      transparentWithoutPadding: {
        sizes: backwardsCompatibleSize,
        color: Colors.white,
        backgroundColor: Colors.transparent,
        states: {
          enabled: {
            color: withOpacity(Colors.white, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.white, Opacities.low)
          },
          hover: {
            color: Colors.white
          },
          active: {
            color: Colors.safetyOrange
          }
        }
      },
      transparent: {
        ...fontProps,
        color: Colors.white,
        backgroundColor: Colors.transparent,
        states: {
          enabled: {
            color: withOpacity(Colors.white, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.white, Opacities.low)
          },
          hover: {
            color: Colors.white
          },
          active: {
            color: Colors.safetyOrange
          }
        },
        sizes: size
      },
      followTheme: {
        ...fontProps,
        color: Colors.graphiteBlack,
        backgroundColor: Colors.graphiteBlack,
        states: {
          enabled: {
            color: withOpacity(Colors.white, Opacities.medium)
          },
          disabled: {
            color: withOpacity(Colors.white, Opacities.low)
          },
          hover: {
            color: Colors.white
          },
          active: {
            color: Colors.safetyOrange
          }
        },
        sizes: size
      }
    }
  }
};

export default IconButtonTheme;
