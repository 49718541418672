import React, { FC } from 'react';
import Colors from '../../../tokens/Colors';
import styled from '@emotion/styled';

import Spinner, { SpinnerSizes } from '../spinner/Spinner';

/**
 * A component to render spinner while loading a page
 */
const PageLoadingSpinner: FC = () => {
  return (
    <PageLoadingSpinnerRoot size={SpinnerSizes.medium} iconLabel="loading">
      <style>{`
        :root {
          --app-visibility: 0;
        }
        html {
          overflow: hidden;
        }
      `}</style>
    </PageLoadingSpinnerRoot>
  );
};

export default PageLoadingSpinner;

export const PageLoadingSpinnerRoot = styled(Spinner)`
  align-items: center;
  color: ${Colors.gieplGreen};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  circle {
    stroke: currentColor !important;
  }
`;
