/**
 * @internal
 */
import React, { createContext, useContext, useState } from 'react';
import useIsomorphicLayoutEffect from '../hooks/useIsomorphicLayoutEffect';

/* eslint-disable */

export interface SSRContextProps {
  isSSR: boolean;
}

export const SSRContext = createContext<SSRContextProps>({
  isSSR: false
});

export const useSSR = () => useContext(SSRContext);

const SSRProvider: React.FC = ({ children }) => {
  const [isSSR, setIsSSR] = useState<boolean>(true);

  useIsomorphicLayoutEffect(() => {
    setIsSSR(false);
  }, []);

  return <SSRContext.Provider value={{ isSSR }}>{children}</SSRContext.Provider>;
};

export default SSRProvider;

export const useIsSSR = () => {
  return useContext(SSRContext).isSSR;
};
