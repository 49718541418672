export default `
query GetPageContent($pathname: String!) {
    page (filter: { pathname: { eq: $pathname } }){
        pageTitle
        pathname
        theme
        title
        sections {
          name
          typeId
          theme
          label {
            text
          }
          contents {
            ... on SimpleTextRecord {
              text
              typeId
              entryTitle
            }
            ... on MediaItemRecord {
              id
              typeId
              imageLarge {
                responsiveImage {
                  webpSrcSet
                  srcSet
                  src
                  title
                }
              }
              imageSmall {
                responsiveImage {
                  srcSet
                  src
                  webpSrcSet
                  title
                }
              }
              body
              title
            }
            ... on ProductCardListRecord {
              typeId
              entryTitle
              cards {
                typeId
                title
                cardImage {
                  imageLarge {
                    responsiveImage {
                      webpSrcSet
                      srcSet
                      src
                    }
                  }
                  imageSmall {
                    responsiveImage {
                      webpSrcSet
                      srcSet
                      src
                    }
                  }
                }
                benefits
                description
                features
                specifications {
                  entryTitle
                  items {
                    entryTitle
                    text
                 }
                }
              }
            }
            ... on GeolocationItemRecord {
              typeId
              entryTitle
              address
              location {
                latitude
                longitude
              }
            }
            ... on LinkRecord {
              id
              typeId
              text
              url
              subText
            }
          }
        }
      }
  }
`;
