import styled from '@emotion/styled';
import React, { FC, useRef } from 'react';

import RatioBox, { RatioBoxProps } from '../ratio-box/RatioBox';
import ResponsiveImage, { ResponsiveImageProps } from '../responsive-image/ResponsiveImage';
import useInViewOnce from '../../../hooks/useInviewOnce';

interface ResponsiveImageWithRatioProps extends ResponsiveImageProps, RatioBoxProps {}

const ResponsiveImageWithRatio: FC<ResponsiveImageWithRatioProps> = ({
  ratio,
  ratioSmall,
  ratioLarge,
  isFullbleed = true,
  objectFit = 'cover',
  ...responsiveImageProps
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const { inView } = useInViewOnce({ targetRef });
  return (
    <Root ref={targetRef}>
      {inView && <ResponsiveImage {...{ isFullbleed, objectFit }} {...responsiveImageProps} />}
      <RatioBox {...{ ratio, ratioSmall, ratioLarge }} />
    </Root>
  );
};

export default ResponsiveImageWithRatio;

const Root = styled.div`
  position: relative;
`;
