import {
  PageResponse,
  Page,
  MediaItem,
  PageSection,
  SimpleText,
  LinkItem
} from '../../types/models/cms';
import { getPageContent, getPageSection } from '.';
import { AboutUsViewSections } from '../../types/models/cms/aboutUsView';

/**
 * Return page content
 */
export const getAboutUsPageContent = ({
  page: { sections }
}: PageResponse): Page<AboutUsViewSections> => {
  return {
    sections: getAboutUsViewSections(sections)
  };
};

const getAboutUsViewSections = (sections: PageSection[]): AboutUsViewSections => {
  const hero = getPageSection('hero', sections);
  const companyProfile = getPageSection('about', sections);
  const qualityCommitment = getPageSection('quality commitment', sections);
  const whyChooseUs = getPageSection('why choose us', sections);
  return {
    hero: {
      theme: hero.theme,
      name: hero.name,
      label: hero.label,
      image: getPageContent<MediaItem>('geiplAboutUsHeroImage', hero)
    },
    companyProfile: {
      theme: companyProfile.theme,
      name: companyProfile.name,
      label: companyProfile.label,
      description: getPageContent<SimpleText>('gieplAboutCompanyDescription', companyProfile),
      image: getPageContent<MediaItem>('gieplCompanyProfileSectionImage', companyProfile),
      cta: getPageContent<LinkItem>('gieplAboutUsProfileLink', companyProfile)
    },
    qualityCommitment: {
      theme: qualityCommitment.theme,
      name: qualityCommitment.name,
      label: qualityCommitment.label,
      description: getPageContent<SimpleText>(
        'gieplAboutUsQualityCommitmentDescription',
        qualityCommitment
      ),
      image: getPageContent<MediaItem>('gieplAboutUsQualityControlImage', qualityCommitment)
    },
    whyChooseUs: {
      theme: whyChooseUs.theme,
      name: whyChooseUs.name,
      label: whyChooseUs.label,
      description: getPageContent<SimpleText>('gieplWhyChooseUsDescription', whyChooseUs),
      image: getPageContent<MediaItem>('geiplWhyChooseUsImage', whyChooseUs)
    }
  };
};
