import React, { FC } from 'react';
import styled from '@emotion/styled';
import Colors from '../../../tokens/Colors';
import Markdown from '../../atoms/markdown/Markdown';
import Paragraph from '../../atoms/paragraph/Paragraph';
import Section from '../../atoms/section/Section';
import useDictionary from '../../../hooks/useDictionary';
import MediaQueries from '../../../tokens/MediaQueries';
import { navBarEntities, socialEntities } from '../../../mocks/header';
import Grid from '../../atoms/grid/Grid';
import GridColumn from '../../atoms/grid/GridColumn';
import { Link } from 'react-router-dom';
import Spacings from '../../../tokens/Spacings';
import Icon from '../../atoms/icon/Icon';
import LinkComponent from '../../atoms/link/Link';
import { isTouchDevice } from '../../../helpers/dom';
import Accordion from '../../atoms/accordion/Accordion';
import AccordionSection from '../../atoms/accordion/AccordionSection';
import Spacer from '../../atoms/spacer/Spacer';
const Footer: FC = () => {
  const dictionary = useDictionary();
  const year = new Date().getFullYear();
  return (
    <FooterContainer>
      <Section>
        {!isTouchDevice && (
          <Grid nested>
            <GridColumn span={{ mobile: 4, desktop: 4, tablet: 2 }}>
              <CopyRightTextContainer>
                <CopyrightText>
                  <Markdown text={dictionary.get('GIEPL')} />
                  <sup>&copy;</sup>
                  <span>{year}.</span>
                  <Markdown text={dictionary.get('All rights reserved')} />
                </CopyrightText>
              </CopyRightTextContainer>
            </GridColumn>
            <GridColumn
              span={{ mobile: 4, desktop: 4, tablet: 4 }}
              offset={{ desktop: 4, tablet: 2 }}
            >
              <Grid>
                <GridColumn span={{ mobile: 4, desktop: 6, tablet: 4 }}>
                  {navBarEntities.map((eachNavigationItem, index) => (
                    <StyledLink to={eachNavigationItem?.route} key={index}>
                      {eachNavigationItem?.name}
                    </StyledLink>
                  ))}
                </GridColumn>
                <GridColumn span={{ mobile: 4, desktop: 6, tablet: 4 }}>
                  {socialEntities.map((eachEntity, index) => (
                    <SocialContainer key={index}>
                      <StyledLinkComponent href={eachEntity?.link}>
                        <Icon icon={eachEntity?.icon} color={Colors.gieplGreen} />
                        <Markdown text={eachEntity?.text} />
                      </StyledLinkComponent>
                    </SocialContainer>
                  ))}
                </GridColumn>
              </Grid>
            </GridColumn>
          </Grid>
        )}
        {isTouchDevice && (
          <>
            <Accordion border disabled={false}>
              <StyledAccordionSection title={dictionary.get('Explore')}>
                {navBarEntities.map((eachNavigationItem, index) => (
                  <StyledLink to={eachNavigationItem?.route} key={index}>
                    {eachNavigationItem?.name}
                  </StyledLink>
                ))}
              </StyledAccordionSection>
              <StyledAccordionSection title={dictionary.get('Social')}>
                {socialEntities.map((eachEntity, index) => (
                  <SocialContainer key={index}>
                    <StyledLinkComponent href={eachEntity?.link}>
                      <Icon icon={eachEntity?.icon} color={Colors.gieplGreen} />
                      <Markdown text={eachEntity?.text} />
                    </StyledLinkComponent>
                  </SocialContainer>
                ))}
              </StyledAccordionSection>
            </Accordion>
            <Spacer spacing={Spacings.large} />
            <CopyrightText>
              <Markdown text={dictionary.get('GIEPL')} />
              <sup>&copy;</sup>
              <span>{year}.</span>
              <Markdown text={dictionary.get('All rights reserved')} />
            </CopyrightText>
            <Spacer spacing={Spacings.large} />
          </>
        )}
      </Section>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  color: ${Colors.gieplGreen};
  padding: ${Spacings.large} 0;
  display: flex;
  align-items: center;
  box-shadow: 0 0 31px 0 ${Colors.boxShadow};
  width: 100%;
  z-index: 1;
  background: ${Colors.white};
  ${MediaQueries.mobileOnly} {
    text-align: center;
  }
  postion: relative;
  opacity: var(--app-visibility, 0);
  transition: opacity 0.3s;
`;
const StyledLink = styled(Link)`
  text-decoration: underline;
  display: block;
  padding: ${Spacings.medium} 0;
  font-weight: normal;
`;

const StyledLinkComponent = styled(LinkComponent)`
  display: flex;
  align-items: center;
  color: ${Colors.gieplGreen};
  font-weight: normal;
  span {
    margin: 0 0 0 ${Spacings.small};
  }
  &:hover {
    color: ${Colors.gieplGreen};
  }
  ${MediaQueries.mobileOnly} {
    justify-content: flex-start;
  }
`;

const CopyRightTextContainer = styled.div`
  position: absolute;
  bottom: ${Spacings.large};
`;
const CopyrightText = styled(Paragraph)`
  span {
    color: ${Colors.gieplGreen};
    padding: 0 2px;
  }
  sup {
    color: ${Colors.gieplGreen};
    font-size: 0.75em;
    position: relative;
    top: -7px;
    left: -5px;
  }
`;

const SocialContainer = styled.div`
  padding: ${Spacings.medium} 0;
`;

const StyledAccordionSection = styled(AccordionSection)`
  div {
    color: ${Colors.gieplGreen};
    font-weight: bold;
    text-align: left;
  }
`;

export default Footer;
