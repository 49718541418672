import React, { FC } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from '@emotion/styled';
import useDictionary from '../../../hooks/useDictionary';
import appIcon from '../../../assets/images/giepl.png';
import Colors from '../../../tokens/Colors';
import Section from '../../atoms/section/Section';
import MobileHamburgerMenu from '../mobile-hamburder-menu/MobileHamburgerMenu';
import { navBarEntities } from '../../../mocks/header';
import { NavLink, useHistory } from 'react-router-dom';
import Markdown from '../../atoms/markdown/Markdown';
import { isTouchDevice } from '../../../helpers/dom';
import Title from '../../atoms/title/Title';

const transitionAnimationVariant = {
  initial: { width: 0 },
  animate: {
    width: '100%',
    transition: { duration: 0.6 }
  },
  exit: { width: 0, transition: { duration: 0.6 } }
};
export interface HeaderProps {
  noHeaderMenu?: boolean;
}
const Header: FC<HeaderProps> = ({ noHeaderMenu = false }: HeaderProps) => {
  const dictionary = useDictionary();
  const { location } = useHistory();

  return (
    <HeaderComponent>
      <Section>
        <HeaderSection>
          <AppLogo src={appIcon} alt="logo" />
          {!noHeaderMenu && (
            <AnimatePresence>
              {!isTouchDevice ? (
                <MenuSection>
                  {navBarEntities.map(({ name, route }, index) => (
                    <NavLink to={route} key={index}>
                      <Markdown text={name} />
                      {route === location.pathname && (
                        <Border
                          variants={transitionAnimationVariant}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                        />
                      )}
                    </NavLink>
                  ))}
                </MenuSection>
              ) : (
                <MobileHamburgerMenu />
              )}
            </AnimatePresence>
          )}

          {!isTouchDevice && (
            <AppTitle as="h1">
              <Markdown text={dictionary.get('GIEPL')} />
            </AppTitle>
          )}
        </HeaderSection>
      </Section>
    </HeaderComponent>
  );
};

const HeaderComponent = styled.header`
  height: 60px;
  padding: 0.5em 0;
  box-shadow: 0 0 31px 0 ${Colors.boxShadow};
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background: ${Colors.white};
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const AppLogo = styled.img`
  display: inline-block;
  width: 40px;
  height: 40px;
`;

const AppTitle = styled(Title)`
  font-weight: bold;
  font-size: 1.75em;
  color: ${Colors.gieplGreen};
`;

const MenuSection = styled.nav`
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  a {
    width: max-content;
    max-width: 90px;
    &.active {
      font-weight: bold;
    }
    &:hover {
      transform: scale(1.05);
      font-weight: bold;
    }
  }
`;

const Border = styled(motion.div)`
  height: 2px;
  background: ${Colors.gieplGreen};
  margin: 0.25rem 0 0;
`;

export default Header;
