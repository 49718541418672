import { PageResponse, Page, MediaItem, PageSection } from '../../types/models/cms';
import { getPageContent, getPageSection } from '.';
import { ProductsViewSections } from '../../types/models/cms/productsView';

/**
 * Return page content
 */
export const ProductsViewPageContent = ({
  page: { sections }
}: PageResponse): Page<ProductsViewSections> => {
  return {
    sections: getProductsViewSections(sections)
  };
};

const getProductsViewSections = (sections: PageSection[]): ProductsViewSections => {
  const hero = getPageSection('hero', sections);
  return {
    hero: {
      theme: hero.theme,
      name: hero.name,
      label: hero.label,
      image: getPageContent<MediaItem>('gieplProductsHeroImage', hero)
    }
  };
};
