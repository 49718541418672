import { useContext } from 'react';

import ProjectContext from '../contexts/project/ProjectContext';

const useDictionary = (): { get: (key: string, alt?: string) => string } => {
  const entries = useContext(ProjectContext).dictionary;
  const globalEntries = useContext(ProjectContext).globalDictionary;
  return {
    get: (key: string, alt?: string) =>
      entries.find(({ entryTitle }) => entryTitle === key)?.text ||
      globalEntries?.find(({ key: globalKey }) => globalKey === key)?.value ||
      alt ||
      key
  };
};

export default useDictionary;
