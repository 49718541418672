import { ComponentThemeData } from '../../../types';
import Colors, { Color, withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';
import { TextSizeThemeData } from '..//text/Text.theme';
import FontSizes from '../../../tokens/FontSizes';
import LetterSpacings from '../../../tokens/LetterSpacings';
import LineHeights from '../../../tokens/LineHeights';

export interface LinkThemeData {
  variants: {
    default: LinkVariantThemeData;
    list: LinkVariantThemeData;
    [key: string]: LinkVariantThemeData;
  };
}

export interface LinkVariantThemeData {
  sizes?: {
    medium: TextSizeThemeData;
    large: TextSizeThemeData;
  };
  states: LinkStateStyles;
}
export interface LinkStateStyles {
  enabled: {
    textColor: Color;
  };
  disabled: {
    textColor: Color;
  };
  hover: {
    textColor: Color;
  };
  active: {
    textColor: Color;
  };
}

export interface LinkThemesData {
  link: ComponentThemeData<LinkThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    link: LinkThemeData;
  }
}

const LinkTheme: LinkThemesData = {
  link: {
    light: {
      variants: {
        default: {
          sizes: {
            medium: {
              fontSize: {
                mobile: FontSizes.body
              },
              letterSpacing: {
                mobile: LetterSpacings.small
              },
              lineHeight: {
                mobile: LineHeights.small
              }
            },
            large: {
              fontSize: {
                mobile: FontSizes.large
              },
              letterSpacing: {
                mobile: LetterSpacings.large
              },
              lineHeight: {
                mobile: LineHeights.large
              }
            }
          },
          states: {
            enabled: {
              textColor: withOpacity(Colors.graphiteBlack, Opacities.medium)
            },
            disabled: {
              textColor: withOpacity(Colors.graphiteBlack, Opacities.low)
            },
            hover: {
              textColor: Colors.graphiteBlack
            },
            active: {
              textColor: Colors.safetyOrange
            }
          }
        },
        list: {
          sizes: {
            medium: {
              fontSize: {
                mobile: FontSizes.body
              },
              letterSpacing: {
                mobile: LetterSpacings.small
              },
              lineHeight: {
                mobile: LineHeights.small
              }
            },
            large: {
              fontSize: {
                mobile: FontSizes.large
              },
              letterSpacing: {
                mobile: LetterSpacings.large
              },
              lineHeight: {
                mobile: LineHeights.large
              }
            }
          },
          states: {
            enabled: {
              textColor: Colors.graphiteBlack
            },
            disabled: {
              textColor: withOpacity(Colors.graphiteBlack, Opacities.low)
            },
            hover: {
              textColor: withOpacity(Colors.graphiteBlack, Opacities.medium)
            },
            active: {
              textColor: Colors.safetyOrange
            }
          }
        }
      }
    },
    dark: {
      variants: {
        default: {
          states: {
            enabled: {
              textColor: withOpacity(Colors.white, Opacities.medium)
            },
            disabled: {
              textColor: withOpacity(Colors.white, Opacities.low)
            },
            hover: {
              textColor: Colors.white
            },
            active: {
              textColor: Colors.safetyOrange
            }
          }
        },
        list: {
          states: {
            enabled: {
              textColor: Colors.white
            },
            disabled: {
              textColor: withOpacity(Colors.white, Opacities.low)
            },
            hover: {
              textColor: withOpacity(Colors.white, Opacities.medium)
            },
            active: {
              textColor: Colors.safetyOrange
            }
          }
        }
      }
    }
  }
};

export default LinkTheme;
