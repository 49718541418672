import React, { forwardRef } from 'react';

const MapMarker = forwardRef<SVGSVGElement, React.SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="16"
    viewBox="0 0 13 16"
    {...props}
    ref={ref}
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-892 -695) translate(140 522) translate(555 80) translate(183 81) translate(0 -1.429) translate(14.286 13.571)">
                  <path
                    fill="#3B99FC"
                    d="M5.571.429C2.757.429.457 2.714.443 5.514c0 1.057.328 2.086.943 2.957.014.015.014.029.028.029l.015.014 3.928 5.229c.057.071.129.114.214.114.086 0 .158-.043.215-.114l3.943-5.257c.014 0 .028-.015.028-.029.614-.871.943-1.886.943-2.943C10.686 2.714 8.4.43 5.571.43zm0 7.114c-1.257 0-2.271-.986-2.271-2.186 0-1.214 1.014-2.186 2.271-2.186 1.258 0 2.272.972 2.272 2.186 0 1.2-1.014 2.186-2.272 2.186z"
                  />
                  <path
                    fill="#3B99FC"
                    d="M5.571 14.286c-.2 0-.4-.1-.542-.257l-.015-.015-3.9-5.214c-.028-.029-.043-.057-.057-.086C.386 7.757.043 6.657.043 5.514.043 2.471 2.529 0 5.57 0c3.058 0 5.543 2.486 5.543 5.529 0 1.128-.343 2.214-.985 3.157-.029.043-.058.085-.1.128L6.129 14l-.015.014c-.128.172-.328.272-.543.272zM1.757 8.243l.014.014 3.8 5.072 3.815-5.086c.014-.014.028-.029.028-.043.543-.786.843-1.714.843-2.671 0-2.572-2.1-4.672-4.686-4.672C3 .857.886 2.943.871 5.53c0 .957.3 1.885.858 2.685.028.015.028.029.028.029z"
                  />
                  <ellipse cx="5.571" cy="5.357" fill="#FFF" rx="2.271" ry="2.186" />
                  <path
                    fill="#FFF"
                    d="M5.571 7.971c-1.485 0-2.7-1.171-2.7-2.614 0-1.443 1.215-2.628 2.7-2.628 1.486 0 2.7 1.171 2.7 2.614 0 1.443-1.214 2.628-2.7 2.628zm0-4.385c-1.014 0-1.842.785-1.842 1.757 0 .971.828 1.771 1.842 1.771 1.015 0 1.843-.785 1.843-1.757 0-.971-.828-1.771-1.843-1.771z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
));
MapMarker.displayName = 'MapMarker';
export default MapMarker;
