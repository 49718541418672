import { ThemeContext } from '@emotion/react';
import { useContext } from 'react';

import { Theme } from '../themes/Theme';
import Themes from '../themes/Themes';

/**
 *
 * Get the current theme context. Please try to avoid and get the theme from the styled component.
 *
 * ```js
 * import { useTheme } from '@polestar/component-warehouse-react';
 *
 * const MyComponent = () => {
 *    const currentTheme = useTheme();
 *
 *    return ...
 * }
 *  ```
 *
 * @storybook Hooks/useTheme
 *
 */
/* eslint-disable */
const useTheme = () => {
  const theme = useContext(ThemeContext) as Theme;
  return theme && Object.keys(theme).length ? theme : Themes.default;
};

export default useTheme;

/**
 * Get the current theme name. Only needed when using a custom component to identify in `styled` what (for example) background color is needed.
 *
 * ```js
 * import { useThemeName } from '@polestar/component-warehouse-react';
 *
 * const MyComponent = () => {
 *    const currentThemeName = useThemeName();
 *
 *    return ...
 *}
 * ```
 *
 * @storybook Hooks/useTheme
 */
export const useThemeName = () => {
  return (useContext(ThemeContext) as Theme)?._name;
};
