import { useContext } from 'react';

import ProductsContext from '../contexts/product/ProductContext';
import { ProductCardList } from '../types/models/cms';

const useProductsList = (): ProductCardList => {
  return useContext(ProductsContext).products;
};

export default useProductsList;
