import { useEffect, useLayoutEffect } from 'react';
import { isSSR } from '../helpers/dom';

/**
 * Because useLayoutEffect does not work during SSR, and throws warnings when you try to, we can
 * fall back to a useEffect during SSR *if and only if* there effect does not cause any visual
 * difference.
 *
 * If the effect does cause a visual difference, users will experience a 'jump' in the page layout
 * after the client is done rendering. In these situations, it is better to not render that
 * component at all during SSR.
 *
 * Try avoiding this hook. Usually you need this hook when calculating dimension of dom, please have a look at https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node for a better solution
 *
 * ```js
 * import { useIsomorphicLayoutEffect } from '@polestar/component-warehouse-react';
 *
 * const MyComponent = () => {
 *    // Triggers at the same time as useEffect on SSR
 *    // Triggers at the same time as useLayoutEffect in the browser
 *    useIsomorphicLayoutEffect(() => {
 *      console.log('triggered')
 *    }, []);
 *
 *    return ...
 * }
 * ```
 *
 * @storybook Hooks/useIsomorphicLayoutEffect
 *
 * @see https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 * @see https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 */
const useIsomorphicLayoutEffect = isSSR ? useEffect : useLayoutEffect;

export default useIsomorphicLayoutEffect;
