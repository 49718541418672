import { css } from '@emotion/css';
import Title, { TitleProps } from '../title/Title';
import { responsiveStyles } from '../../../helpers/responsiveStyle';
import styled from '@emotion/styled';
import React, { forwardRef, ReactNode } from 'react';

import { getColor } from '../../../helpers/cms';
import { Theme } from '../../../types/models/cms';
import FontSizes from '../../../tokens/FontSizes';
import LineHeights, { createCustomLineHeight } from '../../../tokens/LineHeights';
import LetterSpacings from '../../../tokens/LetterSpacings';

export interface MarkingSystemProps {
  /**
   * Theme
   */
  theme?: Theme;
  /**
   * ReactNode to use as a title
   */
  title: ReactNode;
  /**
   * ReactNode to use as a subtitle
   */
  subtitle: ReactNode;
  /**
   * ReactNode to use as a dash line
   */
  dash?: ReactNode;
}

const MarkingSystem = forwardRef<HTMLDivElement, MarkingSystemProps>(
  ({ theme, title, subtitle, ...props }: MarkingSystemProps, ref) => {
    const color = getColor(theme);
    return (
      <div {...props} ref={ref}>
        <StyledTitle color={color}>{title}</StyledTitle>
        <StyledTitle as="h2" color={color}>
          {subtitle}
        </StyledTitle>
      </div>
    );
  }
);
MarkingSystem.displayName = 'MarkingSystem';

export default MarkingSystem;

const StyledTitle = styled(Title)<TitleProps>(
  () =>
    css`
      ${responsiveStyles({
        fontSize: {
          mobile: FontSizes.large
        },
        lineHeight: {
          mobile: LineHeights.large
        },
        letterSpacing: {
          mobile: LetterSpacings.large
        }
      })}
    `
);
StyledTitle.displayName = 'StyledTitle';

const StyledEmDash = styled(Title)<TitleProps>(
  () =>
    css`
      ${responsiveStyles({
        fontSize: {
          mobile: FontSizes.large
        },
        lineHeight: {
          mobile: LetterSpacings.large
        },
        letterSpacing: {
          mobile: createCustomLineHeight(26 / 16)
        }
      })};
      margin-left: -1px;
      padding: 0;
    `
);
StyledEmDash.displayName = 'StyledEmDash';
