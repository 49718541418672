import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MediaQueries, { createMediaQuery } from '../../../tokens/MediaQueries';
import { Span } from '../../../tokens/Spans';
import DeviceTypes from '../../../tokens/DeviceTypes';
import Breakpoints from '../../../tokens/BreakPoints';
import { shouldNotForwardProps } from '../../../helpers/shouldForwardProps';
import { GridContext } from './Grid';

type SpanType = keyof typeof DeviceTypes;
const spanTypes = Object.keys(DeviceTypes) as SpanType[];

export interface GridColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Span based on grid and media query
   */
  span: Span;
  /**
   * Offset in columns from the left
   *
   * @type `Span` with `mobile` also optional
   */
  offset?: Omit<Span, 'mobile'> & {
    mobile?: Span['mobile'];
  };
}

const GridColumn = forwardRef<HTMLDivElement, GridColumnProps>(
  ({ offset, ...props }: GridColumnProps, ref) => {
    // It's allowed to not set mobile for offset, force it on 0 (hidden)
    if (offset && !offset?.mobile) {
      offset = {
        mobile: 0,
        ...offset
      };
    }

    return (
      <GridContext.Provider value={{ span: props.span }}>
        {offset ? <StyledColumn span={offset as Span} /> : null}
        <StyledColumn {...props} ref={ref} />
      </GridContext.Provider>
    );
  }
);

GridColumn.displayName = 'GridColumn';

export default GridColumn;

/**
 * To ensure that <GridColumn={{mobile: 'hidden', tablet: 2}}/> has no effect
 * on the display property for the tablet or above breakpoints a `max-width`
 * is needed to box the environment where the GridColumn should be hidden
 */
const MediaQueriesRanged = Object.freeze({
  mobile: createMediaQuery(Breakpoints.mobile, 'media', Breakpoints.tablet - 1),
  tablet: createMediaQuery(Breakpoints.tablet, 'media', Breakpoints.desktop - 1),
  desktop: createMediaQuery(Breakpoints.desktop, 'media', Breakpoints.desktopWide - 1),
  desktopWide: createMediaQuery(Breakpoints.desktopWide)
});

const StyledColumn = styled(
  'div',
  shouldNotForwardProps(true, 'span')
)<GridColumnProps>((props) => {
  const styles = [
    // This is needed for webkit, otherwise it doesn't scale properly when content is bigger
    css`
      min-width: 0;
      min-height: 0;
    `
  ];

  // Loop over devicetypes and only add css when needed
  spanTypes.forEach((device) => {
    const deviceSpan =
      device === DeviceTypes.mobile && props.span[device] === undefined ? 0 : props.span[device];

    if (deviceSpan !== undefined) {
      const display = deviceSpan === 'hidden' || deviceSpan === 0 ? 'display: none;' : '';
      const gridColumn =
        deviceSpan !== undefined && typeof deviceSpan === 'number'
          ? `grid-column: span ${deviceSpan};`
          : '';

      if (display) {
        /**
         * use MediaQueriesRanged to ensure that columns are only hidden
         * were they were defined to be hidden
         */
        styles.push(css`
          ${MediaQueriesRanged[device]} {
            ${display}
          }
        `);
      }

      if (gridColumn) {
        if (device === DeviceTypes.mobile) {
          styles.push(css`
            ${gridColumn}
          `);
        } else {
          styles.push(css`
            ${MediaQueries[device]} {
              ${gridColumn}
            }
          `);
        }
      }
    }
  });

  return styles;
});
