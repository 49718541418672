export default `
query GetPageContent($pathname: String!) {
    page (filter: { pathname: { eq: $pathname } }){
        pageTitle
        pathname
        theme
        title
        seoMetaTag {
            description
            image {
              responsiveImage {
                src
              }
            }
            title
        }
    }
  }
`;
