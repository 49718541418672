import React, { forwardRef } from 'react';

const Phone = forwardRef<SVGSVGElement, React.SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    viewBox="0 0 14 24"
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    role="img"
    ref={ref}
    {...props}
  >
    <g fill="currentColor">
      <path d="M13.75 0H.25v24h13.5zm-1.5 22.5H1.75v-21h10.5z" />
      <path d="M7.75 19.5h-1.5V21h1.5z" />
    </g>
  </svg>
));
Phone.displayName = 'Phone';
export default Phone;
