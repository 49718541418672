import React, { FC } from 'react';
import loadable from '@loadable/component';
import GET_ALL_PAGE_CONTENT from '../../../graphQL/queries/getPageContent';
import { useQuery } from 'graphql-hooks';
import PageLoadingSpinner from '../../atoms/page-loading-spinner/PageLoadingSpinner';
import { getContactUsPageContent } from '../../../helpers/cms/contactUsView';
const HeroSection = loadable(() => import('../../organisms/section-hero/SectionHero'));
const SectionContactUs = loadable(
  () => import('../../organisms/section-contact-us/SectionContactUs')
);
const ContactUsView: FC = () => {
  const { data, loading } = useQuery(GET_ALL_PAGE_CONTENT, {
    variables: {
      pathname: '/contact-us'
    }
  });
  if (loading) {
    return <PageLoadingSpinner />;
  }
  if (!loading && !!data) {
    const content = getContactUsPageContent(data);
    return (
      <main className="container">
        <HeroSection {...content.sections.hero} showMissionStatement={false} />
        <SectionContactUs {...content.sections.contactUs} />
      </main>
    );
  }
  return <></>;
};

export default ContactUsView;
