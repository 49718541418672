import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef, MouseEvent, useCallback } from 'react';
import { shouldNotForwardProps } from '../../../helpers/shouldForwardProps';
import Sizes from '../../../tokens/Sizes';
import { responsiveStyles, ResponsiveThemeDataObject } from '../../../helpers/responsiveStyle';
import { createCSSTransition, MotionDurations, MotionEasings } from '../../../tokens/Motion';
import { aria } from '../../../helpers/accessibility';
import { SharedButtonProps } from '../button/Button';
import { noHitboxNeeded } from '../../../helpers/style';
import { createCustomSpacing } from '../../../tokens/Spacings';
import FontSizes from '../../../tokens/FontSizes';
import LineHeights from '../../../tokens/LineHeights';
import LetterSpacings from '../../../tokens/LetterSpacings';
import Colors, { withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';

export enum LinkVariants {
  default = 'default',
  list = 'list'
}

export interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'disabled'>,
    Pick<SharedButtonProps, 'disabled'> {
  /**
   * Where it should link to
   */
  href: string;
  /**
   * Only in edge-cases (Doormat) the underline can be removed
   *
   * @deprecated Please do NOT use a Link with a hidden underline, this is a bad practice according to usability standards.
   */
  hideUnderline?: boolean;
  /**
   * The size of the Link component
   */
  size?: Sizes.medium | Sizes.large;
  /**
   * Provide the variant how the Link should be styled / behave
   */
  variant?: LinkVariants;
  // TODO: soon to be required (aria)
  'aria-label'?: string;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      disabled = false,
      onClick,
      hideUnderline = false,
      size = Sizes.medium,
      variant = LinkVariants.default,
      ...props
    }: LinkProps,
    ref
  ) => {
    const handleClick = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        // Prevent default behaviour for disabled links
        if (disabled) {
          event.preventDefault();
        }

        if (onClick && !disabled) {
          onClick(event);
        }
      },
      [onClick, disabled]
    );

    if (props.target && props.target === '_blank' && props.rel === undefined) {
      console.error(
        'Please provide `Link.rel = "noopener" | "noreferrer"`, because of security considerations when using `target="_blank"`. More information at https://web.dev/external-anchors-use-rel-noopener/'
      );
      return null;
    }

    return (
      <StyledLink
        {...aria({
          disabled
        })}
        {...props}
        ref={ref}
        onClick={handleClick}
        disabled={disabled}
        hideUnderline={hideUnderline}
        size={size}
        variant={variant}
      />
    );
  }
);

Link.displayName = 'Link';

export default Link;

const StyledLink = styled(
  'a',
  shouldNotForwardProps(true, 'disabled', 'hideUnderline', 'size', 'variant')
)<LinkProps>(({ disabled, hideUnderline, variant = LinkVariants.default }) => {
  const sizeStyles = {
    fontSize: {
      mobile: FontSizes.body
    },
    letterSpacing: {
      mobile: LetterSpacings.small
    },
    lineHeight: {
      mobile: LineHeights.small
    }
  };
  const stateStyles = {
    enabled: {
      textColor: withOpacity(Colors.graphiteBlack, Opacities.medium)
    },
    disabled: {
      textColor: withOpacity(Colors.graphiteBlack, Opacities.low)
    },
    hover: {
      textColor: Colors.graphiteBlack
    },
    active: {
      textColor: Colors.safetyOrange
    }
  };

  const noUnderline = hideUnderline || variant === LinkVariants.list;
  return css(
    css`
      color: ${stateStyles.enabled.textColor};
      transition: ${createCSSTransition('color', MotionEasings.default, MotionDurations.fast)},
        ${createCSSTransition('background-image', MotionEasings.default, MotionDurations.fast)};
      background-position: 0 80%;
      background-repeat: repeat-x;
      touch-action: manipulation;
      text-decoration: ${noUnderline ? 'none' : 'underline'};
      text-underline-offset: 0.125rem;

      ${responsiveStyles(sizeStyles as unknown as ResponsiveThemeDataObject)}

      &:hover {
        cursor: pointer;
        color: ${stateStyles.hover.textColor};
        text-decoration: ${noUnderline ? 'none' : 'underline'};
      }

      &:active {
        color: ${stateStyles.active.textColor};
      }
    `,
    variant === LinkVariants.list &&
      css`
        min-height: ${createCustomSpacing(40 / 16)};
        display: flex;
        align-items: center;
        word-break: break-word;
      `,
    disabled &&
      css`
        cursor: default;
        color: ${stateStyles.disabled.textColor};

        &:hover,
        &:active,
        &:focus {
          color: ${stateStyles.disabled.textColor};
        }
      `,
    noHitboxNeeded
  );
});
