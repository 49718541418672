import merge from 'deepmerge';
import TextTheme from '../components/atoms/text/Text.theme';
import ImageTheme from '../components/atoms/image/Image.theme';
import TitleTheme from '../components/atoms/title/Title.theme';
import MarkingSystemTheme from '../components/atoms/marking-system/MarkingSystem.theme';
import BackdropTheme from '../components/atoms/backdrop/Backdrop.theme';
import DialogTheme from '../components/atoms/dialog/Dialog.theme';
import IconButtonTheme from '../components/atoms/icon-button/IconButton.theme';
import ButtonTheme from '../components/atoms/button/Button.theme';
import LinkTheme from '../components/atoms/link/Link.theme';
import TextButtonTheme from '../components/atoms/text-button/TextButton.theme';
import LabelTheme from '../components/atoms/label/Label.theme';
import AccordionTheme from '../components/atoms/accordion/Accordion.theme';

// Add import above

/**
 * @internal
 */
export const componentThemes = [
  TextTheme,
  ImageTheme,
  TitleTheme,
  MarkingSystemTheme,
  DialogTheme,
  BackdropTheme,
  IconButtonTheme,
  ButtonTheme,
  LinkTheme,
  TextButtonTheme,
  LabelTheme,
  AccordionTheme
  // Add theme above
];

/**
 * This will be extended by each component using the following code:
 *
 * declare module '@polestar/component-warehouse-react/themes/Theme' {
 * interface Theme {
 *   keyName: ComponentThemeInterface;
 * }
}
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Theme {
  _name?: string;
  //
}

export enum ThemeNames {
  light = 'light',
  dark = 'dark'
}

export interface ResponsiveThemeData<T> {
  mobile: T;
  tablet?: T;
  desktop?: T;
  desktopWide?: T;
}

type DeepPartial<T> = {
  [K in keyof T]?: DeepPartial<T[K]>;
};

export function extendTheme<T = Theme>(baseTheme: T, data: DeepPartial<T>): T {
  return merge<T, DeepPartial<T>>(baseTheme, data);
}

const _themes: {
  [ThemeNames.light]: Theme;
  [ThemeNames.dark]: Theme;
} = {
  [ThemeNames.light]: { _name: ThemeNames.light } as Theme,
  [ThemeNames.dark]: { _name: ThemeNames.dark } as Theme
};

export interface ThemedStyleProperty<T> {
  [key: string]: T;
}

componentThemes.forEach((componentTheme) => {
  for (const [key, data] of Object.entries(componentTheme)) {
    if (key in _themes.light) {
      console.warn('Theme object already in theme', key);
    }

    _themes[ThemeNames.light][key as keyof Theme] = data[ThemeNames.light] || {};
    _themes[ThemeNames.dark][key as keyof Theme] = merge(
      data[ThemeNames.light] || {},
      data[ThemeNames.dark] || {}
    );
  }
});

/**
 * @internal
 */
export const themes = _themes;
