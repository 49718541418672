import { Property } from 'csstype';

export type FontWeight = Property.FontWeight;

enum FontWeights {
  light = 300,
  regular = 400,
  bold = 700
}

export default FontWeights;
