import Icons from '../tokens/icons/Icons';

export const navBarEntities = [
  {
    name: 'Home',
    route: '/home'
  },
  {
    name: 'About Us',
    route: '/about-us'
  },
  {
    name: 'Products',
    route: '/products'
  },
  {
    name: 'Contact Us',
    route: '/contact-us'
  }
];

export const socialEntities = [
  {
    icon: Icons.facebook,
    text: 'Facebook',
    link: 'https://www.facebook.com/EazymixGIEPL'
  },
  {
    icon: Icons.instagram,
    text: 'Instagram',
    link: 'https://www.instagram.com/eazymixgiepl/'
  },
  {
    icon: Icons.whatsapp,
    text: 'Whatsapp',
    link: 'https://api.whatsapp.com/send?phone=918888822844'
  }
];
