export default `
query getProductContent{
  productCardList {
        entryTitle
        cards {
          title
          cardImage {
            imageLarge {
              responsiveImage {
                srcSet
                src
                webpSrcSet
              }
            }
            imageSmall {
              responsiveImage {
                srcSet
                src
                webpSrcSet
              }
            }
          }
          benefits
          description
          features
          specifications {
            entryTitle
            items {
              entryTitle
              text
            }
          }
        }
      }
}
`;
