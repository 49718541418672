import { css } from '@emotion/react';
import Icon, { IconSizes } from '../icon/Icon';
import Label from '../label/Label';
import Spacer from '../spacer/Spacer';
import { responsiveStyles } from '../../../helpers/responsiveStyle';
import { shouldNotForwardProps } from '../../../helpers/shouldForwardProps';
import styled from '@emotion/styled';
import Icons from '../../../tokens/icons/Icons';
import {
  createCSSTransition,
  MotionDurations,
  MotionEasings,
  Transitions
} from '../../../tokens/Motion';
import Orientations from '../../../tokens/Orientations';
import Spacings from '../../../tokens/Spacings';
import Title from '../title/Title';
import React, {
  forwardRef,
  Fragment,
  ReactNode,
  RefCallback,
  useCallback,
  useRef,
  useState
} from 'react';
import { AccordionVariants } from './Accordion';
import { aria } from '../../../helpers/accessibility';
import useCombinedRef from '../../../hooks/useCombinedRef';
import useScrollTo from '../../../hooks/useScrollTo';
import { isSSR } from '../../../helpers/dom';
import { m as motion, AnimatePresence } from 'framer-motion';
import Colors, { withOpacity, blend } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';

const initialContentAnimation = {
  opacity: 1,
  translateY: 0,
  overflow: 'visible'
};

export interface AccordionSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  /**
   * The title of the section
   */
  title: string | ReactNode;
  /**
   * The subtitle of the section
   */
  subtitle?: string;
  /**
   * The reference id for the section, will be included in the onToggle callback
   */
  id?: string;
  /**
   * Defines the open or closed state of the accordionSection
   */
  expanded?: boolean;
  /**
   * Called when the user clicks the accordion section's title, regardless of the expansion state.
   */
  onToggle?: (id: string | undefined, expanded: boolean) => void;
  /**
   * The accordion section can be disabled
   */
  disabled?: boolean;
  /**
   * @internal Shows border
   */
  border?: boolean;
  /**
   * @internal Overwrite variant
   */
  variant?: AccordionVariants;
  /**
   * @internal Used for arrow key focus manager
   */
  focusRef?: RefCallback<HTMLButtonElement>;
  /**
   * Only execute calculations for the items that are opened/closed, see the story for more information
   */
  optimizeRendering?: boolean;
  /**
   * @internal Overwrite variant
   */
  largeIcon?: true;
  /**
   * @internal
   */
  scrollIntoView?: boolean;
  /**
   * @internal
   */
  scrollIntoViewOffset?: number;
}

const AccordionSection = forwardRef<HTMLDivElement, AccordionSectionProps>(
  (
    {
      children,
      expanded = false,
      disabled = false,
      border = true,
      largeIcon,
      id,
      onToggle,
      title,
      subtitle,
      variant,
      focusRef,
      scrollIntoViewOffset = 0,
      ...props
    }: AccordionSectionProps,
    ref
  ) => {
    const internalRef = useRef<HTMLDivElement>();
    const combinedRef = useCombinedRef(ref, internalRef);
    const [iconRotation, setIconRotation] = useState<number>(0);

    const titleId = `${id}-title`;
    const expandableId = `${id}-expandable`;

    const open = expanded && !disabled;

    /**
     * Click handler
     */
    const onTitleClick = useCallback(() => {
      onToggle?.(id, !expanded);
    }, [onToggle, id, expanded]);

    const { motion, reset } = useScrollTo(
      0,
      isSSR ? null : document.documentElement || document.body,
      'scrollTop'
    );
    const handleAnimationComplete = useCallback(() => {
      const currentScrollTop = (document.documentElement || document.body).scrollTop;
      const value = (internalRef.current?.getBoundingClientRect().top || 0) + currentScrollTop;

      // start from the current position
      reset(currentScrollTop);
      // to
      motion?.set(value - Math.abs(scrollIntoViewOffset), false);
    }, [motion, reset, scrollIntoViewOffset]);

    const mouseEnterHandler = useCallback(() => {
      setIconRotation((previousRotation) => previousRotation + 90);
    }, []);

    const showSubtitle =
      (variant === AccordionVariants.default ||
        (variant === AccordionVariants.titleOnOpen && !open)) &&
      subtitle;

    const showLargeType = variant === AccordionVariants.titleOnOpen;

    const showLabelAsTitle =
      variant === AccordionVariants.titleOnOpen && !open ? false : showLargeType;

    const overflowStyling = open ? {} : { overflow: 'hidden' };

    return (
      <Container
        {...props}
        ref={combinedRef}
        disabled={disabled}
        onMouseEnter={mouseEnterHandler}
        border={border}
        id={id}
      >
        <TitleWrapper
          ref={focusRef}
          onClick={onTitleClick}
          disabled={disabled}
          expanded={expanded}
          type="button"
          {...aria({
            disabled,
            controls: id,
            expanded: open
          })}
        >
          <TitleContainer id={titleId}>
            {typeof title === 'string' ? (
              showLabelAsTitle ? (
                <StyledTitle>{title}</StyledTitle>
              ) : (
                <TitleLabel>{title}</TitleLabel>
              )
            ) : (
              title
            )}
            {showSubtitle ? <SubLabel disabled={disabled}>{subtitle}</SubLabel> : null}
          </TitleContainer>
          <Spacer orientation={Orientations.horizontal} spacing={Spacings.large} />
          <IconContainer size={showLargeType || largeIcon ? Spacings.xLarge : Spacings.large}>
            {open ? (
              <MinusIcon
                initial={false}
                animate={{ opacity: open ? 1 : 0 }}
                transition={Transitions.default}
                icon={Icons.minus}
                pairedWithText={false}
                size={showLargeType || largeIcon ? IconSizes.medium : IconSizes.small}
                topOffset={false}
              />
            ) : (
              <PlusIcon
                initial={false}
                animate={{ opacity: open ? 0 : 1, rotate: iconRotation }}
                transition={Transitions.default}
                icon={Icons.plus}
                pairedWithText={false}
                size={showLargeType || largeIcon ? IconSizes.medium : IconSizes.small}
                topOffset={false}
              />
            )}
          </IconContainer>
        </TitleWrapper>
        {open && (
          <MotionWrapper
            initial={false}
            animate={{
              height: 'auto',
              display: 'block',
              transitionEnd: {
                display: 'block',
                overflow: 'visible'
              },
              ...overflowStyling
            }}
            transition={Transitions.default}
            {...aria({
              role: 'region'
            })}
            onAnimationComplete={handleAnimationComplete}
            id={expandableId}
          >
            <AnimatePresence>
              <Division
                initial={{
                  ...initialContentAnimation
                }}
                exit={{
                  ...initialContentAnimation,
                  overflow: 'hidden'
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  transitionEnd: {
                    overflow: 'visible'
                  }
                }}
                transition={Transitions.default}
              >
                <Fragment>
                  {children}
                  <Spacer spacing={Spacings.large} />
                </Fragment>
              </Division>
            </AnimatePresence>
          </MotionWrapper>
        )}
      </Container>
    );
  }
);

AccordionSection.displayName = 'AccordionSection';

export default AccordionSection;

const Container = styled(
  'div',
  shouldNotForwardProps(true, 'disabled', 'borderPosition')
)<{
  disabled: boolean;
  border: boolean;
}>(({ disabled, border }) => {
  const { enabled, disabled: disabledState } = {
    enabled: {
      color: Colors.graphiteBlack,
      borderColor: Colors.graphiteBlack
    },
    disabled: {
      color: withOpacity(Colors.graphiteBlack, Opacities.low),
      borderColor: blend(Colors.white, Colors.graphiteBlack, Opacities.xLow)
    }
  };
  return css(
    css`
      border-bottom: 1px solid ${enabled.borderColor};
      transition: ${createCSSTransition('color', MotionEasings.ease, MotionDurations.normal)},
        ${createCSSTransition('border', MotionEasings.ease, MotionDurations.normal)};
    `,
    disabled &&
      css`
        border-bottom: thin solid ${disabledState.borderColor};
        pointer-events: none;
      `,
    !border &&
      css`
        border-bottom: none;
      `
  );
});

const TitleWrapper = styled(
  'button',
  shouldNotForwardProps(true, 'expanded', 'disabled')
)<{
  disabled: boolean;
  expanded: boolean;
}>(({ disabled, expanded }) => {
  const {
    enabled,
    hover,
    active,
    disabled: disabledState
  } = {
    enabled: {
      color: Colors.graphiteBlack,
      borderColor: Colors.graphiteBlack
    },
    disabled: {
      color: withOpacity(Colors.graphiteBlack, Opacities.low),
      borderColor: blend(Colors.white, Colors.graphiteBlack, Opacities.xLow)
    },
    hover: {
      color: Colors.graphiteBlack,
      borderColor: Colors.graphiteBlack
    },
    active: {
      color: Colors.graphiteBlack,
      borderColor: Colors.graphiteBlack
    }
  };
  return css(
    css`
      background: none;
      border: none;
      appearance: none;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      user-select: none;
      justify-content: space-between;
      color: ${enabled.color};
      ${responsiveStyles({
        padding: {
          mobile: `${Spacings.large} 0`
        }
      })};
    `,
    !disabled &&
      expanded &&
      css`
        color: ${active.color};
      `,
    !disabled &&
      css`
        cursor: pointer;

        &:hover {
          color: ${hover.color};
        }
      `,
    disabled &&
      css`
        color: ${disabledState.color};
      `
  );
});

const TitleContainer = styled.div`
  flex-direction: column;
  flex: 1;
`;

const TitleLabel = styled(Label)`
  color: inherit;
  display: block;
  flex-grow: 1;
  /* TODO: Tokenize this max-width value; */
  max-width: 832px;
  pointer-events: none;
`;

const StyledTitle = styled(Title)`
  color: inherit;
  pointer-events: none;
`;

const IconContainer = styled.div<{ size: Spacings }>(
  ({ size }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: ${Spacings.large};
    height: ${size};
    flex-shrink: 0;
  `
);

const PlusIcon = styled(motion(Icon))`
  position: absolute;
`;

const MinusIcon = styled(PlusIcon)``;

const SubLabel = styled(Label)<{ disabled: boolean }>(
  ({ disabled }) =>
    disabled &&
    css`
      color: ${withOpacity(Colors.graphiteBlack, Opacities.low)};
    `
);

const MotionWrapper = styled(motion.div)`
  display: block;
  position: relative;
  width: 100%;
`;

const Division = styled(motion.div)``;
