import React, { forwardRef } from 'react';

import Text, { TextProps } from '../text/Text';
import useTheme from '../../../hooks/useTheme';
import Sizes from '../../../tokens/Sizes';

export interface ParagraphProps extends TextProps<HTMLParagraphElement> {
  /**
   * The size of the paragraph
   */
  size?: Sizes.medium;
}

const Paragraph = forwardRef<HTMLElement, ParagraphProps>(
  ({ as = 'p', size = Sizes.medium, ...props }: ParagraphProps, ref) => {
    const theme = useTheme();
    const { sizes, ...bodyStyles } = theme.text;
    const bodySizeStyles = sizes[size];

    return <Text ref={ref} as={as} {...bodyStyles} {...bodySizeStyles} {...props} />;
  }
);

Paragraph.displayName = 'Paragraph';

export default Paragraph;
