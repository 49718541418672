import { ThemedStyleProperty } from '../../../themes/Theme';
import { Color } from '../../../tokens/Colors';

export function getCurrentBackgroundColor(
  themeName?: string,
  backgroundColor?: Color | ThemedStyleProperty<Color>
): string | undefined {
  if (typeof backgroundColor === 'string') {
    return backgroundColor;
  } else if (backgroundColor && themeName) {
    return backgroundColor[themeName];
  }
  return undefined;
}
