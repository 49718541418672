import { reduceMotion } from '../helpers/accessibility';
import { Transition as TransitionDefinition } from 'framer-motion';

/* eslint-disable */
const disableMotionOnTest =
  typeof navigator !== 'undefined' && navigator.userAgent === 'cw-puppeteer';

export type MotionEasing = string;

export enum MotionEasings {
  // 0,0,1,1
  linear = 'linear',
  // .25,.1,.25,1
  ease = 'ease',
  easeIn = `cubic-bezier(0.8, 0, 1, 1)`,
  easeOut = `cubic-bezier(0,0,0.2,1)`,
  easeInOut = `cubic-bezier(0.6, 0, 0.4, 1)`,
  default = ''
}

export type MotionDuration = number;

export enum MotionDurations {
  none = 0,
  xSlow = 500,
  slow = 400,
  normal = 300,
  fast = 200,
  xFast = 100
}

export enum MotionDurationsSeconds {
  none = 0,
  xSlow = 0.5,
  slow = 0.4,
  normal = 0.3,
  fast = 0.2,
  xFast = 0.1
}

export const createCustomMotionDuration = (valueInMs: number): number =>
  disableMotionOnTest ? 0 : valueInMs;

export const createCSSTransition = (
  property: string,
  ease: MotionEasing = MotionEasings.default,
  duration: MotionDuration = MotionDurations.normal
): string => `${property} ${disableMotionOnTest ? 0 : duration}ms${ease ? ' ' + ease : ''}`;

type TransitionProps = {
  duration?: number;
  disabled?: boolean;
  reduced?: boolean;
} & TransitionDefinition;

export const createTransition = (
  {
    duration = MotionDurationsSeconds.normal,
    reduced = false,
    disabled = false,
    ...transition
  }: TransitionProps = {
    duration: MotionDurationsSeconds.normal,
    reduced: false,
    disabled: false
  }
): any => ({
  duration,
  ...transition,
  ...(disabled || (reduced && reduceMotion) ? { type: false, delay: 0 } : {}),
  ...(disableMotionOnTest
    ? {
        type: false,
        delay: 0
      }
    : {})
});

export const Transitions = {
  default: createTransition(),
  reduced: createTransition({ reduced: true }),
  largeSurface: createTransition({
    duration: 0.6,
    ease: [0.35, 0, 0, 1]
  }),
  spring: createTransition({
    duration: 1,
    type: 'spring',
    bounce: 0
  }),
  reducedSpring: createTransition({
    duration: 1,
    reduced: true,
    type: 'spring',
    bounce: 0
  })
};
