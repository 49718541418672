import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { responsiveStyles } from '../../../helpers/responsiveStyle';
import { maximumStretchedWidth, maximumWidth } from '../../../tokens/BreakPoints';
import Grids from '../../../tokens/Grids';
import { css } from '@emotion/react';
import { shouldNotForwardProps } from '../../../helpers/shouldForwardProps';
import { Color } from '../../../tokens/Colors';
import Spacings from '../../../tokens/Spacings';
import { createCSSTransition, MotionDurations, MotionEasings } from '../../../tokens/Motion';
import { ThemedStyleProperty } from '../../../themes/Theme';
import { getCurrentBackgroundColor } from './Section.helpers';

export interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Only limits the content up until the `maximumStretchedWidth`
   */
  media?: boolean;
  /**
   * Set the backgroundColor
   *
   * @default transparent
   */
  backgroundColor?: Color | ThemedStyleProperty<Color>;
}

const Section = forwardRef<HTMLElement, SectionProps>(
  ({ children, backgroundColor, media = false, ...props }: SectionProps, ref) => {
    let content = children;

    if (!media) {
      content = <SectionInner>{children}</SectionInner>;
    }

    return (
      <StyledSection {...props} ref={ref} backgroundColor={backgroundColor} media={media}>
        {content}
      </StyledSection>
    );
  }
);

Section.displayName = 'Section';

export default Section;

export const MediaSection = forwardRef<HTMLElement, SectionProps>((props: SectionProps, ref) => (
  <Section {...props} ref={ref} media />
));

MediaSection.displayName = 'MediaSection';

export const CTASection = forwardRef<HTMLElement, SectionProps>(
  ({ children, backgroundColor, ...props }: SectionProps, ref) => (
    <StyledSection {...props} ref={ref} media>
      <StyledCTAWrapper backgroundColor={backgroundColor}>
        <SectionInner>{children}</SectionInner>
      </StyledCTAWrapper>
    </StyledSection>
  )
);

CTASection.displayName = 'CTASection';

const StyledCTAWrapper = styled.div<{ backgroundColor?: Color | ThemedStyleProperty<Color> }>(
  ({ backgroundColor }) => {
    const currentBackgroundColor = getCurrentBackgroundColor('light', backgroundColor);
    return (
      currentBackgroundColor &&
      css`
        background-color: ${currentBackgroundColor};
      `
    );
  }
);

StyledCTAWrapper.displayName = 'StyledCTAWrapper';

const StyledSection = styled(
  'section',
  shouldNotForwardProps(true, 'media')
)<SectionProps>(({ media, backgroundColor }) => {
  const currentBackgroundColor = getCurrentBackgroundColor('light', backgroundColor);
  return css(
    css`
      width: 100%;
      position: relative;
      margin: 0 auto;

      ${responsiveStyles({
        paddingLeft: {
          mobile: Grids.mobile.gutter,
          tablet: Grids.tablet.gutter,
          desktop: Grids.desktop.gutter
        },
        paddingRight: {
          mobile: Grids.mobile.gutter,
          tablet: Grids.tablet.gutter,
          desktop: Grids.desktop.gutter
        }
      })}
    `,
    currentBackgroundColor &&
      css`
        background-color: ${currentBackgroundColor};
      `,
    !!media &&
      css`
        max-width: ${maximumStretchedWidth}px;
      `
  );
});

StyledSection.displayName = 'StyledSection';

export const SectionInner = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => <StyledSectionInner ref={ref} {...props} />
);
SectionInner.displayName = 'SectionInner';

const StyledSectionInner = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  transition: ${createCSSTransition('padding', MotionEasings.easeOut, MotionDurations.fast)};

  ${responsiveStyles({
    padding: {
      mobile: `0 ${Grids.mobile.gutter}`,
      tablet: `0 ${Spacings.large}`,
      desktop: `0 ${Spacings.xLarge}`,
      desktopWide: `0 ${Spacings.xxxxLarge}`
    },
    maxWidth: {
      mobile: '100%',
      desktopWide: `${maximumWidth}px`
    }
  })}
`;
