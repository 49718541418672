import {
  PageResponse,
  Page,
  MediaItem,
  PageSection,
  SimpleText,
  ProductCardList
} from '../../types/models/cms';
import { HomeViewSections } from '../../types/models/cms/homeView';
import { getPageContent, getPageSection } from '.';

/**
 * Return page content
 */
export const getHomePageContent = ({
  page: { sections }
}: PageResponse): Page<HomeViewSections> => {
  return {
    sections: getHomeViewSections(sections)
  };
};

const getHomeViewSections = (sections: PageSection[]): HomeViewSections => {
  const hero = getPageSection('hero', sections);
  const products = getPageSection('products', sections);
  return {
    hero: {
      theme: hero.theme,
      name: hero.name,
      label: hero.label,
      image: getPageContent<MediaItem>('homeImage', hero),
      vision: getPageContent<SimpleText>('vision', hero),
      mission: getPageContent<SimpleText>('mission', hero),
      values: getPageContent<SimpleText>('values', hero)
    },
    products: {
      theme: products.theme,
      name: products.name,
      label: products.label,
      productCards: getPageContent<ProductCardList>('easymixProducts', products)
    }
  };
};
