import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Header from '../molecules/header/Header';
import Footer from '../molecules/footer/Footer';
import useCustomIndicatorVisibility from '../../hooks/useCustomIndicatorVisibility';
import Icon, { IconSizes } from '../atoms/icon/Icon';
import Icons from '../../tokens/icons/Icons';
import Link from '../atoms/link/Link';
import Colors, { withOpacity } from '../../tokens/Colors';
import Spacings from '../../tokens/Spacings';
import Markdown from '../atoms/markdown/Markdown';
import useDictionary from '../../hooks/useDictionary';
import { AnimatePresence, motion } from 'framer-motion';
import Label from '../atoms/label/Label';
import Opacities from '../../tokens/Opacities';

export interface MainLayoutProps {
  children: React.ReactNode;
  noHeaderMenu?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({ children, noHeaderMenu = false }: MainLayoutProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const isCustomIndicatorVisible = useCustomIndicatorVisibility({ rootRef });
  const [isHover, toggleHover] = useState<boolean>(false);
  const dictionary = useDictionary();
  return (
    <>
      <Header noHeaderMenu={noHeaderMenu} />
      <Root ref={rootRef}>
        <AnimatePresence>
          {children}
          <CustomIndicatorWrapper
            isVisible={isCustomIndicatorVisible}
            onMouseOver={() => !isHover && toggleHover(true)}
            onMouseOut={() => isHover && toggleHover(false)}
            onFocus={() => !isHover && toggleHover(true)}
            onBlur={() => isHover && toggleHover(false)}
            animate={{
              width: 200,
              x: isHover ? 0 : 140
            }}
          >
            <StyledLink href="https://api.whatsapp.com/send?phone=918888822844">
              <Icon icon={Icons.whatsapp} color={Colors.white} size={IconSizes.large} />
              <StyledLabel isVisible={isHover}>
                {<Markdown text={dictionary.get('Chat with us')} />}
              </StyledLabel>
            </StyledLink>
          </CustomIndicatorWrapper>
        </AnimatePresence>
      </Root>
      <Footer />
    </>
  );
};

const Root = styled.div`
  position: relative;
`;

const CustomIndicatorWrapper = styled(motion.div)<{ isVisible: boolean }>(
  ({ isVisible }) => `
  ${isVisible ? '' : 'pointer-events: none;'}
  opacity: ${isVisible ? 1 : 0};
  transition: opacity 0.3s;
  position: fixed;
  right: 0;
  top: 50vh;
  width: 50px;
  z-index:1;
  background: ${withOpacity(Colors.gieplGreen, Opacities.xxMedium)};
  padding: ${Spacings.medium};
  box-shadow: 0 0 31px 0 ${Colors.boxShadow};
  cursor: pointer;
`
);

const StyledLink = styled(Link)`
  color: ${Colors.gieplGreen};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${Colors.gieplGreen};
  }
`;

const StyledLabel = styled(Label)<{ isVisible: boolean }>(
  ({ isVisible }) => `
opacity: ${isVisible ? 1 : 0};
transition: opacity 0.5s 0.2s;
color: ${Colors.white};
padding: 0 0 0 ${Spacings.medium};
font-size:${IconSizes.large};
cursor: pointer;
`
);

export default MainLayout;
