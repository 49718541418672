import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import MainLayout from './MainLayout';

interface MainLayoutProps extends RouteProps {
  /* eslint-disable @typescript-eslint/no-explicit-any*/
  component: any;
  noHeaderMenu?: boolean;
}

const MainLayoutRoute: FC<MainLayoutProps> = ({
  component: Component,
  noHeaderMenu = false,
  ...rest
}: MainLayoutProps) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <MainLayout noHeaderMenu={noHeaderMenu}>
        <Component {...matchProps} />
      </MainLayout>
    )}
  />
);

export default MainLayoutRoute;
