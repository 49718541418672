import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ProductCard } from '../../../types/models/cms';
import Title from '../title/Title';
import Colors, { withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';
import Spacings from '../../../tokens/Spacings';
import ResponsiveImageWithRatio from '../responsive-image-with-ratio/ResponsiveImageWithRatio';
import Paragraph from '../paragraph/Paragraph';
import MediaQueries from '../../../tokens/MediaQueries';
import RevealInView from '../reveal-in-view/RevealInView';
import Markdown from '../markdown/Markdown';
import useDictionary from '../../../hooks/useDictionary';
import Icons from '../../../tokens/icons/Icons';
import Icon from '../icon/Icon';
import TextButton from '../text-button/TextButton';
import Spacer from '../spacer/Spacer';
import Sizes from '../../../tokens/Sizes';

export interface ProductCardProps extends ProductCard {
  index: number;
  showLearnMore?: boolean;
  learnMoreClick?: (title: string) => void;
}

const ProductCardComponent: FC<ProductCardProps> = ({
  title = '',
  description = '',
  cardImage,
  index,
  showLearnMore = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  learnMoreClick = () => {}
}: ProductCardProps) => {
  const dictionary = useDictionary();
  return (
    <>
      <ProductCardContainer delay={index * 0.1}>
        <CardSection>
          <CardTitle as="h4">
            <Markdown text={title} />
          </CardTitle>
          {cardImage && <StyledResponsiveImage {...cardImage} ratio={200 / 288} />}
          <StyledPargraph>
            <Markdown text={description} />
          </StyledPargraph>
          {showLearnMore && (
            <>
              <Spacer spacing={Spacings.xLarge} />
              <StyledTextButton
                trailing={<Icon icon={Icons.arrowRight} size={Sizes.small} />}
                onClick={() => learnMoreClick(title)}
              >
                <Markdown text={dictionary.get('Learn More')} />
              </StyledTextButton>
            </>
          )}
        </CardSection>
      </ProductCardContainer>
    </>
  );
};

const ProductCardContainer = styled(RevealInView)``;

const CardSection = styled.div`
  border: 1px solid ${withOpacity(Colors.graphiteBlack, Opacities.medium)};
  padding: ${Spacings.large};
  box-sizing: border-box;
  ${MediaQueries.mobileOnly} {
    width: min(100vw, 320px);
    min-height: 700px;
  }
  ${MediaQueries.tablet} {
    min-height: 700px;
  }
`;

const CardTitle = styled(Title)`
  margin: 0 0 ${Spacings.medium};
`;

const StyledResponsiveImage = styled(ResponsiveImageWithRatio)`
  object-fit: contain;
  margin: 0 0 ${Spacings.medium};
`;

const StyledPargraph = styled(Paragraph)`
  line-height: 1.4;
`;

const StyledTextButton = styled(TextButton)`
  color: ${Colors.gieplGreen};
`;

export default ProductCardComponent;
