import React, { forwardRef } from 'react';

const Facebook = forwardRef<SVGSVGElement, React.SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    role="img"
    ref={ref}
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="#fff" />
    <path
      d="M22.654 0H1.346A1.34 1.34 0 000 1.346v21.308A1.34 1.34 0 001.346 24h11.44v-9.346H9.644v-3.589h3.14V8.374c0-3.066 1.87-4.785 4.636-4.785 1.346 0 2.467.075 2.766.15v3.214h-1.87c-1.495 0-1.794.748-1.794 1.795v2.317h3.589l-.448 3.59h-3.14v9.27h6.056a1.34 1.34 0 001.345-1.346V1.346C24 .598 23.402 0 22.655 0z"
      fill="currentColor"
    />
  </svg>
));
Facebook.displayName = 'Facebook';
export default Facebook;
