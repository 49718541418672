import React, { forwardRef } from 'react';

const Minus = forwardRef<SVGSVGElement, React.SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    role="img"
    ref={ref}
    {...props}
  >
    <path d="M24 11.25H0v1.5h24z" fill="currentColor" />
  </svg>
));
Minus.displayName = 'Minus';
export default Minus;
