import { css } from '@emotion/css';
import Opacities from '../../../tokens/Opacities';
import styled from '@emotion/styled';
import { EasingFunction } from 'bezier-easing';
import { motion, Variants } from 'framer-motion';
import React, { FunctionComponent, useCallback, useState } from 'react';

import Easings from '../../../tokens/Easings';

export const variants = {
  initial: ({ initialY = 10 }): unknown => ({
    opacity: Opacities.none,
    y: initialY
  }),
  opacityInStart: ({ delay = 0 }): unknown => ({
    opacity: Opacities.none,
    transition: {
      delay
    }
  }),
  opacityIn: ({
    animationSpeed = 1,
    delay = 0,
    duration = 0.8,
    easing = Easings.EaseOut
  }): unknown => ({
    opacity: Opacities.full,
    transition: {
      delay: delay * animationSpeed,
      duration: duration * animationSpeed,
      ease: easing
    }
  }),
  transformIn: ({
    animationSpeed = 1,
    delay = 0,
    duration = 0.8,
    easing = Easings.EaseOut
  }): unknown => ({
    y: 0,
    transition: {
      delay: delay * animationSpeed,
      duration: duration * animationSpeed,
      ease: easing
    }
  }),
  opacityOut: ({ animationSpeed = 1, delay = 0, easing = Easings.EaseOut }): unknown => ({
    opacity: Opacities.none,
    transition: {
      delay: delay * animationSpeed,
      duration: 0.4 * animationSpeed,
      ease: easing
    }
  }),
  transformOut: ({
    animationSpeed = 1,
    delay = 0,
    easing = Easings.EaseOut,
    initialY = 10
  }): unknown => ({
    y: initialY,
    transition: {
      delay: (delay + 0.4) * animationSpeed,
      duration: 0,
      ease: easing
    }
  })
} as Variants;

export interface RevealProps {
  className?: string;
  delay?: number;
  duration?: number;
  easing?: EasingFunction;
  show: boolean;
  initialY?: number;
  onAnimationComplete?: () => void;
  animationSpeed?: number;
}

/**
 * A component to control reveal from making-anew repository
 * @see https://bitbucket.org/polestardevhouse/polestar-making-anew/src/273f42db8cbc31bc58dd4082ddf78f0c15cfb7f7/src/components/atoms/reveal/Reveal.tsx
 */
const Reveal: FunctionComponent<RevealProps> = ({
  children,
  className,
  delay = 0,
  duration = 0.8,
  animationSpeed = 1,
  easing,
  show,
  initialY = 10,
  onAnimationComplete
}) => {
  const [completed, setCompleted] = useState(false);

  const onComplete = useCallback(() => {
    show && setCompleted(true);
    onAnimationComplete && onAnimationComplete();
  }, [onAnimationComplete, show]);

  return (
    <Container
      initial="initial"
      animate={show ? ['opacityIn', 'transformIn'] : ['opacityOut', 'transformOut']}
      custom={{ delay, duration, easing, initialY, animationSpeed }}
      variants={variants as Variants}
      onAnimationComplete={onComplete}
      className={className}
      completed={completed}
    >
      {children}
    </Container>
  );
};

const Container = styled(motion.div)<{ completed: boolean }>`
  display: block;
  font-size: inherit;
  line-height: inherit;

  /**
   * On safari the fullscreen api will not work nice with the will-change property, that's why we get rid of it when we are done using it.
   **/
  ${({ completed }) =>
    !completed
      ? css`
          will-change: opacity, translate;
        `
      : css``}
`;

export default Reveal;
