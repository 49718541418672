/**
 * @internal
 */
import { css } from '@emotion/react';
import Spacings from '../../tokens/Spacings';

export const overflowTextOffsetValue = '0.15em';

export const overflowTextOffset = css`
  padding-top: ${overflowTextOffsetValue};
  margin-top: -${overflowTextOffsetValue};
`;

export const overflowEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  ${overflowTextOffset};
`;

export interface MinimalHitboxProps {
  maintainWidth?: boolean;
  maintainHeight?: boolean;
}

// parent node needs `position: relative` to make this work
const createMinimalHitbox = ({
  maintainWidth = false,
  maintainHeight = false
}: MinimalHitboxProps = {}) =>
  css(
    css`
      &:before {
        position: absolute;
        content: '';
        min-width: auto;
        min-height: auto;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,
    maintainWidth &&
      css`
        &:before {
          min-width: ${Spacings.xxLarge};
        }
      `,
    maintainHeight &&
      css`
        &:before {
          min-height: ${Spacings.xxLarge};
        }
      `
  );

export const minimalHitboxWidth = createMinimalHitbox({ maintainWidth: true });
export const minimalHitboxHeight = createMinimalHitbox({ maintainHeight: true });
export const minimalHitbox = createMinimalHitbox({ maintainWidth: true, maintainHeight: true });

/**
 * easily find edge-cases where no hitbox (>48px) is necessary
 *
 * Link: https://www.w3.org/WAI/WCAG21/Understanding/target-size.html > "inline" mentions that
 * due to responsiveness can be excluded of 48px minimal size
 *
 * MapMarker / MapCluster: too much chance that markers overlap
 */
export const noHitboxNeeded = css``;
