import { Globals } from 'csstype';

export type MainAxisAlignment =
  | Globals
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'start'
  | 'left'
  | 'normal'
  | 'right';

export type CrossAxisAlignment =
  | Globals
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'self-end'
  | 'self-start'
  | 'start'
  | 'baseline'
  | 'normal'
  | 'stretch';

export enum MainAxisAlignments {
  start = 'flex-start',
  center = 'center',
  end = 'flex-end',
  spaceBetween = 'space-between',
  spaceAround = 'space-around',
  spaceEvenly = 'space-evenly',
  stretch = 'stretch'
}

export enum CrossAxisAlignments {
  start = 'flex-start',
  center = 'center',
  end = 'flex-end',
  stretch = 'stretch',
  baseline = 'baseline'
}

enum Alignments {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  center = 'center',
  right = 'right'
}

export default Alignments;
