import Text, { TextProps } from '../text/Text';
import useTheme from '../../../hooks/useTheme';
import Sizes from '../../../tokens/Sizes';
import React, { forwardRef } from 'react';

export interface LabelProps extends Omit<TextProps<HTMLLabelElement>, 'size'> {
  /**
   * Which id the label belongs to
   */
  htmlFor?: string;
  /**
   * The size of the Label
   */
  size?: Sizes.xSmall | Sizes.small | Sizes.medium | Sizes.large;
}

const Label = forwardRef<HTMLElement, LabelProps>(
  ({ as = 'label', size = Sizes.medium, ...props }: LabelProps, ref) => {
    const theme = useTheme();
    const { sizes, ...labelStyles } = theme.label;
    const labelSize = size;
    const labelSizeStyles = sizes[labelSize];

    return <Text ref={ref} as={as} {...labelStyles} {...labelSizeStyles} {...props} />;
  }
);

Label.displayName = 'Label';

export default Label;
