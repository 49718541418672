import styled from '@emotion/styled';

import EnhancedMediaQueries from '../../../tokens/MediaQueries';

export interface RatioBoxProps {
  /**
   * Ratio
   */
  ratio?: number | string;
  /**
   * Ratio for mobile layout
   */
  ratioSmall?: number | string;
  /**
   * Ratio for desktop layout
   */
  ratioLarge?: number | string;
}

const RatioBox = styled.div<RatioBoxProps>(({ ratio, ratioSmall, ratioLarge }) => {
  const paddingTop = typeof ratio === 'number' ? `${ratio * 100}%` : ratio;
  const paddingTopSmall = typeof ratioSmall === 'number' ? `${ratioSmall * 100}%` : ratioSmall;
  const paddingTopLarge = typeof ratioLarge === 'number' ? `${ratioLarge * 100}%` : ratioLarge;
  return `
    pointer-events: none;
    position: relative;
    ${paddingTop ? `padding-top: ${paddingTop};` : ''}
    ${
      paddingTopSmall
        ? `${EnhancedMediaQueries.mobileOnly} { padding-top: ${paddingTopSmall}; }`
        : ''
    }
    ${paddingTopLarge ? `${EnhancedMediaQueries.tablet} { padding-top: ${paddingTopLarge}; }` : ''}
  `;
});

export default RatioBox;
