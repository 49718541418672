import React, { forwardRef } from 'react';

const WhatsApp = forwardRef<SVGSVGElement, React.SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    role="img"
    ref={ref}
    {...props}
  >
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="M18.037 14.85c-.075-.113-.262-.188-.562-.338s-1.763-.862-2.063-.975c-.262-.112-.487-.15-.675.15s-.787.975-.937 1.163c-.188.187-.338.225-.638.075s-1.275-.45-2.4-1.5c-.9-.788-1.5-1.763-1.65-2.063-.187-.3 0-.45.15-.6s.3-.337.45-.525.188-.3.3-.487.038-.375-.037-.525-.675-1.613-.938-2.213c-.225-.6-.487-.487-.675-.525H7.8c-.187 0-.525.075-.788.375s-1.05 1.013-1.05 2.513c0 1.462 1.088 2.887 1.238 3.112.15.188 2.1 3.225 5.137 4.538.713.3 1.275.487 1.725.637.713.225 1.388.188 1.875.113.563-.075 1.763-.713 2.025-1.425.15-.75.15-1.35.075-1.5z"
        fillRule="evenodd"
      />
      <path d="M20.475 3.525C18.225 1.237 15.225 0 12 0 5.4 0 0 5.362 0 12c0 2.1.563 4.162 1.613 6L0 24l6.263-1.462A11.764 11.764 0 0012 24c6.6 0 12-5.4 12-12 0-3.188-1.238-6.225-3.525-8.475zM12 21.975c-1.8 0-3.562-.488-5.062-1.388l-.375-.225-3.788 1.013L3.788 17.7l-.225-.375A9.99 9.99 0 012.025 12c0-5.513 4.5-9.975 9.975-9.975 2.663 0 5.175 1.05 7.05 2.925s2.925 4.387 2.925 7.05A9.97 9.97 0 0112 21.975z" />
    </g>
  </svg>
));
WhatsApp.displayName = 'WhatsApp';
export default WhatsApp;
