/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * @internal
 */
import { css, SerializedStyles } from '@emotion/react';
import Colors from '../../tokens/Colors';
import { isClient } from '../dom/index';

type Aria = { [key: string]: string | boolean | number | undefined | null };

export const aria = (values: Aria) => {
  const result: Aria = {};

  for (const key in values) {
    const value = values[key];

    switch (key) {
      case 'role':
        result.role = value;
        break;
      case 'disabled':
        if (value === true) {
          result[`aria-${key}`] = true;
        }
        break;
      case 'label':
        if (value !== undefined && value !== '') {
          result[`aria-${key}`] = value;
        }
        break;
      case 'current':
      case 'errormessage':
      case 'describedby':
        if (value !== undefined && value !== false) {
          result[`aria-${key}`] = value;
        }
        break;
      default:
        result[`aria-${key}`] = value;
    }
  }

  return result;
};

export const reduceMotion: boolean =
  isClient && window?.matchMedia?.('(prefers-reduced-motion: reduce)').matches;

export const reduceAnimation = (reduced = 'none') =>
  css`
    @media (prefers-reduced-motion: reduce) {
      animation: ${reduced};
    }
  `;

export const focusStyleHide = css`
  &::-moz-focus-inner {
    border: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

const createFocusStyle = (focusedStyle: SerializedStyles) => css`
  &::-moz-focus-inner {
    border: none;
  }

  &:focus {
    outline: 0;
  }

  [data-interaction-mode='keyboard'] &:focus {
    z-index: 100;
    ${focusedStyle};
  }
`;

const createFocusWithinStyle = (focusedStyle: SerializedStyles) => css`
  [data-interaction-mode='keyboard'] &:focus-within {
    z-index: 100;
    ${focusedStyle};
  }
`;

export const boxShadowFocus = () => css`
  box-shadow: 0 0 0 2px ${Colors.white}, 0 0 0 4px ${Colors.graphiteBlack};
`;

export const borderBottomFocus = () => css`
  border-bottom: 2px solid ${Colors.graphiteBlack};
`;

export const focusStyle = () => createFocusStyle(boxShadowFocus());

export const borderBottomFocusStyle = () => createFocusStyle(borderBottomFocus());

export const focusWithinStyle = () => createFocusWithinStyle(boxShadowFocus());

export const keyboardOnlyStyle = `[data-interaction-mode='keyboard'] &`;
export const pointerOnlyStyle = `[data-interaction-mode='pointer'] &`;
