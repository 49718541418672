import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import useProductsList from '../../../hooks/useProductsList';
import Section from '../../atoms/section/Section';
import GET_ALL_PAGE_CONTENT from '../../../graphQL/queries/getPageContent';
import { useQuery } from 'graphql-hooks';
import PageLoadingSpinner from '../../atoms/page-loading-spinner/PageLoadingSpinner';
import SectionHero from '../../organisms/section-hero/SectionHero';
import { ProductsViewPageContent } from '../../../helpers/cms/productsView';
import Grid from '../../atoms/grid/Grid';
import { ProductCard } from '../../../types/models/cms';
import ProductCardWithHover from '../../molecules/product-card-with-hover/ProductCardWithHover';
import GridColumn from '../../atoms/grid/GridColumn';
import { isDataExists } from '../../../helpers/array';
import { isMobileLayout, isClient, isTouchDevice } from '../../../helpers/dom';
import useResizeObserver from '../../../hooks/useResizeObserver';
import { useMotionValue, PanInfo, motion } from 'framer-motion';
import ProductCardComponent from '../../atoms/product-card/ProductCard';
import Spacings from '../../../tokens/Spacings';
import Spacer from '../../atoms/spacer/Spacer';
import ProductDetailOverlay from '../../molecules/product-detail-overlay/ProductDetailOverlay';

const maxCardWidth = 320;
const gap = 16;

const ProductsView: FC = () => {
  const isMobile = isMobileLayout();
  const productCardList = useProductsList();
  const canvasRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const { width: canvasWidth } = useResizeObserver({ targetRef: canvasRef });
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const carouselX = useMotionValue(0);
  const initialCarouselXRef = useRef(0);
  const [isPanning, setIsPanning] = useState(false);
  const [showPopup, togglePopUp] = useState<boolean>(false);
  const [selectedCard, setSelectedCardDetails] = useState<ProductCard | undefined>({});

  const { data, loading } = useQuery(GET_ALL_PAGE_CONTENT, {
    variables: {
      pathname: '/products'
    }
  });
  if (loading) {
    return <PageLoadingSpinner />;
  }
  if (!loading && !!data) {
    const content = ProductsViewPageContent(data);
    const { sections } = content;
    /**
     * Set card information
     */
    const offersLength = (productCardList?.cards || []).length;
    let cardWidth = isClient ? window.innerWidth * 0.88 - gap : maxCardWidth;
    cardWidth = Math.min(maxCardWidth, cardWidth);
    const totalCardsWidth = offersLength * cardWidth + (offersLength - 1) * gap;
    const totalPage = isMobile ? offersLength : Math.ceil(totalCardsWidth / (canvasWidth + gap));
    const pageWidth = Math.ceil(offersLength / totalPage) * (cardWidth + gap);
    const hasPage = totalPage > 1;

    /**
     * Set page index
     * @param index next page index
     */
    const setPageIndex = (index: number) => {
      setIsPanning(false);
      setActiveIndex(index);
      carouselX.set(index * pageWidth * -1);
    };

    /**
     * When pan starts
     */
    const onPanStart = () => {
      if (!hasPage) return;
      /** Register initial x position */
      initialCarouselXRef.current = carouselX.get();
      setIsPanning(true);
    };

    /**
     * While panning
     */
    const onPan = (info: PanInfo) => {
      if (!hasPage) return;
      /** Move */
      carouselX.set(initialCarouselXRef.current + info.offset.x * 0.2);
    };

    /**
     * When pan ends
     */
    const onPanEnd = (info: PanInfo) => {
      if (!hasPage) return;
      const offset = info.velocity.x;
      const vect = offset === 0 ? 0 : offset > 0 ? -1 : 1;
      const nextPage = Math.max(0, Math.min(totalPage - 1, activeIndex + vect));
      setPageIndex(nextPage);
    };

    const handleLearnMoreClick = (title: string) => {
      setSelectedCardDetails(
        (productCardList?.cards || [])?.find((eachCard) => eachCard.title === title)
      );
      togglePopUp(true);
    };
    return (
      <main className="container">
        <div ref={canvasRef} />
        <SectionHero {...sections?.hero} showMissionStatement={false} />
        <Spacer spacing={Spacings.xxLarge} />
        <Section>
          {!isTouchDevice ? (
            <Grid nested>
              {isDataExists(productCardList?.cards || []) &&
                (productCardList?.cards || []).map((eachCard: ProductCard, index: number) => (
                  <GridColumn span={{ mobile: 4, desktop: 4, tablet: 4 }} key={index}>
                    <ProductCardWithHover
                      {...eachCard}
                      index={index}
                      learnMoreClick={handleLearnMoreClick}
                    />
                  </GridColumn>
                ))}
            </Grid>
          ) : (
            <>
              {isMobile ? (
                <MotionCarouselContainer
                  {...{ totalCardsWidth, isPanning }}
                  style={{ x: carouselX }}
                  onPanStart={onPanStart}
                  onPan={(e, info) => onPan(info)}
                  onPanEnd={(e, info) => onPanEnd(info)}
                  ref={carouselRef}
                >
                  {isDataExists(productCardList?.cards || []) &&
                    (productCardList?.cards || []).map((eachCard: ProductCard, index: number) => (
                      <ProductCardComponent
                        {...eachCard}
                        index={index}
                        key={index}
                        showLearnMore
                        learnMoreClick={handleLearnMoreClick}
                      />
                    ))}
                </MotionCarouselContainer>
              ) : (
                <Grid>
                  {isDataExists(productCardList?.cards || []) &&
                    (productCardList?.cards || []).map((eachCard: ProductCard, index: number) => (
                      <GridColumn span={{ mobile: 4, desktop: 4, tablet: 4 }} key={index}>
                        <ProductCardComponent
                          {...eachCard}
                          index={index}
                          showLearnMore
                          learnMoreClick={handleLearnMoreClick}
                        />
                      </GridColumn>
                    ))}
                </Grid>
              )}
            </>
          )}
        </Section>
        {showPopup && (
          <ProductDetailOverlay {...selectedCard} closeOverlay={() => togglePopUp(false)} />
        )}
      </main>
    );
  }
  return <></>;
};

export default ProductsView;

const MotionCarouselContainer = styled(motion.div)<{ totalCardsWidth: number; isPanning: boolean }>(
  ({ totalCardsWidth, isPanning }) => `
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  width: ${totalCardsWidth}px;
  ${isPanning ? '' : 'transition: transform 0.3s;'}
  margin-bottom: ${Spacings.xLarge};
  `
);
