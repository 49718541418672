/**
 * Returns whether an array has a particular number of elements
 * @param {any[]} arr any array that needs to be checked
 * @param {number} lengthToCheck the array length that needs to be checked
 * @returns {boolean}
 * @example
 * isDataExists([],1)
 * //-> false
 * @example
 * isDataExists([1,2])
 * //->true
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataExists = (arr: any[], lengthToCheck = 0): boolean =>
  arr && arr.length > lengthToCheck;

/**
 * Returns array of number
 * @param {number} length array length
 * @param {number} [index] starting number
 * @return {number[]}
 * @example
 * listArray(4)
 * // -> [0, 1, 2, 3]
 * @example
 * listArray(3, 2)
 * // -> [2, 3, 4]
 */
export const listArray = (length: number, index = 0): number[] =>
  Array.from({ length: Math.floor(length) }, (v, k) => k + index);
