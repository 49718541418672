import * as React from 'react';
import { ProductCardList } from '../../types/models/cms';

export interface ProductContextProps {
  products: ProductCardList;
}

/**
 * The project context
 */
export const ProductContext = React.createContext<ProductContextProps>({
  products: {}
});

export default ProductContext;
