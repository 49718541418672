import Button, { ExtendedButtonProps } from '../button/Button';
import useTheme from '../../../hooks/useTheme';
import { MainAxisAlignments } from '../../../tokens/Alignments';
import Sizes from '../../../tokens/Sizes';
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { minimalHitbox } from '../../../helpers/style';

export interface TextButtonProps
  extends Omit<ExtendedButtonProps, 'mainAxisAlignment' | 'crossAxisAlignment'> {
  /**
   * The size of the button `Sizes.small` or `Sizes.medium` by default the `Sizes.medium` size is used
   */
  size?: TextButtonSize;
  /**
   * The horizontal alignment inside the button between the text and the icon.
   */
  mainAxisAlignment?: MainAxisAlignments.start | MainAxisAlignments.spaceBetween;
}

/**
 * @internal
 */
export type TextButtonSize = Sizes.medium | Sizes.large;

const TextButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, TextButtonProps>(
  (
    {
      size = Sizes.medium,
      mainAxisAlignment = MainAxisAlignments.start,
      ...props
    }: TextButtonProps,
    ref
  ) => {
    const theme = useTheme();
    const themeSize = size;
    const { sizes, states, ...textButtonTextStyles } = theme.textButton;
    const textButtonSizeStyles = sizes[themeSize];

    return (
      <StyledButton
        {...props}
        ref={ref}
        stateStyles={states}
        mainAxisAlignment={mainAxisAlignment}
        {...textButtonTextStyles}
        {...textButtonSizeStyles}
        topOffset={false}
      >
        {props.children}
      </StyledButton>
    );
  }
);

TextButton.displayName = 'TextButton';

export default TextButton;

const StyledButton = styled(Button)(() =>
  css(
    css`
      position: relative;
    `,
    minimalHitbox
  )
);
