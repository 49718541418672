import { css } from '@emotion/react';
import { responsiveStyles } from '../../../helpers/responsiveStyle';
import { shouldNotForwardThemeProps } from '../../../helpers/shouldForwardProps';
import styled from '@emotion/styled';
import { ResponsiveThemeData } from '../../../themes/Theme';
import { Color } from '../../../tokens/Colors';
import FontSizes, { FontSize } from '../../../tokens/FontSizes';
import { FontStyle } from '../../../tokens/FontStyle';
import { FontWeight } from '../../../tokens/FontWeights';
import { Font } from '../../../tokens/Fonts';
import LetterSpacings, { LetterSpacing } from '../../../tokens/LetterSpacings';
import LineHeights, { LineHeight } from '../../../tokens/LineHeights';
import React, { forwardRef, ReactNode } from 'react';
import { overflowEllipsis, overflowTextOffset } from '../../../helpers/style';

export interface TextProps<T = HTMLSpanElement>
  extends Omit<React.HTMLAttributes<T>, 'children' | 'color'> {
  /**
   * The text to show
   */
  children: ReactNode;
  /**
   * @internal
   */
  fontFamily?: Font;
  /**
   * @internal
   */
  fontWeight?: FontWeight;
  /**
   * @internal
   */
  fontStyle?: FontStyle;
  /**
   * @internal
   */
  color?: Color;
  /**
   * @internal
   */
  fontSize?: ResponsiveThemeData<FontSize>;
  /**
   * @internal
   */
  letterSpacing?: ResponsiveThemeData<LetterSpacing>;
  /**
   * @internal
   */
  lineHeight?: ResponsiveThemeData<LineHeight>;
  /**
   * Enable / disable wrapping of the text
   */
  noWrap?: boolean;
  /**
   * How many lines before the text will be truncated
   */
  lineClamp?: number;
}

const Text = forwardRef<HTMLSpanElement, TextProps>((props: TextProps, ref) => {
  return <StyledSpan {...props} ref={ref} />;
});

Text.displayName = 'Text';

export default Text;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledSpan = styled(
  'span',
  shouldNotForwardThemeProps
)<TextProps>(({ noWrap, lineClamp, ...props }) => {
  const sizes = {
    medium: {
      fontSize: {
        mobile: FontSizes.body
      },
      letterSpacing: {
        mobile: LetterSpacings.small
      },
      lineHeight: {
        mobile: LineHeights.small
      }
    }
  };

  const style = {
    ...sizes.medium,
    ...props
  };

  return css(
    !!style.fontFamily &&
      css`
        font-family: ${style.fontFamily};
      `,
    !!style.fontWeight &&
      css`
        font-weight: ${style.fontWeight};
      `,
    !!style.fontStyle &&
      css`
        font-style: ${style.fontStyle};
      `,
    !!style.color &&
      css`
        color: ${style.color};
      `,
    css`
      ${responsiveStyles({
        fontSize: style.fontSize,
        letterSpacing: style.letterSpacing,
        lineHeight: style.lineHeight
      })}
    `,
    noWrap &&
      css`
        white-space: nowrap;
        ${overflowEllipsis};
      `,
    lineClamp &&
      style.lineHeight &&
      css`
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: ${lineClamp};
        -webkit-box-orient: vertical;
        ${overflowTextOffset};
      `
  );
});
