import { AccordionSectionProps } from './AccordionSection';
import generateId from '../../../helpers/generateId';
import styled from '@emotion/styled';
import Spacings from '../../../tokens/Spacings';
import React, {
  Children,
  cloneElement,
  forwardRef,
  ReactElement,
  Reducer,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef
} from 'react';
import { css } from '@emotion/react';
import { aria } from '../../../helpers/accessibility';
import AccordionTheme from './Accordion.theme';

export interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Callback when a child accordionSection opens or closes
   */
  onToggle?: (id: string, expanded: boolean) => void;
  /**
   * Disabled all the children
   */
  disabled?: boolean;
  /**
   * Close other items when opening a new one
   */
  closeOthersOnOpen?: boolean;
  /**
   * Provide which sections are expanded based on ids
   */
  initialExpanded?: { [key: string]: boolean };
  /**
   * Show the border divider when expanded
   */
  border?: boolean;
  /**
   * Provide the style variant that should be used in all Accordion levels.
   */
  variant?: AccordionVariants.default | AccordionVariants.titleOnOpen;
  /**
   * Forces the + and - icon to be the large variant. If not set, the size will be based on the variant.
   */
  largeIcons?: true;

  /**
   * Scroll the selected AccordionSection into view on open
   */
  scrollIntoView?: boolean;

  /**
   * Provide an offset when scrolling into a position. This is only meant for edge-cases that
   * need to adjust the position of the clicked AccordionSection due to overlaying elements.
   */
  scrollIntoViewOffset?: number;
}

export enum AccordionVariants {
  default = 'default',
  titleOnOpen = 'titleOnOpen'
}

/**
 * @internal
 */
export const AccordionContext = React.createContext<{
  indented: boolean;
  scrollIntoView: boolean;
  scrollIntoViewOffset: number;
}>({
  indented: false,
  scrollIntoView: false,
  scrollIntoViewOffset: 0
});

const EMPTY_OBJECT = {};

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      children,
      closeOthersOnOpen = false,
      disabled = false,
      border = true,
      variant = AccordionVariants.default,
      largeIcons,
      onToggle,
      initialExpanded = EMPTY_OBJECT,
      onKeyDown,
      scrollIntoView,
      scrollIntoViewOffset,
      ...props
    }: AccordionProps,
    ref
  ) => {
    const prefix = useRef<string>(generateId());
    const {
      indented,
      scrollIntoView: contextScrollIntoView,
      scrollIntoViewOffset: contextScrollIntoViewOffset
    } = useContext(AccordionContext);

    const initialExpandedCleaned = useMemo<{ [key: string]: boolean }>(
      () =>
        React.Children.map(
          children as ReactElement<AccordionSectionProps>[],
          ({ props: { id } }) => id
        ).reduce((carry: { [key: string]: boolean }, childId: string) => {
          if (initialExpanded[childId]) {
            carry[childId] = true;
          }
          return carry;
        }, {}),
      [children, initialExpanded]
    );

    const [expandedState, dispatchExpanded] = useReducer<
      Reducer<{ [key: string]: boolean }, { id: string }>
    >((state: { [key: string]: boolean }, action: { id: string }) => {
      const newState = closeOthersOnOpen ? {} : { ...state };
      const oldValue = state[action.id];

      if (newState[action.id] === undefined) newState[action.id] = false;

      newState[action.id] = !oldValue;

      return newState;
    }, initialExpandedCleaned);

    const handleToggle = useCallback(
      (id, expanded) => {
        if (id) {
          dispatchExpanded({ id });

          onToggle?.(id, expanded);
        }
      },
      [onToggle]
    );

    const amountOfChildren = (children as ReactElement<AccordionSectionProps>[])?.length || 0;

    const contextValue = {
      indented: true,
      scrollIntoView: scrollIntoView ?? contextScrollIntoView ?? false,
      scrollIntoViewOffset: scrollIntoViewOffset ?? contextScrollIntoViewOffset ?? 0
    };

    // Give each button a ref, so we can loop over them with native focus
    const childrenLength = Children.count(children);
    const [buttonNodes, dispatch] = useReducer<
      Reducer<(HTMLButtonElement | null)[], { index: number; node: HTMLButtonElement }>
    >((state, action) => {
      const newState = [...state];
      newState[action.index] = action.node;
      return newState;
    }, Array(childrenLength).fill(null));

    const childRefs = useMemo(
      () =>
        Array(childrenLength)
          .fill(null)
          .map((_, index) => (node: HTMLButtonElement) => {
            if (node) {
              dispatch({ index, node });
            }
          }),
      [childrenLength]
    );

    const handleKeyDown = useCallback(
      (e) => {
        // Use cursors to navigate, loop back around at bottom and top
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.stopPropagation();
          const currentIndex = buttonNodes.findIndex((node) => node === document.activeElement);

          let newIndex = currentIndex || 0;
          switch (e.key) {
            case 'ArrowUp':
              newIndex = currentIndex === 0 ? buttonNodes.length - 1 : currentIndex - 1;
              break;
            case 'ArrowDown':
              newIndex = currentIndex === buttonNodes.length - 1 ? 0 : currentIndex + 1;
              break;
          }

          buttonNodes[newIndex]?.focus();
        }

        onKeyDown?.(e);
      },
      [buttonNodes, onKeyDown]
    );

    return (
      <Container
        {...aria({
          disabled
        })}
        {...props}
        ref={ref}
        indented={indented}
        onKeyDown={handleKeyDown}
      >
        {indented && border && <Border disabled={disabled} />}
        <AccordionContext.Provider value={contextValue}>
          {children &&
            Children.map(children as ReactElement<AccordionSectionProps>[], (child, index) => {
              const id = child.props.id || `${prefix.current}-${index}`;

              if (child.props.expanded !== undefined) {
                console.warn(
                  'You are trying to use AccordionSection as a controlled element inside Accordion. ' +
                    'Please you the `initialExpanded` prop on Accordion instead in combination with an id on the AccordionSection'
                );
              }

              return cloneElement(child, {
                ...child.props,
                focusRef: childRefs[index],
                variant,
                largeIcon: largeIcons,
                id,
                onToggle: child.props.onToggle
                  ? (id: string | undefined, expanded: boolean) => {
                      handleToggle(id, expanded);
                      child.props.onToggle?.(id, expanded);
                    }
                  : handleToggle,
                expanded: expandedState[id],
                disabled: disabled || child.props.disabled,
                // only trigger when at least 1 section is expanded
                border: !indented || (indented && index < amountOfChildren - 1),
                scrollIntoView: contextValue.scrollIntoView,
                scrollIntoViewOffset: contextValue.scrollIntoViewOffset
              });
            })}
        </AccordionContext.Provider>
      </Container>
    );
  }
);

Accordion.displayName = 'Accordion';

export default Accordion;

const Container = styled.div<{ indented?: boolean }>`
  padding-left: ${(props) => (props.indented ? Spacings.medium : 0)};
  margin-bottom: -${(props) => (props.indented ? Spacings.large : 0)};
`;

const Border = styled.div<{ disabled: boolean }>(
  ({ disabled = false }) =>
    css`
      height: 1px;
      background: ${AccordionTheme.accordion.light.states[disabled ? 'disabled' : 'enabled']
        .borderColor};
      margin-left: -${Spacings.medium};
    `
);
