enum Sizes {
  xLarge = 'xLarge',
  large = 'large',
  medium = 'medium',
  small = 'small',
  xSmall = 'xSmall'
}

export type Size = string;

export default Sizes;
