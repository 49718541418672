import React, { FC, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import Colors, { withOpacity } from '../../../tokens/Colors';
import Spacings from '../../../tokens/Spacings';
import Icons from '../../../tokens/icons/Icons';
import Icon from '../../atoms/icon/Icon';
import { motion, MotionProps } from 'framer-motion';
import Opacities from '../../../tokens/Opacities';
import MediaQueries from '../../../tokens/MediaQueries';
import Markdown from '../../atoms/markdown/Markdown';
import ResponsiveImageWithRatio from '../../atoms/responsive-image-with-ratio/ResponsiveImageWithRatio';
import { SimpleText, ProductCard } from '../../../types/models/cms';
import LineHeights from '../../../tokens/LineHeights';
import Grid from '../../atoms/grid/Grid';
import GridColumn from '../../atoms/grid/GridColumn';
import Spacer from '../../atoms/spacer/Spacer';
import Title from '../../atoms/title/Title';
import useDictionary from '../../../hooks/useDictionary';
import Paragraph from '../../atoms/paragraph/Paragraph';

export interface ProductDetailOverlayProps extends ProductCard {
  closeOverlay: () => void;
}

const ProductDetailOverlay: FC<ProductDetailOverlayProps> = ({
  title,
  description,
  closeOverlay,
  cardImage,
  features,
  benefits,
  specifications
}: ProductDetailOverlayProps) => {
  const dictionary = useDictionary();
  const handleOverlayClose = useCallback((event: KeyboardEvent) => {
    const key = event.key || event.keyCode;

    switch (key) {
      case 'Escape':
      case 'Esc':
      case 27:
        closeOverlay();
        break;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleOverlayClose);
    return () => {
      document.removeEventListener('keydown', handleOverlayClose);
    };
  }, [handleOverlayClose]);
  return (
    <>
      <style>{globalStyle}</style>
      <OverlayWrapper {...overlayMotionProps}>
        <OverlayBackground />
        <OverlayPopUp {...overlayPopupMotionProps}>
          <TitleSection>
            <StyledTitle as="h3">
              <Markdown text={title} />
            </StyledTitle>
            <StyledIcon icon={Icons.close} onClick={closeOverlay} />
          </TitleSection>
          <DescriptionSection>
            <SectionTitle as="h4">
              <Markdown text={dictionary.get('Description')} />
            </SectionTitle>
            <Spacer spacing={Spacings.medium} />
            <Markdown text={description} />
          </DescriptionSection>
          <Spacer spacing={Spacings.xLarge} />
          <FeaturesSection>
            <Grid>
              <GridColumn span={{ mobile: 4, tablet: 4, desktop: 6 }}>
                {cardImage && <StyledResponsiveImage ratio={0.6} {...cardImage} />}
              </GridColumn>
              <GridColumn span={{ mobile: 4, tablet: 4, desktop: 6 }}>
                {features && (
                  <>
                    <SectionTitle as="h4">
                      <Markdown text={dictionary.get('Features')} />
                    </SectionTitle>
                    <Spacer spacing={Spacings.medium} />
                    <Markdown text={features} />
                  </>
                )}
                {benefits && (
                  <>
                    <Spacer spacing={Spacings.large} />
                    <SectionTitle as="h4">
                      <Markdown text={dictionary.get('Benefits')} />
                    </SectionTitle>
                    <Spacer spacing={Spacings.medium} />
                    <Markdown text={benefits} />
                  </>
                )}
              </GridColumn>
            </Grid>
          </FeaturesSection>
          <Spacer spacing={Spacings.xLarge} />
          <SpecificationsSection>
            {specifications && (
              <>
                <SectionTitle as="h4">
                  <Markdown text={dictionary.get('Specifications')} />
                </SectionTitle>
                <Spacer spacing={Spacings.medium} />
                <SpecificationsList>
                  <Tbody>
                    {specifications?.items.map(
                      ({ entryTitle, text }: SimpleText, index: number) => (
                        <TableRow key={index}>
                          <TableData>{entryTitle}</TableData>
                          <TableData>{text}</TableData>
                        </TableRow>
                      )
                    )}
                  </Tbody>
                </SpecificationsList>
              </>
            )}
          </SpecificationsSection>
        </OverlayPopUp>
      </OverlayWrapper>
    </>
  );
};

export default ProductDetailOverlay;

const StyledTitle = styled(Title)`
  color: ${Colors.gieplGreen};
  font-weight: bold;
`;

const StyledResponsiveImage = styled(ResponsiveImageWithRatio)`
  object-fit: contain;
  margin: 0 0 ${Spacings.medium};
`;

const TitleSection = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  background: ${Colors.white};
  padding: ${Spacings.xLarge} 0;
  display: flex;
  justify-content: space-between;
`;

const SpecificationsSection = styled.div``;

const DescriptionSection = styled.div`
  line-height: ${LineHeights.small};
`;

const SectionTitle = styled(Paragraph)`
  font-weight: bold;
  font-size: ${Spacings.large};
`;

const FeaturesSection = styled.div`
  line-height: ${LineHeights.small};
`;

const SpecificationsList = styled.table`
  width: 100%;
  border-collapse: collpase;
  table-layout: fixed;
`;

const TableRow = styled.tr``;

const TableData = styled.td`
  border: 1px solid ${Colors.graphiteBlack};
  padding: ${Spacings.large};
`;

const Tbody = styled.tbody``;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const OverlayWrapper = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  z-index: 10;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayBackground = styled.div`
  background: ${withOpacity(Colors.graphiteBlack, Opacities.xLow)};
  height: 100%;
  z-index: 11;
  width: 100%;
  position: absolute;
`;

const OverlayPopUp = styled(motion.div)`
  width: 65%;
  min-height: 250px;
  max-height: 90vh;
  background: ${Colors.white};
  z-index: 12;
  box-shadow: 0 0 31px 0 ${Colors.boxShadow};
  padding: 0 ${Spacings.large} ${Spacings.large};
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  ${MediaQueries.mobileOnly} {
    width: 99vw;
  }
`;

const overlayMotionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

const overlayPopupMotionProps: MotionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } },
  exit: { opacity: 0, y: 20 }
};

const globalStyle = `
  body{
    overflow:hidden;
  }
  `;
