import { TextSizeThemeData, TextThemeBaseData } from '../text/Text.theme';
import Colors, { withOpacity } from '../../../tokens/Colors';
import FontSizes from '../../../tokens/FontSizes';
import LetterSpacings from '../../../tokens/LetterSpacings';
import LineHeights from '../../../tokens/LineHeights';
import Opacities from '../../../tokens/Opacities';
import { ComponentThemeData } from '../../../types';

export interface LabelThemeData extends TextThemeBaseData {
  sizes: {
    large?: TextSizeThemeData;
    medium: TextSizeThemeData;
    small?: TextSizeThemeData;
    xSmall?: TextSizeThemeData;
  };
}

export interface LabelThemesData {
  label: ComponentThemeData<LabelThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    label: LabelThemeData;
  }
}

const LabelTheme: LabelThemesData = {
  label: {
    light: {
      color: withOpacity(Colors.graphiteBlack, Opacities.medium),
      sizes: {
        medium: {
          fontSize: {
            mobile: FontSizes.body
          },
          letterSpacing: {
            mobile: LetterSpacings.small
          },
          lineHeight: {
            mobile: LineHeights.small
          }
        }
      }
    },
    dark: {
      color: withOpacity(Colors.white, Opacities.medium)
    }
  }
};

export default LabelTheme;
