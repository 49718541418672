import styled from '@emotion/styled';
import React, { FC } from 'react';
import Spacings from '../../../tokens/Spacings';
import { SimpleText } from '../../../types/models/cms';
import Section from '../../atoms/section/Section';
import Grid from '../../atoms/grid/Grid';
import GridColumn from '../../atoms/grid/GridColumn';
import MissionStatementItem from '../../atoms/mission-statement-item/MissionStateMentItem';
import RevealInView from '../../atoms/reveal-in-view/RevealInView';

export interface SectionMissionStatementProps {
  mission?: SimpleText;
  vision?: SimpleText;
  values?: SimpleText;
}

const SectionMissionStatement: FC<SectionMissionStatementProps> = ({
  mission = { id: '', text: '' },
  vision = { id: '', text: '' },
  values = { id: '', text: '' }
}: SectionMissionStatementProps) => (
  <StyledSection>
    <Grid nested>
      <GridColumn span={{ mobile: 4, tablet: 2, desktop: 4 }}>
        <RevealInView>
          <MissionStatementItem {...vision} />
        </RevealInView>
      </GridColumn>
      <GridColumn span={{ mobile: 4, tablet: 2, desktop: 4 }}>
        <RevealInView delay={0.2}>
          <MissionStatementItem {...mission} />
        </RevealInView>
      </GridColumn>
      <GridColumn span={{ mobile: 4, tablet: 2, desktop: 4 }}>
        <RevealInView delay={0.4}>
          <MissionStatementItem {...values} />
        </RevealInView>
      </GridColumn>
    </Grid>
  </StyledSection>
);

const StyledSection = styled(Section)`
  margin: ${Spacings.medium} 0;
`;

export default SectionMissionStatement;
