/**
 *
 * This generates a (non cryptographically) unique id. This is useful when generating elements where an ID is needed, but the prop is optional.
 *
 * ```js
 *
 * generateId('pre-'); // pre-RANDOMXXXXXXXXXX
 * generateId('pre-', 6); // pre-RANDOM
 * ```
 *
 * @storybook Helpers/generateId
 */
const generateId = (prefix = '', length = 16): string => {
  let string = '';
  for (let i = 1; i < length + 1; i = i + 8) {
    string += Math.random().toString(36).substr(2, 10);
  }
  const id = string.substr(0, length);
  return prefix + id;
};
export default generateId;
