import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/_main.scss';
import { ClientContext } from 'graphql-hooks';
import client from './graphQL/client';
import '@fontsource/roboto';

ReactDOM.render(
  <Router>
    <ClientContext.Provider value={client}>
      <App />
    </ClientContext.Provider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
