import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';

import { isClient } from '../helpers/dom';

export type ResizeObserverTargetSize = {
  width: number;
  height: number;
  left: number;
  top: number;
  bottom: number;
  right: number;
  x: number;
  y: number;
};
export const initialResizeObserverTargetSize: ResizeObserverTargetSize = {
  width: 0,
  height: 0,
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  x: 0,
  y: 0
};

/**
 * A hook for manage window size
 * @param targetRef RefObject<HTMLElement>
 * @param targetSizeRef MutableRefObject<ResizeObserver>
 * @param callback function to pass window size rather than get as state. This helps to avoid render each time
 * @example
 * const { width, height } = useResizeObserver();
 * @example
 * useResizeObserver({ callback: console.log });
 */
export default function useResizeObserver({
  targetRef,
  targetSizeRef = useRef(initialResizeObserverTargetSize),
  callback
}: {
  targetRef: RefObject<HTMLElement>;
  targetSizeRef?: MutableRefObject<ResizeObserverTargetSize>;
  callback?: (size: ResizeObserverTargetSize) => void;
}): ResizeObserverTargetSize {
  /** State for window size */
  const [elementSize, setResizeObserver] = useState(targetSizeRef.current);

  const update = (nextSize: DOMRect) => {
    /** Ignore if the size is same as privious */
    const prevSize = targetSizeRef.current;
    if (nextSize.width === prevSize.width && nextSize.height === prevSize.height) return;

    targetSizeRef.current = nextSize;
    if (callback) {
      callback(nextSize);
    } else {
      setResizeObserver(nextSize);
    }
  };

  const resizeWindow = () => {
    if (!targetRef.current) return;
    update(targetRef.current.getBoundingClientRect());
  };

  /** When mounted */
  useEffect(() => {
    if (!isClient || !targetRef.current) return;
    let resizeObserver: ResizeObserver | undefined;
    const hasResizeObserver = 'ResizeObserver' in window;
    if (hasResizeObserver) {
      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          update(entry.contentRect);
        }
      });
      resizeObserver.observe(targetRef.current);
    } else {
      window.addEventListener('resize', resizeWindow);
      resizeWindow();
    }
    return () => {
      if (hasResizeObserver && resizeObserver) {
        resizeObserver.disconnect();
      } else {
        window.removeEventListener('resize', resizeWindow);
      }
    };
  }, []);

  return elementSize;
}
