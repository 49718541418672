import { ComponentThemeData } from '../../../types';
import Colors, { Color } from '../../../tokens/Colors';

export interface DialogThemeData {
  backgroundColor: Color;
}

export interface DialogThemesData {
  dialog: ComponentThemeData<DialogThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    dialog: DialogThemeData;
  }
}

const DialogTheme: DialogThemesData = {
  dialog: {
    light: { backgroundColor: Colors.white },
    dark: { backgroundColor: Colors.graphiteBlack }
  }
};

export default DialogTheme;
