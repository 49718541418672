import { ResponsiveThemeData } from '../../../themes/Theme';
import FontSizes, { FontSize } from '../../../tokens/FontSizes';
import { LetterSpacing } from '../../../tokens/LetterSpacings';
import Spacings, { DimensionalSpacing, Spacing } from '../../../tokens/Spacings';
import Colors, { Color, withOpacity } from '../../../tokens/Colors';
import { FontWeight } from '../../../tokens/FontWeights';
import Opacities, { Opacity } from '../../../tokens/Opacities';
import { FontStyle } from '../../../tokens/FontStyle';
import LineHeights, { LineHeight } from '../../../tokens/LineHeights';
import { ComponentThemeData } from '../../../types';

export interface ButtonThemeData {
  fontWeight?: FontWeight;
  fontFamily?: string;
  color: Color;
  opacity?: Opacity;
  fontStyle?: FontStyle;
  sizes: {
    medium: ButtonSizeThemeData;
  };
  states: ButtonStateStyles;
  iconColor?: Color;
}

export interface ButtonSizeThemeData {
  fontSize: ResponsiveThemeData<FontSize>;
  letterSpacing?: ResponsiveThemeData<LetterSpacing>;
  lineHeight: ResponsiveThemeData<LineHeight>;
  padding: ResponsiveThemeData<DimensionalSpacing>;
  spacing: Spacing;
  height?: ResponsiveThemeData<Spacing>;
}

export interface ButtonStateStyles {
  enabled: ButtonStyles;
  disabled: ButtonStyles;
  hover: ButtonStyles;
  active: ButtonStyles;
}

export interface ButtonStyles {
  color: Color;
  backgroundColor?: Color;
  borderColor?: Color;
  contentOpacity?: number;
}

export interface ButtonThemesData {
  button: ComponentThemeData<ButtonThemeData>;
}

declare module '../../../themes/Theme' {
  interface Theme {
    button: ButtonThemeData;
  }
}

const ButtonTheme: ButtonThemesData = {
  button: {
    light: {
      color: Colors.graphiteBlack,
      iconColor: Colors.safetyOrange,
      states: {
        enabled: {
          color: Colors.graphiteBlack
        },
        disabled: {
          color: Colors.graphiteBlack
        },
        hover: {
          color: withOpacity(Colors.graphiteBlack, Opacities.medium)
        },
        active: {
          color: Colors.safetyOrange
        }
      },
      sizes: {
        medium: {
          padding: {
            mobile: `0px`
          },
          spacing: Spacings.xSmall,
          fontSize: {
            mobile: FontSizes.body
          },
          lineHeight: {
            mobile: LineHeights.small
          }
        }
      }
    },
    dark: {
      color: Colors.white,
      states: {
        enabled: {
          color: Colors.white
        },
        disabled: {
          color: Colors.white
        },
        hover: {
          color: withOpacity(Colors.white, Opacities.medium)
        },
        active: {
          color: Colors.safetyOrange
        }
      }
    }
  }
};

export default ButtonTheme;
