const grid = '|&|';

type MarkdownStyle =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'blockquote'
  | 'bold'
  | 'italic'
  | 'img'
  | 'link'
  | 'br'
  | 'code';

/**
 * Return parsed colleciton from Markdown text
 * @see https://www.bigomega.dev/markdown-parser
 * @example
 * parseMarkdown('**Covid** Hello'); // [{ text: 'Covid', style: 'bold' }, { text: ' Hello' }];
 */
const parseMarkdown = (
  text: string
): { text?: string; style?: MarkdownStyle; alt?: string; href?: string }[] => {
  if (!text) return [];
  return text
    .replace(/^### (.*$)/gim, `${grid}{ "text": "$1", "style": "h3" }${grid}`)
    .replace(/^## (.*$)/gim, `${grid}{ "text": "$1", "style": "h2" }${grid}`)
    .replace(/^# (.*$)/gim, `${grid}{ "text": "$1", "style": "h1" }${grid}`)
    .replace(/^\>(.+)/gim, `${grid}{ "text": "$1", "style": "blockquote" }${grid}`)
    .replace(/[\*\_]{2}([^\*\_]+)[\*\_]{2}/gim, `${grid}{ "text": "$1", "style": "bold" }${grid}`)
    .replace(/[\`]{1}([^\`]+)[\`]{1}/gim, `${grid}{ "text": "$1", "style": "code" }${grid}`)
    .replace(
      /\!\[([^\]]+)\]\(([^\)]+)\)/gim,
      `${grid}{ "alt": "$1", "text": "$2", "style": "img" }${grid}`
    )
    .replace(
      /[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/gim,
      `${grid}{ "href": "$2", "text": "$1", "style": "link" }${grid}`
    )
    .replace(/\n/gim, `${grid}{ "style": "br" }${grid}`)
    .trim()
    .split(grid)
    .map((value) => {
      if (!value) return false;
      try {
        return JSON.parse(value);
      } catch (err) {
        return { text: value };
      }
    })
    .filter(Boolean);
};

export default parseMarkdown;
