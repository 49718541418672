import { PageSectionRes, PageSection, PageSectionContent, Theme } from '../../types/models/cms';
import Colors from '../../tokens/Colors';
/**
 * Return page section
 */
export const getPageSection = (name: string, sections: PageSection[]): PageSectionRes => {
  return sections.find((section) => section.name === name) as PageSectionRes;
};

/**
 * Return page content
 */
/* eslint-disable */
export function getPageContent<T>(typeId: string, section: PageSectionRes): T {
  const content = section.contents.find((content: PageSectionContent) => content.typeId === typeId);
  return content as any as T;
}

/**
 * Return Color
 */
export const getColor = (theme?: Theme) => {
  switch (theme) {
    case 'dark':
      return Colors.white;
    case 'light':
      return Colors.graphiteBlack;
    default:
      return Colors.graphiteBlack;
  }
};
