import React, { forwardRef } from 'react';

const Instagram = forwardRef<SVGSVGElement, React.SVGAttributes<SVGSVGElement>>((props, ref) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    role="img"
    ref={ref}
    {...props}
  >
    <g fill="currentColor">
      <path d="M11.925 2.175c3.225 0 3.6 0 4.875.075 1.2.075 1.8.225 2.25.45.525.225.975.45 1.35.9.45.45.675.825.9 1.35.15.45.375 1.05.45 2.25.075 1.275.075 1.65.075 4.875s0 3.6-.075 4.875c-.075 1.2-.225 1.8-.45 2.25-.225.525-.45.975-.9 1.35-.45.45-.825.675-1.35.9-.45.15-1.05.375-2.25.45-1.275.075-1.65.075-4.875.075s-3.6 0-4.875-.075c-1.2-.075-1.8-.225-2.25-.45-.525-.225-.975-.45-1.35-.9-.45-.45-.675-.825-.9-1.35-.15-.45-.375-1.05-.45-2.25-.075-1.275-.075-1.65-.075-4.875s0-3.6.075-4.875c.075-1.2.225-1.8.45-2.25.225-.525.45-.975.9-1.35.45-.45.825-.675 1.35-.9.45-.15 1.05-.375 2.25-.45 1.275-.075 1.65-.075 4.875-.075zm0-2.175C8.7 0 8.25 0 6.975.075S4.8.3 4.05.6c-.825.3-1.425.75-2.1 1.35C1.275 2.625.9 3.3.6 4.05S.075 5.7.075 6.975C0 8.25 0 8.7 0 11.925s0 3.675.075 4.95C.15 18.15.3 19.05.6 19.8c.3.825.75 1.425 1.35 2.1.675.675 1.35 1.05 2.1 1.35s1.65.525 2.925.525c1.275.075 1.725.075 4.95.075s3.675 0 4.95-.075 2.175-.225 2.925-.525c.825-.3 1.425-.75 2.1-1.35.675-.675 1.05-1.35 1.35-2.1s.525-1.65.525-2.925c.075-1.275.075-1.725.075-4.95s0-3.675-.075-4.95S23.55 4.8 23.25 4.05c-.3-.825-.75-1.425-1.35-2.1C21.225 1.275 20.55.9 19.8.6S18.15.075 16.875.075C15.6 0 15.15 0 11.925 0z" />
      <path d="M11.775 6c-3.375 0-6.15 2.775-6.15 6.15s2.775 6.15 6.15 6.15c3.375 0 6.15-2.775 6.15-6.15S15.15 6 11.775 6zm0 10.125c-2.175 0-3.975-1.8-3.975-3.975s1.8-3.975 3.975-3.975 3.975 1.8 3.975 3.975-1.8 3.975-3.975 3.975zM18.3 6.975a1.425 1.425 0 100-2.85 1.425 1.425 0 000 2.85z" />
    </g>
  </svg>
));
Instagram.displayName = 'Instagram';
export default Instagram;
