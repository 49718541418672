import { Property } from 'csstype';

export type LineHeight = Property.LineHeight<string>;

enum LineHeights {
  large = '2rem', // '32px'
  small = '1.125rem' // '18px'
}
export default LineHeights;

export const createCustomLineHeight = (multiplier: number): string => `${multiplier}rem`;
