import React, { FC } from 'react';
import loadable from '@loadable/component';
import GET_ALL_PAGE_CONTENT from '../../../graphQL/queries/getPageContent';
import { useQuery } from 'graphql-hooks';
import PageLoadingSpinner from '../../atoms/page-loading-spinner/PageLoadingSpinner';
import { getAboutUsPageContent } from '../../../helpers/cms/aboutUsView';
import RevealInView from '../../atoms/reveal-in-view/RevealInView';
const HeroSection = loadable(() => import('../../organisms/section-hero/SectionHero'));
const ImageWithDescription = loadable(
  () => import('../../molecules/image-with-description/ImageWithDescription')
);

const AboutUsView: FC = () => {
  const { data, loading } = useQuery(GET_ALL_PAGE_CONTENT, {
    variables: {
      pathname: '/about-us'
    }
  });
  if (loading) {
    return <PageLoadingSpinner />;
  }
  if (!loading && !!data) {
    const content = getAboutUsPageContent(data);
    const {
      sections: { hero, companyProfile, qualityCommitment, whyChooseUs }
    } = content;
    return (
      <main className="container">
        <HeroSection {...hero} showMissionStatement={false} />
        <RevealInView>
          <ImageWithDescription {...companyProfile} />
        </RevealInView>
        <RevealInView>
          <ImageWithDescription {...qualityCommitment} isMediaLeft={false} />
        </RevealInView>
        <RevealInView>
          <ImageWithDescription {...whyChooseUs} />
        </RevealInView>
      </main>
    );
  }
  return <></>;
};

export default AboutUsView;
