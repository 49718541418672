/**
 * @internal
 */
import { useMotionValue, useSpring } from 'framer-motion';
import { useCallback, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useScrollTo = <T = HTMLDivElement>(
  scrollTo: number,
  scrollElement: T,
  direction: 'scrollTop' | 'scrollLeft'
) => {
  // https://github.com/framer/motion/issues/304
  const motion = useMotionValue(scrollTo);
  const spring = useSpring(motion, {
    velocity: 5,
    damping: 20
  });

  const reset = useCallback(
    (scrollTo?: number) => {
      const resetValue = scrollTo || (scrollElement as unknown as HTMLElement)[direction];
      spring.set(resetValue, false);
      motion.set(resetValue, false);
    },
    [direction, motion, scrollElement, spring]
  );

  const stop = useCallback(() => {
    spring.stop();
  }, [spring]);

  useEffect(() => {
    motion.set(scrollTo, false);
  }, [motion, scrollTo, spring]);

  useEffect(() => {
    const unsubscribe = spring.onChange((value) => {
      if (scrollElement) {
        (scrollElement as unknown as HTMLElement)[direction] = value;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [direction, motion, scrollElement, spring]);

  return {
    motion,
    spring,
    reset,
    stop
  };
};

export default useScrollTo;
