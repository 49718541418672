import React, { forwardRef, Fragment, useCallback, useEffect, useState } from 'react';
import { Property } from 'csstype';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Spacings from '../../../tokens/Spacings';
import Paragraph from '../paragraph/Paragraph';
import { shouldNotForwardProps } from '../../../helpers/shouldForwardProps';
import { m as motion } from 'framer-motion';
import { createTransition } from '../../../tokens/Motion';
import { HTMLMotionAttributes } from '../../../types/motionAttributes';
import useCombinedRef from '../../../hooks/useCombinedRef';
import { useIsSSR } from '../../../contexts/ssr';
import Colors, { blend, withOpacity } from '../../../tokens/Colors';
import Opacities from '../../../tokens/Opacities';

/* eslint-disable */

export interface ImageProps
  extends HTMLMotionAttributes<React.ImgHTMLAttributes<HTMLImageElement>> {
  /**
   * Children are not supported
   */
  children?: never;
  /**
   * The (fallback) source image
   */
  source: string;
  /**
   * The alt text shown when hovering over the image
   */
  alternative: string;
  /**
   * The width of the image
   */
  width?: Property.Width<string> | number;
  /**
   * The height of the image
   */
  height?: Property.Height<string> | number;
  /**
   * The way the image is being placed in its container
   */
  objectFit?: Property.ObjectFit;
  /**
   * The way the image aligns in its container
   */
  objectPosition?: Property.ObjectPosition<string>;
  /**
   * If the image is stretched inside its container
   */
  stretch?: boolean;
  /**
   * Show text when an image failed to load
   */
  errorMessage?: string;
  /**
   * Show/hide the loading animation
   */
  showLoadingState?: boolean;
  /**
   * Whether the image fades in or not
   */
  fadeIn?: boolean;
  // TODO: soon to be required (aria) or is the `alternative` property enough?
  'aria-label'?: string;
}

enum ImageLoadingState {
  loading = 'loading',
  loaded = 'loaded',
  failed = 'failed'
}

const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      source,
      alternative,
      objectFit,
      objectPosition,
      stretch,
      errorMessage = '',
      showLoadingState = true,
      fadeIn = false,
      style = {},
      onLoad,
      onError,
      ...props
    }: ImageProps,
    ref
  ) => {
    const [failedLoading, setFailedLoading] = useState<{ [key: string]: boolean }>({});
    const [imageLoadingState, setImageLoadingState] = useState<ImageLoadingState>(
      showLoadingState ? ImageLoadingState.loading : ImageLoadingState.loaded
    );
    const [currentSource, setCurrentSource] = useState(source);
    const [cached, setCached] = useState();

    const imageRef = useCallback(
      (node) => {
        if (node) {
          // validate when a ref is defined if the image is already loaded
          // onLoad doesn't always trigger when inside <picture> when it is already loaded
          if (node.complete && imageLoadingState !== ImageLoadingState.loaded) {
            setImageLoadingState(ImageLoadingState.loaded);
          }
          if (node.complete) {
            // trigger a new render to ensure the loaded event is triggered
            setCached(node.getAttribute('src'));
          }
        }
      },
      [imageLoadingState]
    );
    const combinedRef = useCombinedRef(ref, imageRef);

    useEffect(() => {
      // check if loading failed
      if (imageLoadingState === ImageLoadingState.failed && !failedLoading[source]) {
        setImageLoadingState(ImageLoadingState.loading);
      }
    }, [source, imageLoadingState, failedLoading]);

    useEffect(() => {
      if (source !== currentSource) {
        // cleanup failed states
        delete failedLoading[currentSource];
        setFailedLoading({ ...failedLoading });

        // reset loading state while a new source is provided
        setCurrentSource(source);
        if (cached !== source) {
          setImageLoadingState(ImageLoadingState.loading);
        }
        // reset to discover new sources when they are already cached
        setCached(undefined);
      }
    }, [source, currentSource, failedLoading, cached]);

    const handleLoad = useCallback(
      (e) => {
        if (imageLoadingState !== ImageLoadingState.loaded) {
          setImageLoadingState(ImageLoadingState.loaded);
        }
        onLoad?.(e);
      },
      [imageLoadingState, onLoad]
    );

    const handleError = useCallback(
      (e) => {
        setFailedLoading({ ...failedLoading, [source]: true });
        setImageLoadingState(ImageLoadingState.failed);

        onError?.(e);
      },
      [source, failedLoading, onError]
    );

    const isLoading = imageLoadingState === ImageLoadingState.loading;

    const { width, height, ...errorProps } = props;
    const widthValue = typeof width === 'number' ? `${width}px` : width;
    const heightValue = typeof height === 'number' ? `${height}px` : height;

    if (imageLoadingState === ImageLoadingState.failed) {
      return (
        <StyledImageError
          {...errorProps}
          // @ts-ignore
          style={style}
          width={widthValue}
          height={heightValue}
          ref={ref}
          stretch={stretch || false}
        >
          <StyledImageErrorMessage>{errorMessage}</StyledImageErrorMessage>
        </StyledImageError>
      );
    }

    const isSSR = useIsSSR();
    const isVisible = isLoading && !isSSR ? { display: 'none' } : {};

    return (
      <Fragment>
        <StyledImage
          draggable={false}
          {...props}
          ref={combinedRef}
          style={{ ...style, ...isVisible }}
          initial={false}
          animate={{
            opacity: !fadeIn || !isLoading || isSSR ? 1 : 0
          }}
          transition={createTransition({ disabled: !fadeIn })}
          key={source}
          src={source}
          alt={alternative}
          objectFit={objectFit}
          objectPosition={objectPosition}
          stretch={stretch}
          loadingState={imageLoadingState}
          showLoadingState={showLoadingState}
          onLoad={handleLoad}
          onError={handleError}
        />
      </Fragment>
    );
  }
);

Image.displayName = 'Image';

export default Image;

interface StyledImageProps extends Omit<ImageProps, 'children'> {
  loadingState: ImageLoadingState;
  showLoadingState: boolean;
  source: string;
  objectFit?: Property.ObjectFit;
  objectPosition?: Property.ObjectPosition<string>;
  stretch?: boolean;
}

const StyledImage = styled(
  motion.img,
  shouldNotForwardProps(
    false,
    'loadingState',
    'objectFit',
    'objectPosition',
    'stretch',
    'width',
    'height'
  )
)<Omit<StyledImageProps, 'source' | 'alternative'>>((props) =>
  css(
    css`
      display: block;
      max-width: 100%;
      width: ${typeof props.width === 'number' ? `${props.width}px` : props.width};
      height: ${typeof props.height === 'number' ? `${props.height}px` : props.height};
      text-align: center;
      position: relative;
    `,
    props.loadingState === ImageLoadingState.failed &&
      css`
        &:after {
          /* TODO: remove */
          background-color: ${blend(Colors.white, Colors.graphiteBlack, Opacities.xLow)};
        }
      `,
    props.objectFit &&
      css`
        object-fit: ${props.objectFit};
        object-position: ${props.objectPosition};
      `,
    props.stretch &&
      css`
        display: flex;
        width: 100%;
        height: 100%;
        flex: 1;
      `
  )
);

StyledImage.displayName = 'StyledImage';

const StyledImageError = styled('div', shouldNotForwardProps(false, 'width', 'height', 'stretch'))<{
  stretch: boolean;
  width?: string;
  height?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${blend(Colors.white, Colors.graphiteBlack, Opacities.xLow)};
  flex-direction: column;
  color: ${withOpacity(Colors.graphiteBlack, Opacities.medium)};
  padding: ${Spacings.medium};
  width: ${(props) => (props.stretch ? '100%' : props.width ? props.width : 'auto')};
  height: ${(props) => (props.stretch ? '100%' : props.height ? props.height : 'auto')};
`;

const StyledImageErrorMessage = styled(Paragraph)`
  margin-top: ${Spacings.small};
  color: ${withOpacity(Colors.graphiteBlack, Opacities.medium)};
`;
