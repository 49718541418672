import { css } from '@emotion/react';
import { responsiveStyles } from '../../../helpers/responsiveStyle';
import { shouldNotForwardThemeProps } from '../../../helpers/shouldForwardProps';
import styled from '@emotion/styled';
import { createCSSTransition } from '../../../tokens/Motion';
import Orientations from '../../../tokens/Orientations';
import Spacings, { ResponsiveSpacing, Spacing } from '../../../tokens/Spacings';
import React, { forwardRef } from 'react';

export interface SpacerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  /**
   * Adjusts the direction in which the `spacing` takes up space
   */
  orientation?: Orientations;
  /**
   * The amount of space the component fills
   */
  spacing?: Spacing | ResponsiveSpacing;
}

const Spacer = forwardRef<HTMLDivElement, SpacerProps>(
  (
    { spacing = Spacings.medium, orientation = Orientations.vertical, ...props }: SpacerProps,
    ref
  ) => <StyledSpacer {...props} ref={ref} spacing={spacing} orientation={orientation} />
);

Spacer.displayName = 'Spacer';

export default Spacer;

const StyledSpacer = styled(
  'div',
  shouldNotForwardThemeProps
)<SpacerProps>(({ orientation, spacing }) =>
  css(
    css`
      flex-shrink: 0;
      transition: ${createCSSTransition('width')}, ${createCSSTransition('height')};

      ${spacing !== undefined
        ? typeof spacing === 'string'
          ? `${orientation === Orientations.vertical ? 'height' : 'width'}: ${spacing};`
          : responsiveStyles({
              [orientation === Orientations.vertical ? 'height' : 'width']: spacing
            })
        : ''}
    `
  )
);
