import { RefObject, useState } from 'react';

import useInView, { InViewResponse } from './useInview';

/**
 * A hook to check if element is in view
 * @param targetRef RefObject<HTMLElement>
 * @param onShow () => void callback
 * @example
 * const targetRef = useRef();
 * const { inView } = useInViewOnce({ targetRef });
 */
export default function useInViewOnce({
  targetRef,
  onShow
}: {
  targetRef: RefObject<HTMLElement>;
  onShow?: () => void;
}): InViewResponse {
  const [inView, setInView] = useState(false);
  useInView({
    targetRef: inView ? undefined : targetRef,
    callback: (res) => {
      if (res.inView && !inView) {
        setInView(true);
        if (onShow) {
          onShow();
        }
      }
    }
  });
  return { inView };
}
