import * as React from 'react';

export interface PageEntry {
  hideMarketSelector?: boolean;
  pathname: string;
  useTransparentHeader?: boolean;
}

export interface DictionaryEntry {
  entryTitle: string;
  text: string;
}

export interface GlobalDictionaryEntry {
  key: string;
  value: string;
}

export interface ProjectContextProps {
  /**
   * Page slugs
   */
  pages?: PageEntry[];
  /**
   * App dictionary
   */
  dictionary: DictionaryEntry[];
  /**
   * Global dictionary
   */
  globalDictionary?: GlobalDictionaryEntry[];
}

/**
 * The project context
 */
export const ProjectContext = React.createContext<ProjectContextProps>({
  pages: [],
  dictionary: [],
  globalDictionary: []
});

export default ProjectContext;
