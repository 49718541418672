import {
  PageResponse,
  Page,
  MediaItem,
  PageSection,
  GeolocationItem
} from '../../types/models/cms';
import { getPageContent, getPageSection } from '.';
import { ContactUsViewSections } from '../../types/models/cms/ContactUsView';

/**
 * Return page content
 */
export const getContactUsPageContent = ({
  page: { sections }
}: PageResponse): Page<ContactUsViewSections> => {
  return {
    sections: getContactUsViewSections(sections)
  };
};

const getContactUsViewSections = (sections: PageSection[]): ContactUsViewSections => {
  const hero = getPageSection('hero', sections);
  const contactUs = getPageSection('geiplContactUs', sections);
  return {
    hero: {
      theme: hero.theme,
      name: hero.name,
      label: hero.label,
      image: getPageContent<MediaItem>('geiplContactUsHeroImage', hero)
    },
    contactUs: {
      theme: contactUs.theme,
      name: contactUs.name,
      label: contactUs.label,
      corporateOffice: getPageContent<GeolocationItem>('gieplCorporateOffice', contactUs),
      siteOffice: getPageContent<GeolocationItem>('gieplSiteAddress', contactUs)
    }
  };
};
