import styled from '@emotion/styled';
import { motion, MotionProps, AnimatePresence } from 'framer-motion';
import React, { FC } from 'react';
import Spacings from '../../../tokens/Spacings';
import { SimpleText } from '../../../types/models/cms';
import Markdown from '../markdown/Markdown';
import Paragraph from '../paragraph/Paragraph';
import Title from '../title/Title';
import Grid from '../grid/Grid';
import GridColumn from '../grid/GridColumn';

const MissionStateMentItem: FC<SimpleText> = ({
  entryTitle = '',
  text = '',
  ...props
}: SimpleText) => (
  <AnimatePresence>
    <MissionStateMentItemSection {...props}>
      <Grid>
        <GridColumn span={{ mobile: 4, desktop: 12, tablet: 8 }}>
          <motion.div {...heroTextMotionProps}>
            <StyledTitle>
              <Markdown text={entryTitle} />
            </StyledTitle>
          </motion.div>
        </GridColumn>
        <GridColumn span={{ mobile: 4, desktop: 12, tablet: 8 }}>
          <motion.div {...heroTagLineMotionProps}>
            <StyledParagraph>
              <Markdown text={text} />
            </StyledParagraph>
          </motion.div>
        </GridColumn>
      </Grid>
    </MissionStateMentItemSection>
  </AnimatePresence>
);

const MissionStateMentItemSection = styled.div`
  padding: ${Spacings.xxLarge} ${Spacings.medium};
  box-sizing: border-box;
`;

const StyledTitle = styled(Title)`
  text-transform: capitalize;
  margin: 0 0 ${Spacings.xxSmall};
  span {
    text-decoration: underline;
  }
`;

const StyledParagraph = styled(Paragraph)`
  line-height: 1.4;
`;

const heroTextMotionProps: MotionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.5 } }
};
const heroTagLineMotionProps: MotionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.8 } }
};

export default MissionStateMentItem;
